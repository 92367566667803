import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import PublicMap from "../../../components/olMap/publicMap/PublicMap";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import { loadPublicEventList } from "../../../xhr/EventRequests";
import ISyncProjectList from "../../../xhr/interface/ISyncProjectList";
import { loadPublicProjectListRequest } from "../../../xhr/ProjectRequests";

interface IMapStateProps {}
interface IOwnProps {
    mandant: IMandant;
}

interface IPublicMapParams extends IMapStateProps, IOwnProps {}

function AllMap(props: IPublicMapParams) {
    const { mandant } = props;

    const [projectList, setProjectList] = React.useState<ISyncProjectList[]>([]);

    const [eventList, setEventList] = React.useState<IEventList[]>([]);

    React.useEffect(() => {
        const loadEvent = () => {
            loadPublicEventList(mandant.key).then((result) => {
                setEventList(result.data);
                loadPublicProjectListRequest(mandant.key).then((response) => {
                    setProjectList(response.data);
                    return Promise.resolve();
                });
            });
        };
        loadEvent();
    }, [mandant.key]);

    return (
        <div className="portal-section">
            <Helmet>
                <title>Übersichtskarte Projekte und Veranstaltungen</title>
            </Helmet>
            <PortalHeader mandant={mandant} noProject={true} />

            <Grid container>
                {projectList.length > 0 && (
                    <PublicMap events={eventList} projects={projectList} mandant={mandant.key} />
                )}
            </Grid>
            <PortalFooter mandant={mandant} />
        </div>
    );
}

export default AllMap;
