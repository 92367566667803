import {
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    Paper,
    TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconGames from "@material-ui/icons/Games";
import IconOpenWith from "@material-ui/icons/OpenWith";
import arrayMove from "array-move";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import Notify from "../../../../components/notify/Notify";
import TeamForm from "../../../../components/team/TeamForm";
import TeamTile from "../../../../components/team/TeamTile";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import InitialTeamData from "../../../../types/initialData/InitialTeamData";
import IProjectFull from "../../../../types/IProjectFull";
import ISnackbarState from "../../../../types/ISnackbarState";
import ITeam from "../../../../types/ITeam";
import {
    createProjectTeamRequest,
    deleteProjectTeamRequest,
    updateProjectTeamRequest,
} from "../../../../xhr/TeamRequests";

interface ITeamTabParentProps {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    projectData: IProjectFull;
    onRefreshProject: () => void;
    onUpdateParticipants: (team: any) => void;
    onChangeWysiwyg: (editorState: any, name: string) => void;
}
type IOwnPros = {};
type TeamTabProps = ITeamTabParentProps & IOwnPros;

const ProjectTeamTab: React.FC<TeamTabProps> = (props: TeamTabProps) => {
    const { onTextChange, onImageChange } = props;

    //snackbar Confirm
    const confirm = useConfirm();
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [sortingActive, setSortingActive] = React.useState<boolean>(false);

    /* component states  */

    const [participantAction, setParticipantAction] =
        React.useState<string>("");
    const [selectedParticipant, setSelectedParticipant] = React.useState<ITeam>(
        {
            ...InitialTeamData,
        }
    );
    const { projectData, mandant } = props;
    const [participants, setParticipants] = React.useState<ITeam[]>(
        projectData.team
    );
    React.useEffect(() => {
        setParticipants(projectData.team);
    }, [projectData]);

    /* Get project and setState function */
    const refreshData = () => {
        setIsLoading(true);
        setSelectedParticipant({ ...InitialTeamData });
        setParticipantAction("CREATE");
        props.onRefreshProject();
        setIsLoading(false);
    };
    /* Team Card Display functions  */
    const handleEditTeam = (selectedPart: ITeam) => {
        setParticipantAction("EDIT");
        setSelectedParticipant({ ...selectedPart });
    };

    const handleDeleteTeam = (participant: ITeam) => {
        confirm({
            description:
                "Bitte bestätigen Sie, dass sie das Teammitglied löschen möchten",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: participant.displayTitle,
        }).then(() => {
            setIsLoading(true);

            if (participant.id !== null) {
                deleteProjectTeamRequest(participant.id, mandant.key)
                    .then(() => {
                        const newData = participants.filter(
                            (elem) => elem.id !== participant.id
                        );
                        setParticipants(newData);
                        setSnackBarState({
                            isOpen: true,
                            message: "Der Teilnehmer wurde gelöscht.",
                            type: "success",
                        });
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Der Teilnehmer konnte nicht gelöscht werden.",
                            type: "error",
                        });
                        setIsLoading(false);
                    });
                // n1 2001449/3
                // 71289002 -- 80107843
            }
            setIsLoading(false);
        });
    };

    /* Form Edit Create Team Functions */
    const onTeamFormChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setSelectedParticipant({
            ...selectedParticipant,
            [event.target.name]: event.target.value,
        });
    };

    const onTeamCheckFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedParticipant({
            ...selectedParticipant,
            [event.target.name]: event.target.checked,
        });
    };

    /**
     * upload event for the document
     */
    const onTeamImageChange = (image: IDocument) => {
        setSelectedParticipant({
            ...selectedParticipant,
            image: {
                ...selectedParticipant.image,
                ...image,
            },
        });
    };

    /**
     *
     */
    const onTeamCreateClick = () => {
        setIsLoading(true);

        const teamToPost = {
            ...selectedParticipant,
            project: { id: projectData.id },
        };

        createProjectTeamRequest(mandant.key, teamToPost)
            .then((response: any) => {
                const newData = [...participants];
                newData.push(response.data);
                setParticipants(newData);
                setSelectedParticipant({ ...InitialTeamData });
                setSnackBarState({
                    isOpen: true,
                    message: "Ein neues Teammitglied wurde erstellt",
                    type: "success",
                });
                setIsLoading(false);
            })

            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Erstellen des Teammitglieds ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    /**
     * save the team
     */
    const onTeamSaveClick = () => {
        setIsLoading(true);

        const teamToPost = {
            ...selectedParticipant,
            project: { id: projectData.id },
        };

        updateProjectTeamRequest(mandant.key, teamToPost)
            .then(() => {
                refreshData();
                setSnackBarState({
                    isOpen: true,
                    message: "Das Teammitglied wurde angepasst.",
                    type: "success",
                });
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim Anpassen des Teammitglieds.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    /**
     * Sorting
     */
    const SortableItem = SortableElement(
        ({ value, index }: { value: any; index: any }) => (
            <TeamTile key={value.name + index} participant={value} />
        )
    );
    const SortableList = SortableContainer(({ items }: { items: ITeam[] }) => {
        return (
            <Grid container direction={"row"} spacing={2}>
                {items ? (
                    items
                        /* .sort((a: ITeam, b: ITeam) => {
                            return a.priority - b.priority;
                        }) */
                        .map((participant: ITeam, index: any) => (
                            <SortableItem
                                value={participant}
                                index={index}
                                key={participant.name}
                            />
                        ))
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    });
    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const arry = arrayMove(participants, oldIndex, newIndex);
        for (let i = arry.length - 1; i >= 0; i--) {
            arry[i].priority = i;
        }
        //const listIds = arry.map((elem) => elem.id);
        setParticipants(arry);
        //props.updateParticipants(listIds);
        //props.refreshProject();
    };

    /* handle edit participant click */
    const toggleSortingAction = () => {
        if (sortingActive) {
            props.onUpdateParticipants(
                participants.map((part: ITeam): string => {
                    return part.id;
                })
            );
        }

        setSortingActive(!sortingActive);
    };

    return (
        <>
            {isLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            <Paper variant="outlined" className={"main-paper"}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Informationen Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden,
                        werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="menuTabTeam"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabTeam}
                        onChange={onTextChange}
                        variant="outlined"
                    />

                    <TextField
                        name="titleTabTeam"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabTeam}
                        onChange={onTextChange}
                        variant="outlined"
                    />

                    <TextField
                        name="descTabTeam"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabTeam}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabTeam}
                        idKey={"headerTabTeam"}
                        name={"headerTabTeam"}
                        label={"Info Header"}
                        defaultFilename="Header.jpg"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Team Verwaltung
                    </Typography>
                </Grid>

                <Grid item xs={6} md={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            props.onChangeWysiwyg(editorState, "teamText");
                        }}
                        title="Team Beschreibung"
                        defaultValue={
                            projectData.teamText ? projectData.teamText : ""
                        }
                    />
                </Grid>

                <Grid item xs={6} md={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            props.onChangeWysiwyg(editorState, "teamText2");
                        }}
                        title="Team Beschreibung"
                        defaultValue={
                            projectData.teamText2 ? projectData.teamText2 : ""
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        onClick={toggleSortingAction}
                        startIcon={
                            sortingActive ? <IconOpenWith /> : <IconGames />
                        }
                    >
                        {sortingActive
                            ? "Sortierung deaktivieren"
                            : "Sortierung aktivieren"}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} xl={9}>
                            {sortingActive && (
                                <SortableList
                                    items={participants}
                                    onSortEnd={onSortEnd}
                                    axis="xy"
                                />
                            )}

                            {!sortingActive && (
                                <Grid container direction="row" spacing={2}>
                                    {participants.map(
                                        (participant: ITeam, index: any) => (
                                            <TeamTile
                                                key={participant.name + index}
                                                participant={participant}
                                                handleEditClick={() =>
                                                    handleEditTeam(participant)
                                                }
                                                handleDeleteClick={() =>
                                                    handleDeleteTeam(
                                                        participant
                                                    )
                                                }
                                            />
                                        )
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <Paper variant="outlined" className="main-paper">
                                <TeamForm
                                    label="Teammitglied"
                                    participant={selectedParticipant}
                                    onEditHandler={onTeamFormChange}
                                    onCheckEditHandler={onTeamCheckFormChange}
                                    onImageChange={onTeamImageChange}
                                    action={participantAction}
                                    onCreateTeam={onTeamCreateClick}
                                    onTeamSave={onTeamSaveClick}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
            </Paper>
        </>
    );
};

export default ProjectTeamTab;
