import { CmsPageSyncBodyWrite } from "../../../../../types/ICmsPage";
import { DocumentIndex } from "../../../../../types/IDocument";
import { SnackbarStateSetter } from "../../../../../types/ISnackbarState";
import { createCmsPageRequest, updateCmsPageRequest } from "../../../../../xhr/CmsPageRequests";
import { CmsContentBlock, CmsPageData, CmsSectionBlock, CmsSyncPageData } from "../../../types/CmsPageData";
import { CmsContentMap, CmsSectionMap } from "./useBackend";

export interface UseSaveToBackendParams {
    setIsLoading: (isLoading: boolean) => void;
    isEditForm: boolean;
    mandantKey: string;
    pageData: CmsPageData;
    sectionIds: string[];
    sectionIndex: CmsSectionMap;
    contentIndex: CmsContentMap;
    imagesIndex: DocumentIndex;
    uploadsIndex: DocumentIndex;
    setSnackBarState: SnackbarStateSetter;
    closeChangesNotSavedWarning: () => void;
    setIsEditForm: (isEdit: boolean) => void;
    mapServerRequestToStates: (response: CmsSyncPageData) => void;
}

const mapFormDataToRequest = (
    data: CmsPageData,
    sectionIds: string[],
    sectionIndex: CmsSectionMap,
    contentIndex: CmsContentMap,
    images: DocumentIndex,
    uploads: DocumentIndex
): CmsPageSyncBodyWrite => {
    const contentBody = {
        pageProperties: data.pageProperties,
        elements: [],
    } as Pick<CmsPageData, "pageProperties" | "elements">;

    sectionIds.forEach((sectionId) => {
        const sectionEditor = sectionIndex[sectionId];
        const newSection = {
            ...sectionIndex[sectionId],
            content: [],
        } as CmsSectionBlock;

        sectionEditor.content.forEach((contentId) => {
            newSection.content.push({
                ...contentIndex[contentId],
            } as CmsContentBlock);
        });

        contentBody.elements.push(newSection);
    });

    return {
        ...data.data,
        flagShowTimelineHeader: data.data.flagShowTimelineHeader || false,
        content: contentBody,
        images,
        uploads,
    };
};

export function useSaveToBackend({
    setIsLoading,
    isEditForm,
    mandantKey,
    pageData,
    sectionIds,
    sectionIndex,
    contentIndex,
    imagesIndex,
    uploadsIndex,
    setSnackBarState,
    closeChangesNotSavedWarning,
    setIsEditForm,
    mapServerRequestToStates,
}: UseSaveToBackendParams) {
    const handleSave = () => {
        setIsLoading(true);

        if (isEditForm) {
            updateCmsPageRequest(
                mandantKey,
                pageData.data.id,
                mapFormDataToRequest(pageData, sectionIds, sectionIndex, contentIndex, imagesIndex, uploadsIndex)
            )
                .then((response) => {
                    mapServerRequestToStates(response.data);

                    setSnackBarState({
                        isOpen: true,
                        message: "Die Seite wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setIsLoading(false);
                    closeChangesNotSavedWarning();
                })
                .catch((error) => {
                    console.log("error", { error });
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Speichern der Seite ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            createCmsPageRequest(
                mandantKey,
                mapFormDataToRequest(pageData, sectionIds, sectionIndex, contentIndex, imagesIndex, uploadsIndex)
            )
                .then((response) => {
                    mapServerRequestToStates(response.data);

                    setIsEditForm(true);
                    setSnackBarState({
                        isOpen: true,
                        message: "Die Seite wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setIsLoading(false);
                    closeChangesNotSavedWarning();
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Erstellen der Seite ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    return {
        handleSave,
    };
}
