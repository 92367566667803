import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    ListItemSecondaryAction,
    TextField,
    Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Delete } from "@material-ui/icons";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import ColorPicker from "material-ui-color-picker";
import React, { useEffect, useState } from "react";

import Notify from "../../../../../components/notify/Notify";
import IMandant from "../../../../../types/IMandant";
import IMapProposalType from "../../../../../types/IMapProposalType";
import InitialSnackbarState from "../../../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../../../types/ISnackbarState";
import toInputString from "../../../../../utils/form/toInputString";
import {
    createProposalTypeRequest,
    deleteProposalTypeRequest,
    loadPublicProposalTypeList,
} from "../../../../../xhr/ProposalTypeRequests";

interface IProjectResultCardProps {
    projectId: number;
    mandant: IMandant;
}
const MapProposalTypeEditor = (props: IProjectResultCardProps) => {
    /**
     * Deconstructing
     */
    const { projectId, mandant } = props;

    /**
     * states
     */
    const [types, setTypes] = useState<IMapProposalType[]>([]);
    const [newType, setNewType] = useState<Partial<IMapProposalType>>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    /**
     * Backend sync / queries and mutations
     */

    const loadDataToState = () => {
        setLoading(true);

        loadPublicProposalTypeList(mandant.key, projectId)
            .then((response) => {
                setTypes(response.data);
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Projektdaten ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        loadDataToState();
    }, [mandant, projectId]);

    const deleteTypeAction = (typeId: number) => {
        setLoading(true);

        deleteProposalTypeRequest(mandant.key, typeId)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Der Type wurde gelöscht.",
                    type: "error",
                });

                loadDataToState();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Löschen des Datensatz ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    const createTypeAction = () => {
        setLoading(true);

        if (toInputString(newType.label) === "") {
            return;
        }
        if (toInputString(newType.color) === "") {
            return;
        }

        createProposalTypeRequest(mandant.key, {
            ...newType,
            project: { id: projectId },
        } as IMapProposalType)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Der neue Type wurde erstellt",
                    type: "info",
                });

                setNewType({});
                loadDataToState();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim erstellen des Types",
                    type: "error",
                });
                setLoading(false);
            });
    };

    /**
     Additional actions
     */

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    /**
        Form handling
     */

    const handleTextChange = (event: any) => {
        const newData = {
            ...newType,
            [event.target.name]: event.target.value,
        };
        setNewType(newData);
    };

    const handleColorChange = (color: string) => {
        const newData = {
            ...newType,
            color,
        };
        setNewType(newData);
    };

    /**
     * Renderlogic
     */

    return (
        <Grid item xs={12}>
            <Typography variant="h3">Kommentar Typen</Typography>
            <List dense={true} style={{ maxWidth: "400px" }}>
                {types.map((typeDset, index) => {
                    return (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ColorLensIcon
                                        style={{ fill: typeDset.color }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={typeDset.label}
                                secondary={typeDset.description}
                            />
                            <ListItemSecondaryAction
                                onClick={() => {
                                    deleteTypeAction(typeDset.id);
                                }}
                            >
                                <IconButton edge="end" aria-label="delete">
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>

            <Card style={{ overflow: "visible" }}>
                <CardHeader>Typ erstellen</CardHeader>
                <CardContent>
                    <ColorPicker
                        name="color"
                        inputProps={{
                            style: {
                                backgroundColor: toInputString(newType.color),
                            },
                        }}
                        label="Farbe"
                        defaultValue={toInputString(newType.color)}
                        value={toInputString(newType.color)}
                        onChange={handleColorChange}
                        variant="outlined"
                        required={true}
                    />
                    <TextField
                        name="label"
                        label="Label"
                        fullWidth
                        value={toInputString(newType.label)}
                        onChange={handleTextChange}
                        variant="outlined"
                        required={true}
                    />
                    <TextField
                        name="description"
                        label="(Kurz) Beschreibung"
                        fullWidth
                        value={toInputString(newType.description)}
                        onChange={handleTextChange}
                        variant="outlined"
                        required={true}
                    />
                </CardContent>
                <CardActions>
                    <Button color={"primary"} onClick={createTypeAction}>
                        Speichern
                    </Button>
                </CardActions>
            </Card>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </Grid>
    );
};
export default MapProposalTypeEditor;
