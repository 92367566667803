import { Avatar, Card, CardActions, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { Email, Phone } from "@material-ui/icons";
import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import React, { memo } from "react";

import AvatarImg from "../../assets/images/avatar.svg"; // this image must be replaced
import { API_URL_STATIC } from "../../config/config";
import ITeam from "../../types/ITeam";

type GridSizes = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IParentProps {
    participant: ITeam;
    fullWidth?: boolean;
    onClick?: (event: any) => void;
    handleEditClick?: () => void;
    handleDeleteClick?: () => void;
}

interface ITeamCardProps extends IParentProps {}

function TeamTile(props: ITeamCardProps) {
    const { participant, fullWidth, onClick } = props;

    if (participant == undefined) {
        return <></>;
    }

    const sizes: { [key: string]: GridSizes } = fullWidth
        ? { xs: 12, sm: 12, md: 12, lg: 12 }
        : { xs: 12, sm: 6, md: 6, lg: 4 };

    return (
        <Grid item xs={sizes.xs} sm={sizes.sm} md={sizes.md} lg={sizes.lg}>
            <Card variant="outlined" className="team-card" onClick={onClick} style={{ height: "100%" }}>
                <CardHeader
                    title={participant?.displayTitle ? participant.displayTitle : ""}
                    subheader={participant?.company ? participant.company : ""}
                />
                <CardContent>
                    <Avatar
                        alt={participant?.name ? participant.name : "Avatar"}
                        src={
                            participant?.image?.url != undefined ? API_URL_STATIC + participant?.image?.url : AvatarImg
                        }
                    />

                    {participant?.salutation && (
                        <Typography variant="body2" color="textSecondary" component="p" className="main-header">
                            {participant?.salutation ? participant.salutation : ""}
                        </Typography>
                    )}

                    {participant?.name && (
                        <Typography variant="body2" color="textSecondary" component="p">
                            {participant?.name ? participant.name : ""}
                        </Typography>
                    )}

                    {participant?.street && (
                        <Typography variant="body2" color="textSecondary" component="p">
                            {participant?.street ? participant.street : ""}
                        </Typography>
                    )}

                    {participant?.city && (
                        <Typography variant="body2" color="textSecondary" component="p">
                            {participant?.city ? participant.city : ""}
                        </Typography>
                    )}

                    {participant?.zip && (
                        <Typography variant="body2" color="textSecondary" component="p">
                            {participant?.zip ? participant.zip : ""}
                        </Typography>
                    )}

                    {participant.phone && (
                        <Grid container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <Icon>
                                    <Phone />
                                </Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" color="textPrimary" component="p" align="left">
                                    {participant.phone}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {participant.email && (
                        <Grid container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <Icon>
                                    <Email />
                                </Icon>
                            </Grid>
                            <Typography variant="body1" color="textPrimary" component="p" align="left">
                                {participant.email}
                            </Typography>
                        </Grid>
                    )}
                </CardContent>
                {props.handleEditClick && (
                    <CardActions>
                        <IconButton onClick={props.handleEditClick} title="Editieren">
                            <IconEdit />
                        </IconButton>
                        <IconButton color="secondary" onClick={props.handleDeleteClick} title="Löschen">
                            <IconDelete />
                        </IconButton>
                    </CardActions>
                )}
            </Card>
        </Grid>
    );
}

export default memo(TeamTile);
