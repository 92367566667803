import { Alert } from "@material-ui/lab";
import React from "react";

import { CmsEditLayoutOne } from "./CmsEditLayoutOne";
import { CmsEditLayoutOneOne } from "./CmsEditLayoutOneOne";
import { CmsEditLayoutOneOneOne } from "./CmsEditLayoutOneOneOne";
import { CmsEditLayoutOneOneOneOne } from "./CmsEditLayoutOneOneOneOne";
import { CmsEditLayoutOneTwo } from "./CmsEditLayoutOneTwo";
import { CmsEditLayoutOneTwoOne } from "./CmsEditLayoutOneTwoOne";
import { CmsEditLayoutTwoOne } from "./CmsEditLayoutTwoOne";
import { CmsLayoutComponentProps } from "./CmsLayoutComponentProps";

export function CmsLayoutMapper({ section, sectionPosition }: CmsLayoutComponentProps) {
    switch (section.layout) {
        case "l_1": {
            return <CmsEditLayoutOne section={section} sectionPosition={sectionPosition} />;
        }
        case "l_1_1": {
            return <CmsEditLayoutOneOne section={section} sectionPosition={sectionPosition} />;
        }
        case "l_1_1_1": {
            return <CmsEditLayoutOneOneOne section={section} sectionPosition={sectionPosition} />;
        }
        case "l_1_1_1_1": {
            return <CmsEditLayoutOneOneOneOne section={section} sectionPosition={sectionPosition} />;
        }
        case "l_1_2": {
            return <CmsEditLayoutOneTwo section={section} sectionPosition={sectionPosition} />;
        }
        case "l_2_1": {
            return <CmsEditLayoutTwoOne section={section} sectionPosition={sectionPosition} />;
        }
        case "l_1_2_1": {
            return <CmsEditLayoutOneTwoOne section={section} sectionPosition={sectionPosition} />;
        }

        default: {
            console.error("missing layout type: ", { section });
            return <Alert severity={"error"}>Missing Layout Type: {section.layout}</Alert>;
        }
    }
}
