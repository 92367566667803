import { createContext, useContext } from "react";

import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import IProjectFull from "../../../types/IProjectFull";

interface ActiveProjectContextProps {
    project: IProjectFull | undefined;
    actualUser?: IUser | undefined;
    mandant: IMandant;
}

export const ActiveProjectContext = createContext<ActiveProjectContextProps | null>(null);

/**
 * The context to pass Controls of Data grids
 */
export function useActiveProjectContext(): ActiveProjectContextProps {
    const data = useContext<ActiveProjectContextProps | null>(ActiveProjectContext);

    if (data == null) {
        // should not happen
        throw new Error("Context must not be null!");
    }

    return data;
}
