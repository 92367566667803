import { CmsPageData } from "../../types/CmsPageData";

export const initialCmsPageData: CmsPageData = {
    data: {
        id: 0,
        urlKey: "",
        title: "",
        flagPublished: false,
    },
    pageProperties: {},
    elements: [],
};
