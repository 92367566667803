import { Alert } from "@material-ui/lab";
import React, { ReactNode, useState } from "react";

import { CmsSectionIndexBlock } from "../../../types/CmsPageData";
import { useCmsEditorContext } from "../../masks/CmsEditorContext";
import { CmsContentContext } from "./CmsContentContext";
import { CmsContentAttachment } from "./contentTypes/CmsContentAttachment";
import { CmsContentAttachmentList } from "./contentTypes/CmsContentAttachmentList";
import { CmsContentCalculator } from "./contentTypes/CmsContentCalculator";
import { CmsContentComments } from "./contentTypes/CmsContentComments";
import { CmsContentFaq } from "./contentTypes/CmsContentFaq";
import { CmsContentHeader } from "./contentTypes/CmsContentHeader";
import { CmsContentHtml } from "./contentTypes/CmsContentHtml";
import { CmsContentImage } from "./contentTypes/CmsContentImage";
import { CmsContentImageCarousel } from "./contentTypes/CmsContentImageCarousel";
import { CmsContentMapComments } from "./contentTypes/CmsContentMapComments";
import { CmsContentPerson } from "./contentTypes/CmsContentPerson";
import { CmsContentPersonList } from "./contentTypes/CmsContentPersonList";
import { CmsContentText } from "./contentTypes/CmsContentText";

export interface CmsContentProps {
    contentId: string;
    position: number;
    sectionNode: CmsSectionIndexBlock;
    sectionPosition: number;
}

export function CmsContentMapper({ contentId, position, sectionNode, sectionPosition }: CmsContentProps) {
    const { contentIndex, sectionIds, deleteSectionAction } = useCmsEditorContext();

    const [editMode, setEditMode] = useState<boolean>(sectionIds.length == sectionPosition + 1);

    const startEditMode = () => {
        setEditMode(true);
    };
    const stopEditMode = () => {
        setEditMode(false);
    };

    const contentNode = contentIndex[contentId];

    let content: ReactNode;

    switch (contentNode.type) {
        case "header_1": {
            content = (
                <CmsContentHeader
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                    headerType={"h1"}
                />
            );
            break;
        }
        case "header_2": {
            content = (
                <CmsContentHeader
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                    headerType={"h2"}
                />
            );
            break;
        }
        case "header_3": {
            content = (
                <CmsContentHeader
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                    headerType={"h3"}
                />
            );
            break;
        }
        case "header_4": {
            content = (
                <CmsContentHeader
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                    headerType={"h4"}
                />
            );
            break;
        }
        case "text": {
            content = (
                <CmsContentText
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "attachment": {
            content = (
                <CmsContentAttachment
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "attachment_list": {
            content = (
                <CmsContentAttachmentList
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }

        case "image": {
            content = (
                <CmsContentImage
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "carousel": {
            content = (
                <CmsContentImageCarousel
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "html": {
            content = (
                <CmsContentHtml
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "person": {
            content = (
                <CmsContentPerson
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "person_list": {
            content = (
                <CmsContentPersonList
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "calculator": {
            content = (
                <CmsContentCalculator
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "faq": {
            content = (
                <CmsContentFaq
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "comments": {
            content = (
                <CmsContentComments
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        case "map_comments": {
            content = (
                <CmsContentMapComments
                    contentNode={contentNode}
                    editMode={editMode}
                    startEditMode={startEditMode}
                    stopEditMode={stopEditMode}
                    showLayoutMenu={position === 0}
                />
            );
            break;
        }
        default: {
            content = <Alert severity={"error"}>Missing Content Type: {contentNode.type}</Alert>;
        }
    }
    return (
        <CmsContentContext.Provider
            value={{
                sectionPosition: sectionPosition,
                sectionNode: sectionNode,
                numOfSections: sectionIds.length,
                deleteSectionAction,
            }}
        >
            {content}
        </CmsContentContext.Provider>
    );
}
