import { ICmsPageData } from "../../../../../types/ICmsPage";
import { CmsPageData } from "../../../types/CmsPageData";
import { mapLegacySectionsToNew } from "./legacy/mapLegacyCmsToNew";

export function handleCmsData(pageDataFromServer: ICmsPageData, pageContent: unknown): CmsPageData {
    if (Array.isArray(pageContent)) {
        return {
            data: pageDataFromServer,
            pageProperties: {},
            elements: mapLegacySectionsToNew(pageContent),
        };
    } else {
        return {
            data: pageDataFromServer,
            ...(pageContent as Partial<CmsPageData>),
        } as CmsPageData;
    }
}
