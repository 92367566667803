import { AxiosResponse } from "axios";

import { CmsSyncPageData } from "../components/cms/types/CmsPageData";
import { ADMIN_CMS_PAGE, ADMIN_CMS_PAGE_SELECTBOX, PORTAL_LOAD_CMS_PAGE } from "../enums/Endpoints";
import { CmsPageSyncBodyWrite, ICmsPageChild } from "../types/ICmsPage";
import { deleteData, getData, postData, putData } from "../utils/httpRequest/request";
import SyncCmsPage from "./interface/ISyncCmsPage";

/**
 * Project list
 */
const loadPublicCmsPage = (mandant: string, pageKey: string) => {
    return getData<CmsSyncPageData>(PORTAL_LOAD_CMS_PAGE + pageKey, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadPublicCmsPageByProjectKey = (mandant: string, projectKey: string, pageKey: string) => {
    return getData<CmsSyncPageData>(PORTAL_LOAD_CMS_PAGE + projectKey + "/" + pageKey, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadCmsPageListRequest = (mandant: string) => {
    return getData<SyncCmsPage>(ADMIN_CMS_PAGE, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadCmsPageProjectSelectboxRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<ICmsPageChild[]>> => {
    return getData<ICmsPageChild[]>(ADMIN_CMS_PAGE_SELECTBOX + projectId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadCmsPageRequest = (mandant: string, pageId: number) => {
    return getData<CmsSyncPageData>(ADMIN_CMS_PAGE + pageId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const createCmsPageRequest = (mandant: string, pageData: CmsPageSyncBodyWrite) => {
    return postData<CmsPageSyncBodyWrite, CmsSyncPageData>(ADMIN_CMS_PAGE, pageData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const updateCmsPageRequest = (mandant: string, pageId: number, pageData: CmsPageSyncBodyWrite) => {
    return putData<CmsPageSyncBodyWrite, CmsSyncPageData>(ADMIN_CMS_PAGE, pageId, pageData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const deleteCmsPageRequest = (mandant: string, pageId: number | string): Promise<any> => {
    return deleteData<SyncCmsPage>(ADMIN_CMS_PAGE, pageId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export {
    loadPublicCmsPage,
    loadCmsPageListRequest,
    loadCmsPageRequest,
    deleteCmsPageRequest,
    createCmsPageRequest,
    updateCmsPageRequest,
    loadCmsPageProjectSelectboxRequest,
    loadPublicCmsPageByProjectKey,
};
