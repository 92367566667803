import React from "react";

import { CmsContentBlockImage } from "../../../types/CmsPageData";
import { useCmsDataContext } from "../../CmsDataContext";
import CmsImage from "../components/CmsImage";

interface CmsRenderContentImageProps {
    content: CmsContentBlockImage;
}

export const CmsRenderContentImage = (props: CmsRenderContentImageProps) => {
    const { content } = props;

    const { imagesIndex } = useCmsDataContext();

    return <CmsImage image={imagesIndex[content.data.key]} className="portal-cms-image" />;
};
