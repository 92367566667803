import { Button, Divider, Grid, LinearProgress } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import React from "react";

import IMandant from "../../../../types/IMandant";
import { SnackbarStateSetter } from "../../../../types/ISnackbarState";
import { toPortalUrl } from "../../../../utils/toPortalUrl";
import { CmsLayoutMapper } from "../components/layouts/CmsLayoutMapper";
import { useCmsEditorContentLayouts } from "../logic/elements/useCmsEditorContentLayouts";
import { useChangesNotSaved } from "../logic/useChangesNotSaved";
import { CmsEditorContext } from "./CmsEditorContext";
import { CmsPageDataForm } from "./CmsPageDataForm";
import { ElementLayoutList } from "./controls/ElementLayoutList";
import { useBackend } from "./logic/useBackend";
import { useChangeDataLogic } from "./logic/useChangeDataLogic";

export const initialCmsPageState: CmsPageState = {
    isEditForm: false,
    pageTitle: "",
};

export interface CmsPageState {
    isEditForm: boolean;
    pageTitle: string;
}

interface ICmsPageEditor {
    mandant: IMandant;
    pageId: string;
    setPageState: (state: CmsPageState) => void;
    setSnackBarState: SnackbarStateSetter;
}

export function CmsPageEditor({ mandant, pageId, setPageState, setSnackBarState }: ICmsPageEditor) {
    const confirm = useConfirm();

    const { changesNotSavedState, closeChangesNotSavedWarning, setFormHasChangesAction } = useChangesNotSaved();

    const {
        isLoading,
        pageData,
        setPageData,
        // content indices
        sectionIndex,
        setSectionIndex,
        contentIndex,
        setContentIndex,
        sectionIds,
        setSectionIds,
        // upload indices
        imagesIndex,
        setImagesIndex,
        uploadsIndex,
        setUploadsIndex,

        //
        projectList,
        teamIndex,
        //
        handleSave,
    } = useBackend({
        mandantKey: mandant.key,
        pageId: Number(pageId),
        closeChangesNotSavedWarning,
        setSnackBarState,
        setPageState,
    });

    const {
        handleTextChange,
        handleCheckboxChange,
        changeProject,
        handlePropertiesTextChange,
        handlePropertiesImageChange,
    } = useChangeDataLogic({
        pageData,
        setPageData,
        imagesIndex,
        setImagesIndex,
        setFormHasChangesAction,
    });

    const { addNewPageSectionAction, deleteSectionAction } = useCmsEditorContentLayouts({
        pageData,
        setPageData,
        setFormHasChangesAction,

        sectionIndex,
        setSectionIndex,
        contentIndex,
        setContentIndex,
        sectionIds,
        setSectionIds,
    });

    const handleOpenPreviewForPage = () => {
        const projectUrlKey = pageData.data?.project?.urlKey;

        const previewUrl = projectUrlKey
            ? projectUrlKey + "/seite/" + pageData.data.urlKey
            : "/seite/" + pageData.data.urlKey;

        if (changesNotSavedState) {
            confirm({
                description:
                    "Die Seite hat ungespeicherte Änderungen. Bitte speichern Sie zuerst die Seite wenn Sie die Änderungen sehen möchten.",
                confirmationText: "Trotzdem anschauen",
                cancellationText: "Erst speichern",
                title: "Inhalt anzeigen",
            }).then(() => {
                window.open(toPortalUrl(mandant, previewUrl), "preview");
            });
        } else {
            window.open(toPortalUrl(mandant, previewUrl), "preview");
        }
    };

    if (isLoading) {
        return <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button onClick={handleSave} color={"primary"}>
                    Seite speichern
                </Button>

                <Button onClick={handleOpenPreviewForPage} color="secondary">
                    Vorschau anzeigen
                </Button>
            </Grid>
            <Grid item xs={10} className={"gap-bottom"}>
                <CmsPageDataForm
                    pageData={pageData}
                    handleTextChange={handleTextChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handlePropertiesTextChange={handlePropertiesTextChange}
                    handlePropertiesImageChange={handlePropertiesImageChange}
                    changeProject={changeProject}
                    projectList={projectList}
                    imagesIndex={imagesIndex}
                />

                <Divider
                    className="divider"
                    style={{
                        marginBottom: "32px",
                    }}
                />

                <CmsEditorContext.Provider
                    value={{
                        pageData,
                        setPageData,

                        sectionIndex,
                        setSectionIndex,
                        contentIndex,
                        setContentIndex,
                        sectionIds,
                        setSectionIds,

                        imagesIndex,
                        setImagesIndex,
                        uploadsIndex,
                        setUploadsIndex,
                        teamIndex,

                        setFormHasChangesAction,
                        deleteSectionAction,
                    }}
                >
                    <Grid container spacing={2}>
                        {sectionIds.map((sectionId: string, index) => {
                            return (
                                <CmsLayoutMapper
                                    key={sectionId}
                                    section={sectionIndex[sectionId]}
                                    sectionPosition={index}
                                />
                            );
                        })}
                    </Grid>
                </CmsEditorContext.Provider>
            </Grid>

            <Grid item xs={2}>
                <ElementLayoutList addPageSectionAction={addNewPageSectionAction} />
            </Grid>
        </Grid>
    );
}
