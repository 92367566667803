import { TextareaAutosize, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";

import { CmsContentBlock, CmsContentBlockText } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentTextProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockText;
}

export function CmsContentText({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentTextProps) {
    const { contentIndex, setContentIndex, setFormHasChangesAction } = useCmsEditorContext();

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    const updateContentData = useCallback(
        (newText: string) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...contentNode,
                    data: newText,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    useEffect(() => {
        if (!editMode && contentNode.data.length == 0) {
            startEditMode();
        }

        if (Array.isArray(contentNode.data) || typeof contentNode.data == "object") {
            // set an empty string if the content is an object or an array
            updateContentData("");
        }
    }, [contentNode.data, editMode]);

    if (!editMode) {
        return (
            <Typography variant={"body2"} onClick={startEditMode} className={"cmsHoverContent"}>
                {contentNode.data ? contentNode.data : ""}
            </Typography>
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <TextareaAutosize
                value={contentNode.data ? contentNode.data : ""}
                onChange={(event) => {
                    updateContentData(event.target.value);
                }}
                style={{
                    width: "100%",
                    minHeight: "100px",
                }}
            />
        </CmsContentEditor>
    );
}
