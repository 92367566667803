import React, { useCallback, useEffect, useMemo } from "react";

import ITeam from "../../../../../../types/ITeam";
import TeamTile from "../../../../../team/TeamTile";
import { CmsContentBlock, CmsContentBlockPerson } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";
import { CmsPersonSelector } from "./components/CmsPersonSelector";

export interface CmsContentPersonProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockPerson;
}

export function CmsContentPerson({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentPersonProps) {
    useEffect(() => {
        if (!editMode && contentNode.data.id == undefined) {
            startEditMode();
        }
    }, [contentNode.data, editMode]);

    const { contentIndex, setContentIndex, setFormHasChangesAction, teamIndex } = useCmsEditorContext();

    const teamListSorted = useMemo(() => {
        return Object.values(teamIndex).sort((left, right) => {
            if (left.name == right.name) {
                return 0;
            }

            return left.name > right.name ? 1 : -1;
        });
    }, [teamIndex]);

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlockPerson,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    const changeTeamHandler = (teamId: string) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: {
                    id: teamId,
                },
            } as CmsContentBlockPerson,
        });

        setFormHasChangesAction();
    };

    if (!editMode) {
        return (
            <TeamTile onClick={startEditMode} participant={teamIndex[contentNode.data.id] as ITeam} fullWidth={true} />
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <CmsPersonSelector
                value={"" + contentNode.data.id}
                changeTeamHandler={changeTeamHandler}
                idKey={contentNode.id}
                teamList={teamListSorted}
            />
        </CmsContentEditor>
    );
}
