import "./Footer.css";

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import CmsImage from "../../components/cms/renderer/content/components/CmsImage";
import IMandant from "../../types/IMandant";

interface IOwnProps {
    mandant: IMandant;
}

const PartnerLogoList = (props: IOwnProps) => {
    const { mandant } = props;

    if (!mandant.partnerLogos?.length) {
        return <></>;
    }
    const showDesktopLogo = () => {
        return (
            <div className="App-footer-logo-container">
                {mandant.partnerLogos?.map((partnerLogo) => {
                    if (!["footer", "both"].includes(partnerLogo.position)) {
                        return <></>;
                    }

                    if (partnerLogo.url) {
                        return (
                            <a
                                href={partnerLogo.url}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <CmsImage
                                    className="App-footer-logo-customer"
                                    image={partnerLogo.document}
                                />
                            </a>
                        );
                    }

                    return (
                        <CmsImage
                            className="App-footer-logo-customer"
                            image={partnerLogo.document}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <Grid item xs={12} className="gap-top">
                <Typography variant="h3" className="gap-top gap-bottom">
                    {mandant.partnerLogosLabel
                        ? mandant.partnerLogosLabel
                        : "Gefördert von"}
                </Typography>
                <div className="footer-partner-logo-container">
                    {showDesktopLogo()}
                </div>
            </Grid>
        </>
    );
};

export default PartnerLogoList;
