import "../ProjectData.css";

import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Divider, Grid, LinearProgress, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { AttachFile } from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocal from "date-fns/locale/de";
import moment from "moment";
import React, { useState } from "react";

import campaignPlaceHolder from "./../../../../assets/images/campaign-placeholder.png";
import HtmlContent from "../../../../components/cms/renderer/content/components/HtmlContent";
import Notify from "../../../../components/notify/Notify";
import { API_URL_STATIC, Format, getTimeInterval } from "../../../../config/config";
import ICampaign from "../../../../types/appointment/ICampaign";
import ICampaignAppointmentList from "../../../../types/appointment/ICampaignAppointmentList";
import IPortalAppointment from "../../../../types/appointment/IPortalAppointment";
import IPortalBookingAppointment from "../../../../types/appointment/IPortalBookingAppointment";
import IPortalBookingParticipant from "../../../../types/appointment/IPortalBookingParticipant";
import IMandant from "../../../../types/IMandant";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../../types/ISnackbarState";
import IUserData from "../../../../types/IUserData";
import { getExtension } from "../../../../utils/checkFileAndDecideIcon";
import { createAppointmentBookingRequest, loadPublicAppointmentDataById } from "../../../../xhr/AppointmentRequests";
import AppointmentForm from "../components/AppointmentForm";
import AppointmentSuccessMessage from "../components/AppointmentSuccessMessage";

interface IProjectCampaignTabProps {
    mandant: IMandant;
    campaign: ICampaign;
}

interface IMinMax {
    min: Date;
    max: Date;
}

enum CampaignFormStep {
    START = 0,
    FORM = 1,
    SUCCESS = 2,
}

function ProjectCampaignTab(props: IProjectCampaignTabProps) {
    const { mandant, campaign } = props;
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [campaignSerialNumber, setCampaignSerialNumber] = useState<string>("");
    const [selectableAppointments, setSelectableAppointments] = useState<ICampaignAppointmentList[]>([]);

    const [activeAppointmentDates, setActiveAppointmentDates] = useState<string[]>([]);

    const [selectedAppointment, setSelectedAppointment] = useState<ICampaignAppointmentList | null>(null);

    const [loadedAppointment, setLoadedAppointment] = useState<IPortalAppointment | null>(null);

    const [userData, setUserData] = useState<Partial<IUserData>>({});
    const [appointmentFormData, setAppointmentFormData] = useState<Partial<IPortalAppointment>>({});
    const [participantData, setParticipantData] = useState<IPortalBookingParticipant | null>(null);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [formStep, setFormStep] = React.useState<CampaignFormStep>(CampaignFormStep.START);

    const [minMax, setMinMax] = React.useState<IMinMax>({
        min: new Date(),
        max: new Date(),
    });

    React.useEffect(() => {
        // there must be appointments to execute this step
        if (!campaign.campaignAppointments.length) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const newMinMax: IMinMax = setMinMaxByAppointments(campaign.campaignAppointments);

        setMinMax(newMinMax);
        setSelectedDate(newMinMax.min);
        setSelectableAppointments(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            createStackByDate(newMinMax.min, campaign.campaignAppointments)
        );
        setActiveAppointmentDates(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getActiveAppointmentDates(campaign.campaignAppointments)
        );

        //campaign.campaignAppointments
    }, [campaign]);

    React.useEffect(() => {
        // only load if we have an id
        if (selectedAppointment?.id) {
            loadPublicAppointmentDataById(mandant?.key as string, selectedAppointment?.id + "").then((result) => {
                setLoadedAppointment(result.data);

                if (!Object.keys(appointmentFormData).length) {
                    setAppointmentFormData(result.data);
                }
                // set the user information if not
                if (!Object.keys(userData).length) {
                    if (result.data?.bookingParticipant?.user) {
                        setUserData(result.data.bookingParticipant.user);
                    }
                }
            });
        }
    }, [selectedAppointment]);

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const handleAppointmentSubmitEvent = () => {
        if (loadedAppointment?.id) {
            const appointmentBookingData: Partial<IPortalBookingAppointment> = {
                id: loadedAppointment?.id,
                isGroup: !!appointmentFormData.additionalPerson,
                user: userData,
                ...appointmentFormData,
            };
            createAppointmentBookingRequest(mandant?.key, appointmentBookingData)
                .then((result) => {
                    setParticipantData(result.data.bookingParticipant);

                    setSnackBarState({
                        isOpen: true,
                        message: "Ihre Buchung wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setIsLoading(false);
                    setFormStep(CampaignFormStep.SUCCESS);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Erstellen der Buchung ist ein Fehler aufgetreten.",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    const handleShowCalendar = () => {
        setFormStep(CampaignFormStep.START);
    };

    const setMinMaxByAppointments = (appointments: ICampaignAppointmentList[]): IMinMax => {
        const first = appointments[0];
        const last = appointments[appointments.length - 1];

        return {
            min: new Date(first.startDate),
            max: new Date(last.startDate),
        };
    };

    const createStackByDate = (activeDate: Date, appointments: ICampaignAppointmentList[]) => {
        const selected = moment(activeDate).format(Format.date);

        const theIndex: any = {};
        const completeFiltered: ICampaignAppointmentList[] = [];

        const preFiltered = appointments.filter((checkMe: ICampaignAppointmentList) => {
            return selected === moment(checkMe.startDate).format(Format.date);
        });

        preFiltered.forEach((checkMe: ICampaignAppointmentList) => {
            const checkStart: string = moment(checkMe.startDate).format(Format.dateTime);
            if (theIndex[checkStart] !== undefined) {
                return;
            }
            theIndex[checkStart] = true;
            completeFiltered.push(checkMe);
        });

        return completeFiltered;
    };

    const getActiveAppointmentDates = (appointments: ICampaignAppointmentList[]) => {
        const appointmentDates = appointments.map((appointment: ICampaignAppointmentList) =>
            moment(appointment.startDate).format(Format.date)
        );

        const uniqueActiveDates = [...Array.from(new Set(appointmentDates))];
        return uniqueActiveDates;
    };

    const handleSelectedDateChange = (date: any) => {
        setSelectedDate(date);

        setSelectableAppointments(createStackByDate(date as Date, campaign.campaignAppointments));
    };

    const isPdf = getExtension(campaign.document?.name) === "pdf";

    switch (formStep) {
        case CampaignFormStep.START: {
            return (
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="appointment-container"
                >
                    {isLoading && <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />}
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography gutterBottom variant="h2" className="main-header gap-top gap-bottom">
                            {campaign.title}
                        </Typography>
                        <Typography variant="h3" className="main-header">
                            Von: {moment(campaign.startDate).format(Format.date)} bis:{" "}
                            {moment(campaign.endDate).format(Format.date)}
                        </Typography>
                        <Typography variant="h3" className="main-header gap-bottom">
                            Ort: {campaign.locationLink && <a href={campaign.locationLink}>{campaign.location}</a>}
                            {!campaign.locationLink && <>{campaign.location}</>}
                        </Typography>

                        <HtmlContent className="content-block gap-bottom" content={campaign.description} />

                        <Typography gutterBottom variant="body1" className="gap-bottom">
                            Buchungen können bis: <strong>{moment(campaign.bookingEndDate).format(Format.date)}</strong>{" "}
                            vorgenommen werden.
                        </Typography>

                        {campaign.document && (
                            <>
                                <Typography variant="h3">Zusätzliche Informationen als Download</Typography>
                                <List className="gap-bottom">
                                    <Divider />

                                    <div>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <a
                                                    href={API_URL_STATIC + campaign?.document?.url}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    <Avatar>{isPdf ? <PictureAsPdfIcon /> : <AttachFile />}</Avatar>
                                                </a>
                                            </ListItemAvatar>
                                            <ListItemText
                                                className="clickable"
                                                onClick={() => {
                                                    window.location.href = API_URL_STATIC + campaign?.document?.url;
                                                }}
                                                primary={campaign.document.name}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </div>
                                </List>
                            </>
                        )}

                        <Grid container spacing={2} direction="row" justifyContent="center" className="gap-top">
                            <Grid item xs={12} sm={6} lg={8} className="campaign-portal-calendar">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocal}>
                                    <Calendar
                                        minDate={minMax.min}
                                        maxDate={minMax.max}
                                        date={selectedDate}
                                        shouldDisableDate={(date) =>
                                            activeAppointmentDates.indexOf(moment(date).format(Format.date)) < 0
                                        }
                                        onChange={handleSelectedDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                {!selectableAppointments.length && (
                                    <Alert color="info">
                                        Es sind leider keine Termine mehr für diesen Tag verfügbar.
                                    </Alert>
                                )}
                                {selectableAppointments.length > 0 && (
                                    <ToggleButtonGroup orientation="vertical" exclusive className="fullWidth">
                                        {selectableAppointments?.map((apt: ICampaignAppointmentList, index: number) => {
                                            return (
                                                <ToggleButton
                                                    key={index}
                                                    value={apt.id}
                                                    aria-label="one"
                                                    selected={apt.id === selectedAppointment?.id}
                                                    onClick={() => {
                                                        setSelectedAppointment(apt);
                                                    }}
                                                >
                                                    {getTimeInterval(apt.startDate, apt.duration)}
                                                </ToggleButton>
                                            );
                                        })}
                                    </ToggleButtonGroup>
                                )}
                            </Grid>
                            {selectableAppointments.length > 0 && (
                                <Grid item xs={12}>
                                    <div className="gap-top">
                                        <Button
                                            disabled={!selectedAppointment}
                                            color="primary"
                                            onClick={() => setFormStep(CampaignFormStep.FORM)}
                                        >
                                            Fortfahren
                                        </Button>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                pr: 5,
                                pt: 5,
                            }}
                        >
                            {campaign?.image?.url ? (
                                <img
                                    src={API_URL_STATIC + campaign?.image?.url}
                                    alt={campaign.image.description}
                                    style={{ maxWidth: "100%" }}
                                />
                            ) : (
                                <img src={campaignPlaceHolder} alt="campaign-not-found" style={{ maxWidth: "100%" }} />
                            )}
                        </Box>
                    </Grid>

                    <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
                </Grid>
            );
        }
        case CampaignFormStep.FORM: {
            return (
                <AppointmentForm
                    campaignData={campaign}
                    handleSubmitEvent={handleAppointmentSubmitEvent}
                    handlePrevious={handleShowCalendar}
                    mandant={mandant}
                    appointment={loadedAppointment as IPortalAppointment}
                    userData={userData}
                    setUserData={setUserData}
                    appointmentFormData={appointmentFormData}
                    setAppointmentFormData={setAppointmentFormData}
                    campaignSerialNumber={campaignSerialNumber}
                    setCampaignSerialNumber={setCampaignSerialNumber}
                />
            );
        }
        case CampaignFormStep.SUCCESS: {
            return (
                <AppointmentSuccessMessage
                    campaignData={campaign}
                    appointment={loadedAppointment as IPortalAppointment}
                    participant={participantData as IPortalBookingParticipant}
                    mandant={mandant}
                    userData={userData}
                />
            );
        }
    }
}

export default ProjectCampaignTab;
