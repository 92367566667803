import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

import IDeadlineList from "../../types/IDeadlineList";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: any
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
interface IDeleteDialog {
    deadline: IDeadlineList;
    deleteHandler: (row: IDeadlineList) => void;
}

export default function AlertDialogSlide(props: IDeleteDialog) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAgree = (row: IDeadlineList) => {
        setOpen(false);
        props.deleteHandler(row);
    };

    return (
        <>
            <IconButton onClick={handleClickOpen} size={"small"}>
                <DeleteIcon />
            </IconButton>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <DialogTitle id="alert-dialog-slide-title" />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Bitte bestätigen Sie, dass Sie den Datensatz löschen möchten.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseAgree(props.deadline)} color="primary">
                        Den Datensatz löschen
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
