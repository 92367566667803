import {
    Breadcrumbs,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import moment from "moment";
import React, { SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import { Format } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IPortalCommentList from "../../../types/IPortalCommentList";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    deleteCommentRequest,
    loadCommentList,
    updateCommentRequest,
} from "../../../xhr/CommentRequests";

interface IPortalCommentListingParams {
    mandant: IMandant;
}

const CommentListing = (props: IPortalCommentListingParams) => {
    const history = useHistory();
    const { mandant } = props;
    const [commentList, setCommentList] = React.useState<IPortalCommentList[]>(
        []
    );
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        loadCommentList(mandant.key)
            .then((result: any) => {
                setCommentList(result.data);
                return Promise.resolve();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    }, [mandant.key]);

    if (!mandant.id) {
        return <></>;
    }

    const actionMarkAsAswered = (event: SyntheticEvent<HTMLSpanElement>) => {
        const commentId: number = parseInt("" + event.currentTarget.dataset.id);

        const index = commentList.findIndex((search) => {
            return commentId === search.id;
        });

        updateCommentRequest(mandant.key, commentId, {
            flagAccepted: true,
        })
            .then(() => {
                commentList[index].flagAccepted = true;
                setCommentList([...commentList]);

                setSnackBarState({
                    isOpen: true,
                    message: "Die Nachricht wurde als beantwortet markiert",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Die Nachricht konnte nicht als beantwortet markiert werden",
                    type: "error",
                });
            });
    };

    const actionDeleteComment = (event: SyntheticEvent<HTMLSpanElement>) => {
        const commentId: number = parseInt("" + event.currentTarget.dataset.id);

        deleteCommentRequest(mandant.key, commentId)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Anfrage wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadCommentList(mandant.key)
                    .then((result: any) => {
                        setCommentList(result.data);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Löschen der Anfrage ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Kommentare</Typography>
            </Breadcrumbs>

            <Typography variant={"h3"} className="main-header gap-top">
                Neue Kommentare
            </Typography>
            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Kommentar</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Projekt</TableCell>
                            <TableCell>Veranstaltung</TableCell>
                            <TableCell style={{ width: "100px" }}>
                                Menu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {commentList.map(
                            (comment: IPortalCommentList, index: number) => {
                                return (
                                    <TableRow key={"user" + index}>
                                        <TableCell>
                                            {moment(
                                                comment.creationDate
                                            ).format(Format.dateTime)}
                                        </TableCell>
                                        <TableCell>{comment.content}</TableCell>
                                        <TableCell>
                                            {comment.nameOfPerson}
                                        </TableCell>
                                        <TableCell>
                                            <a
                                                href={
                                                    "mailto:" +
                                                    comment.emailOfPerson
                                                }
                                            >
                                                {comment.emailOfPerson}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                //@ts-ignore
                                                comment.project?.title
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                //@ts-ignore
                                                comment.event?.title
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                data-id={comment.id}
                                                onClick={actionMarkAsAswered}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <QuestionAnswerIcon />
                                            </IconButton>
                                            <IconButton
                                                data-id={comment.id}
                                                onClick={actionDeleteComment}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

export default CommentListing;
