import { createContext, useContext } from "react";

import { DocumentIndex } from "../../../types/IDocument";
import { TeamIndex } from "../editor/masks/logic/useGetRelationData";
import { CmsPageData } from "../types/CmsPageData";

interface CmsDataContextProps {
    pageData: CmsPageData;
    imagesIndex: DocumentIndex;
    uploadsIndex: DocumentIndex;
    teamIndex: TeamIndex;
}

export const CmsDataContext = createContext<CmsDataContextProps | null>(null);

/**
 * The context to pass Controls of Data grids
 */
export function useCmsDataContext(): CmsDataContextProps {
    const data = useContext<CmsDataContextProps | null>(CmsDataContext);

    if (data == null) {
        // should not happen
        throw new Error("Context must not be null!");
    }

    return data;
}
