import { Divider, IconButton, Menu, MenuList } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useRef, useState } from "react";

import { useMoveSection } from "../../logic/useMoveSection";
import { useCmsEditorContext } from "../../masks/CmsEditorContext";
import { useCmsContentContext } from "../content/CmsContentContext";

export function CmsLayoutControls() {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { setFormHasChangesAction, sectionIds, setSectionIds, deleteSectionAction } = useCmsEditorContext();

    const { sectionPosition, numOfSections } = useCmsContentContext();

    const { movePageSectionUp, movePageSectionDown } = useMoveSection({
        sectionIds,
        setSectionIds,
        setFormHasChangesAction,
    });

    return (
        <>
            <IconButton
                ref={ref as never}
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={ref.current}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuList>
                    {sectionPosition !== 0 && (
                        <MenuItem
                            onClick={() => {
                                movePageSectionUp(sectionPosition);
                            }}
                        >
                            Nach oben schieben
                        </MenuItem>
                    )}

                    {numOfSections != sectionPosition + 1 && (
                        <MenuItem
                            onClick={() => {
                                movePageSectionDown(sectionPosition);
                            }}
                        >
                            Nach unten schieben
                        </MenuItem>
                    )}
                    <Divider />
                    <MenuItem
                        onClick={() => {
                            deleteSectionAction(sectionPosition);
                        }}
                    >
                        Abschnitt löschen
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
}
