export enum CmsPageContentTypeEnum {
    HEADER_1 = "header_1",
    HEADER_2 = "header_2",
    HEADER_3 = "header_3",
    HEADER_4 = "header_4",
    HTML = "html",
    ATTACHMENT = "attachment",
    ATTACHMENT_LIST = "attachment_list",
    IMAGE = "image",
    CAROUSEL = "carousel",
    PERSON = "person",
    PERSON_LIST = "person_list",
    CARD = "card",
    FAQ = "faq",
    CALCULATOR = "calculator",
}
