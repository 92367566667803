import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useCallback } from "react";

import { CmsContentBlock, CmsContentBlockMapComments } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentMapCommentsProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockMapComments;
}

export function CmsContentMapComments({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentMapCommentsProps) {
    const { contentIndex, setContentIndex, setFormHasChangesAction } = useCmsEditorContext();

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlockMapComments,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    if (!editMode) {
        return (
            <Box onClick={startEditMode}>
                <Typography variant="body2">Feedback Karte</Typography>
                <Skeleton variant={"rect"} style={{ width: "500px", height: "150px" }} animation={false} />
            </Box>
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <Typography variant="body2">Feedback Karte</Typography>
            <Skeleton variant={"rect"} style={{ width: "500px", height: "150px" }} animation={false} />
        </CmsContentEditor>
    );
}
