/* eslint-disable no-console */
import "./ProjectData.css";

import { LinearProgress } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { PAGE_BREAK_MOBILE } from "../../../config/config";
import useWindowSize from "../../../customhooks/useWindowSize";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import { setProjectDataToStore, unsetProjectDataInStore } from "../../../store/project/ProjectAction";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import ICampaign from "../../../types/appointment/ICampaign";
import IMandant from "../../../types/IMandant";
import InitialProjectFullData from "../../../types/initialData/InitialProjectFullData";
import IProjectFull from "../../../types/IProjectFull";
import ITheme from "../../../types/ITheme";
import stringIsEmpty from "../../../utils/form/stringIsEmpty";
import DevLogger from "../../../utils/logger/DevLogger";
import setMandantThemeByServer from "../../../utils/setMandantThemeByServer";
import { loadActiveCampaignRequest } from "../../../xhr/CampaignRequests";
import { loadPublicProjectDataRequest } from "../../../xhr/ProjectRequests";
import { ActiveProjectContext } from "./ActiveProjectContext";
import ProjectDataContent from "./components/ProjectDataContent";
import getActiveProjectSection from "./helper/getActiveProjectSection";
import { ProjectTabEnum } from "./ProjectTabEnum";

interface IMapStateProps {
    mandantTheme: ITheme;
    storedProject?: any;
    actualUser?: any;
}

interface IMapDispatchProps {
    dispatchSetProject: (projectData: IProjectFull) => void;
    dispatchUnsetProject: () => void;
}

interface IOwnProps {
    mandant: IMandant;
}

interface IProjectDataProps extends IMapStateProps, IOwnProps, IMapDispatchProps {}

const ProjectData = (props: IProjectDataProps) => {
    const { id, tabKey }: { id: string; tabKey: string } = useParams();
    let { pageKey }: { pageKey: string } = useParams();

    const { dispatchSetProject, dispatchUnsetProject } = props;

    const [projectData, setProjectData] = React.useState<IProjectFull>({
        ...InitialProjectFullData,
    });
    const [campaignData, setCampaignData] = React.useState<ICampaign | null>(null);
    const { mandant, actualUser } = props;

    const [projectLoaded, setProjectLoaded] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<ProjectTabEnum>(ProjectTabEnum.START_TAB);
    const windowWidth = useWindowSize();

    const isMobileSize = windowWidth < PAGE_BREAK_MOBILE;

    const getTabByHash = (hash: string): ProjectTabEnum => {
        if (String(hash).trim() !== "") {
            return hash as ProjectTabEnum;
        } else {
            return ProjectTabEnum.START_TAB;
        }
    };

    const [isMobile, setIsMobile] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        // when changing the pages we scroll the page up
        window.scrollTo(0, 0);
    }, [mandant, pageKey]);

    React.useEffect(() => {
        if (windowWidth > 319) {
            let flagIsMobile = false;
            if (windowWidth < PAGE_BREAK_MOBILE) {
                flagIsMobile = true;
            }

            if (isMobile !== flagIsMobile) {
                // this was moved to line 62 to update the mandant when the user switches the projects.
                // setMandantThemeByServer(mandant, flagIsMobile);
                setIsMobile(flagIsMobile);
            }
            setMandantThemeByServer(mandant, flagIsMobile);
        }
    }, [windowWidth, mandant]);

    React.useEffect(() => {
        if (props?.storedProject?.id != 0 && props?.storedProject?.id != null) {
            setProjectData(props.storedProject);
            setProjectLoaded(true);

            loadActiveCampaignRequest(mandant.key, "" + props.storedProject.id)
                .then((campaignResult) => {
                    setCampaignData(campaignResult.data);
                    return Promise.resolve();
                })
                .catch((error) => {
                    DevLogger.logError("error", { error });
                });
        } else {
            if (!mandant.key) {
                return;
            }

            loadPublicProjectDataRequest(mandant.key, id)
                .then((result) => {
                    dispatchSetProject(result.data);

                    return Promise.resolve();
                })
                .catch(() => {
                    setProjectLoaded(true);
                });
        }
    }, [mandant.key, props.storedProject?.id]);

    React.useEffect(() => {
        if (projectData) {
            if (!stringIsEmpty(projectData.entryPage) && projectData.flagEnableProjectCms && !tabKey) {
                if (Object.values(ProjectTabEnum).includes(projectData.entryPage as ProjectTabEnum)) {
                    setActiveTab(tabKey ? getTabByHash(tabKey) : (projectData.entryPage as ProjectTabEnum));
                } else {
                    pageKey = pageKey ? pageKey : (projectData.entryPage as string);
                }
            } else {
                setActiveTab(getTabByHash(tabKey));
            }
        }
    }, [tabKey, projectData]);

    if (projectLoaded && !projectData?.id) {
        return (
            <div className="portal-section">
                <Helmet>
                    <title>{projectData.title}</title>
                </Helmet>
                <PortalHeader mandant={mandant} />
                <div className="App-main">
                    <LinearProgress color="secondary" />
                </div>

                <PortalFooter mandant={mandant} activeProjectKey={projectData?.urlKey} />
            </div>
        );
    }

    let contentTab;
    if (!activeTab && !pageKey) {
        if (!stringIsEmpty(projectData.entryPage) && projectData.flagEnableProjectCms) {
            if (!activeTab || activeTab === ProjectTabEnum.START_TAB) {
                if (
                    Object.values(ProjectTabEnum).includes(
                        projectData.entryPage as ProjectTabEnum // feels wrong
                    )
                ) {
                    contentTab = activeTab ? activeTab : (projectData.entryPage as ProjectTabEnum);
                } else {
                    pageKey = pageKey ? pageKey : (projectData.entryPage as string);
                }
            } else {
                contentTab = activeTab;
            }
        } else {
            contentTab = activeTab ? activeTab : ProjectTabEnum.START_TAB;
        }
    } else {
        contentTab = activeTab;
    }

    const activeContent =
        !pageKey && contentTab ? (
            getActiveProjectSection(mandant, projectData, actualUser as IUser, campaignData, contentTab)
        ) : (
            <></>
        );

    return (
        <div className="portal-section">
            <Helmet>
                <title>{projectData.title}</title>
            </Helmet>

            {projectLoaded || false ? (
                <>
                    <ActiveProjectContext.Provider
                        value={{
                            project: projectData,
                            actualUser: actualUser,
                            mandant,
                        }}
                    >
                        <PortalHeader mandant={mandant} activeTab={contentTab} pageKey={pageKey} />
                        <div className="portal-section-body-position">
                            <div className="App-main">
                                <ProjectDataContent
                                    mandant={mandant}
                                    isMobileSize={isMobileSize}
                                    projectData={projectData}
                                    pageKey={pageKey}
                                    activeContent={activeContent}
                                />
                            </div>
                        </div>
                    </ActiveProjectContext.Provider>
                </>
            ) : (
                <div className="App-main">
                    <LinearProgress color="secondary" />
                </div>
            )}

            <PortalFooter mandant={mandant} activeProjectKey={projectData?.urlKey} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandantTheme: state.mandant.mandantTheme,
        actualUser: state.user.userData,
        storedProject: state.activeProject?.projectData,
    };
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<IMapDispatchProps, IOwnProps> = (
    dispatch: ThunkDispatch<{}, {}, Action>
) => {
    return {
        dispatchSetProject: (projectData: IProjectFull) => {
            return dispatch(setProjectDataToStore(projectData));
        },
        dispatchUnsetProject: () => {
            return dispatch(unsetProjectDataInStore());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectData);
