import { FormControl, IconButton, InputLabel, Select, Toolbar } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SaveIcon from "@material-ui/icons/Save";
import React, { ChangeEvent, ReactNode } from "react";

import ISelectOption from "../../../../../xhr/interface/ISelectOption";
import { CmsContentBlock, cmsContentSelection } from "../../../types/CmsPageData";
import { CmsLayoutControls } from "../layouts/CmsLayoutControls";

export interface CmsContentEditorProps {
    children: ReactNode;
    contentNode: CmsContentBlock;
    showLayoutMenu: boolean;
    updateContentNode: (contentNode: CmsContentBlock) => void;
    stopEditMode: () => void;
    additionalButtons?: ReactNode;
}

export function CmsContentEditor({
    children,
    contentNode,
    updateContentNode,
    stopEditMode,
    showLayoutMenu,
    additionalButtons,
}: CmsContentEditorProps) {
    const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        //TODO add logic to check if the changes are compatible

        updateContentNode({
            ...contentNode,
            type: event.target.value,
        } as CmsContentBlock);
    };

    return (
        <>
            <Toolbar
                disableGutters={true}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                }}
            >
                {showLayoutMenu && <CmsLayoutControls />}

                <IconButton onClick={stopEditMode}>
                    <SaveIcon />
                </IconButton>

                {additionalButtons != undefined && additionalButtons}

                <FormControl variant="outlined" size={"small"} style={{ width: "200px" }}>
                    <InputLabel id="cms-content-select-label">Typ des Inhalts</InputLabel>
                    <Select
                        labelId="layout-select-label"
                        label="Typ des Inhalts"
                        value={contentNode.type}
                        onChange={onChange}
                        className={"noBottomMarg"}
                    >
                        <MenuItem key="empty" value="">
                            <em>Inhalt wählen</em>
                        </MenuItem>
                        {cmsContentSelection.map((layout: ISelectOption, index: number) => {
                            return (
                                <MenuItem key={"content-" + index} value={layout.id}>
                                    {layout.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Toolbar>
            {children}
        </>
    );
}
