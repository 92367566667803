import { Grid, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

type IConsultingTabProps = {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    projectData: IProjectFull;
    onChangeWysiwyg: (editorState: any, name: string) => void;
};

const ConsultingTab = (props: IConsultingTabProps) => {
    const { projectData, onChangeWysiwyg, onImageChange, onTextChange } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Beratung Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden,
                        werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="menuTabConsulting"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabConsulting}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TextField
                        name="titleTabConsulting"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabConsulting}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TextField
                        name="descTabConsulting"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabConsulting}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabConsulting}
                        idKey={"headerTabConsulting"}
                        name={"headerTabConsulting"}
                        label={"Header Bild"}
                        defaultFilename="Header.jpg"
                        minSourceImageWidth={2000}
                        minSourceImageHeight={1125}
                        imageExportHeightInPixels={1125}
                        imageExportWidthInPixels={2000}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Beratung Inhalt
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "consultingText");
                        }}
                        title="Text links"
                        defaultValue={
                            props.projectData.consultingText
                                ? projectData.consultingText
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "consultingText2");
                        }}
                        title="Text rechts"
                        defaultValue={
                            props.projectData.consultingText2
                                ? projectData.consultingText2
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.imageConsulting}
                        idKey={"imageConsulting"}
                        name={"imageConsulting"}
                        label={"Beratungs Bild *"}
                        defaultFilename="Consulting.jpg"
                        minSourceImageWidth={2000}
                        minSourceImageHeight={1125}
                        imageExportHeightInPixels={1125}
                        imageExportWidthInPixels={2000}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ConsultingTab;
