import React, { useCallback } from "react";

import IDocument, { DocumentIndex } from "../../../../../types/IDocument";
import ISnackbarState from "../../../../../types/ISnackbarState";
import { loadCmsPageRequest } from "../../../../../xhr/CmsPageRequests";
import {
    CmsContentBlock,
    CmsPageData,
    CmsSectionBlock,
    CmsSectionIndexBlock,
    CmsSyncPageData,
} from "../../../types/CmsPageData";
import { initialCmsPageData } from "../../data/initialCmsPageData";
import { CmsPageState } from "../CmsPageEditor";
import { handleCmsData } from "./handleCmsData";
import { useGetRelationData } from "./useGetRelationData";
import { useSaveToBackend } from "./useSaveToBackend";

export type CmsSectionMap = Record<string, CmsSectionIndexBlock>;
export type CmsSectionMapSetter = (newData: CmsSectionMap) => void;

export type CmsContentMap = Record<string, CmsContentBlock>;
export type CmsContentMapSetter = (newData: CmsContentMap) => void;

export function useBackend({
    mandantKey,
    pageId,
    closeChangesNotSavedWarning,
    setSnackBarState,
    setPageState,
}: {
    mandantKey: string;
    pageId: number;
    closeChangesNotSavedWarning: () => void;
    setSnackBarState: (state: ISnackbarState) => void;
    setPageState: (state: CmsPageState) => void;
}) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(pageId));

    /**
     * Images Index
     */
    const [imagesIndex, setImagesIndex] = React.useState<DocumentIndex>({});

    /**
     * Upload Index
     */
    const [uploadsIndex, setUploadsIndex] = React.useState<DocumentIndex>({});

    /**
     * An index for the sections
     */
    const [sectionIndex, setSectionIndex] = React.useState<CmsSectionMap>({});

    /**
     * Cms Page Content Index. Contains all Content nodes. Can access by ID
     */
    const [contentIndex, setContentIndex] = React.useState<CmsContentMap>({});

    /**
     * Cms Page Content Index. Contains all Content nodes. Can access by ID
     */
    const [sectionIds, setSectionIds] = React.useState<string[]>([]);

    /**
     * The form data
     */
    const [pageData, setPageData] = React.useState<CmsPageData>({
        ...initialCmsPageData,
    });

    const { projectList, teamIndex } = useGetRelationData({
        mandantKey,
        pageData,
    });

    const populateContentIndices = useCallback((sections: CmsSectionBlock[]) => {
        const newSectionList: string[] = [];
        const newSectionIndex: CmsSectionMap = {};
        const newContentIndex: CmsContentMap = {};

        sections.forEach((section) => {
            newSectionList.push(section.id);

            const nextSection: CmsSectionIndexBlock = {
                id: section.id,
                layout: section.layout,
                content: [],
            };

            section.content.forEach((content) => {
                nextSection.content.push(content.id);
                newContentIndex[content.id] = content;
            });

            newSectionIndex[section.id] = nextSection;
        });

        setSectionIndex(newSectionIndex);
        setContentIndex(newContentIndex);
        setSectionIds(newSectionList);
    }, []);

    const mapServerRequestToStates = useCallback((response: CmsSyncPageData) => {
        const { data, pageProperties, elements } = handleCmsData(response.data, response.elements);

        populateContentIndices(elements);

        setPageData({
            data,
            pageProperties,
            elements: [],
        });

        setPageState({
            isEditForm,
            pageTitle: response.data.title,
        });

        if (Array.isArray(response.images)) {
            const newImages: DocumentIndex = {};
            response.images.map((img: IDocument) => {
                newImages[img.key + ""] = img;
            });
            setImagesIndex(newImages);
        } else {
            setImagesIndex(response.images);
        }

        // why this check?!
        if (Array.isArray(response.uploads)) {
            const newUploads: DocumentIndex = {};
            response.uploads.map((upl: IDocument) => {
                newUploads[upl.key + ""] = upl;
            });
            setUploadsIndex(newUploads);
        } else {
            setUploadsIndex(response.uploads);
        }
    }, []);

    const loadDataFromServer = useCallback((loadPageId: number) => {
        setIsLoading(true);

        loadCmsPageRequest(mandantKey, loadPageId)
            .then((response) => {
                mapServerRequestToStates(response.data);
                setIsLoading(false);
                closeChangesNotSavedWarning();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Seite ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    }, []);

    const { handleSave } = useSaveToBackend({
        setIsLoading,
        isEditForm,
        mandantKey,
        pageData,
        sectionIds,
        sectionIndex,
        contentIndex,
        imagesIndex,
        uploadsIndex,
        setSnackBarState,
        closeChangesNotSavedWarning,
        setIsEditForm,
        mapServerRequestToStates,
    });

    React.useEffect(() => {
        if (isEditForm) {
            loadDataFromServer(pageId);
        } else {
            // if we create an event we need at least the project
            setPageData({ ...initialCmsPageData });
        }
    }, [pageId]);

    return {
        isLoading,
        pageData,
        setPageData,
        // content indices
        sectionIndex,
        setSectionIndex,
        contentIndex,
        setContentIndex,
        sectionIds,
        setSectionIds,
        // upload indices
        imagesIndex,
        setImagesIndex,
        uploadsIndex,
        setUploadsIndex,

        //
        projectList,
        teamIndex,
        //
        handleSave,
    };
}
