import uuid from "uuid-random";

import { CmsPageLayoutTypes, CmsSectionBlock } from "../../types/CmsPageData";

const l_1 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_1",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

const l_1_1 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_1_1",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

const l_1_1_1 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_1_1_1",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

const l_1_1_1_1 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_1_1_1_1",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

const l_1_2 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_1_2",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

const l_2_1 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_2_1",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

const l_1_2_1 = (): CmsSectionBlock => {
    return {
        id: uuid(),
        layout: "l_1_2_1",
        content: [
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
            {
                id: uuid(),
                type: "html",
                data: "",
            },
        ],
    };
};

export const listOfLayouts: Record<CmsPageLayoutTypes, () => CmsSectionBlock> = {
    l_1,
    l_1_1,
    l_1_1_1,
    l_1_1_1_1,
    l_1_2,
    l_2_1,
    l_1_2_1,
};
