import {
    Breadcrumbs,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import moment from "moment";
import React, { SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import { Format } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IFeedbackList from "../../../types/IFeedbackList";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    deleteFeedbackRequest,
    loadFeedbackList,
    updateFeedbackRequest,
} from "../../../xhr/FeedbackRequests";

interface IFeedbackListingParams {
    mandant: IMandant;
}

const FeedbackListing = (props: IFeedbackListingParams) => {
    const history = useHistory();
    const { mandant } = props;
    const [feedbackList, setFeedbackList] = React.useState<IFeedbackList[]>([]);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        loadFeedbackList(mandant.key)
            .then((result: any) => {
                setFeedbackList(result.data);
                return Promise.resolve();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    }, [mandant.key]);

    if (!mandant.id) {
        return <></>;
    }

    const actionMarkAsAswered = (event: SyntheticEvent<HTMLSpanElement>) => {
        const feedbackId: number = parseInt(
            "" + event.currentTarget.dataset.id
        );

        const index = feedbackList.findIndex((search) => {
            return feedbackId === search.id;
        });

        updateFeedbackRequest(
            {
                dateAnswered: {
                    date: moment(new Date()).format(Format.dateTimeEn),
                },
                flagAnswered: true,
            },
            feedbackId,
            mandant.key
        )
            .then(() => {
                feedbackList[index].answerDate = new Date().toString();
                feedbackList[index].flagAnswered = true;
                setFeedbackList([...feedbackList]);

                setSnackBarState({
                    isOpen: true,
                    message: "Die Nachricht wurde als beantwortet markiert",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Die Nachricht konnte nicht als beantwortet markiert werden",
                    type: "error",
                });
            });
    };

    const actionDeleteFeedback = (event: SyntheticEvent<HTMLSpanElement>) => {
        const feedbackId: number = parseInt(
            "" + event.currentTarget.dataset.id
        );

        deleteFeedbackRequest(feedbackId, mandant.key)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Anfrage wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadFeedbackList(mandant.key)
                    .then((result: any) => {
                        setFeedbackList(result.data);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Löschen der Anfrage ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Kontakt Anfragen</Typography>
            </Breadcrumbs>

            <Typography variant={"h3"} className="main-header gap-top">
                Offene Anfragen
            </Typography>
            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Projekt</TableCell>
                            <TableCell>Anfrage Text</TableCell>
                            <TableCell style={{ width: "100px" }}>
                                Menu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedbackList
                            .filter(
                                (feedback: IFeedbackList) =>
                                    feedback.flagAnswered === false
                            )
                            .map((feedback: IFeedbackList, index: number) => {
                                return (
                                    <TableRow key={"user" + index}>
                                        <TableCell>
                                            {moment(
                                                feedback.creationDate
                                            ).format(Format.dateTime)}
                                        </TableCell>
                                        <TableCell>
                                            {feedback.title}{" "}
                                            {feedback.nameOfPerson}
                                        </TableCell>
                                        <TableCell>
                                            <a
                                                href={
                                                    "mailto:" +
                                                    feedback.emailOfPerson
                                                }
                                            >
                                                {feedback.emailOfPerson}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            {feedback.project?.title
                                                ? feedback.project?.title
                                                : "Ohne Projekt"}
                                        </TableCell>
                                        <TableCell>
                                            {feedback.description}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                data-id={feedback.id}
                                                onClick={actionMarkAsAswered}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <QuestionAnswerIcon />
                                            </IconButton>
                                            <IconButton
                                                data-id={feedback.id}
                                                onClick={actionDeleteFeedback}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant={"h3"} className="main-header-with-gap">
                Bearbeitete Anfragen
            </Typography>
            <TableContainer>
                <Table size={"small"} >
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Datum Antwort</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Projekt</TableCell>
                            <TableCell>Anfrage Text</TableCell>
                            <TableCell style={{ width: "50px" }}>
                                Menu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedbackList
                            .filter(
                                (feedback: IFeedbackList) =>
                                    feedback.flagAnswered === true
                            )
                            .map((feedback: IFeedbackList, index: number) => {
                                return (
                                    <TableRow key={"user" + index}>
                                        <TableCell>
                                            {moment(
                                                feedback.creationDate
                                            ).format(Format.dateTime)}
                                        </TableCell>
                                        <TableCell>
                                            {moment(feedback.answerDate).format(
                                                Format.dateTime
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {feedback.title}{" "}
                                            {feedback.nameOfPerson}
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            <a
                                                href={
                                                    "mailto:" +
                                                    feedback.emailOfPerson
                                                }
                                            >
                                                {feedback.emailOfPerson}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            {feedback.project?.title
                                                ? feedback.project?.title
                                                : "Ohne Projekt"}
                                        </TableCell>
                                        <TableCell>
                                            {feedback.description}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                data-id={feedback.id}
                                                onClick={actionDeleteFeedback}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

export default FeedbackListing;
