import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import CheckIcon from "@material-ui/icons/Check";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React from "react";

import { CmsContentBlockFaq } from "../../../types/CmsPageData";
import HtmlContent from "../components/HtmlContent";

interface CmsRenderContentFaqProps {
    content: CmsContentBlockFaq;
    onClick?: () => void;
}

export const CmsRenderContentFaq = ({ content, onClick }: CmsRenderContentFaqProps) => {
    const [open, setOpen] = React.useState<string | number>(-1);

    const handleClick = (id: string) => {
        if (id === open) {
            setOpen(-1); // If it is already open, close it.
        } else {
            setOpen(id); // else open the clicked element
        }
    };

    return (
        <List
            component="nav"
            subheader={
                <ListSubheader component="h3" id={"nested-list-subheader-" + content.id} onClick={onClick}>
                    FAQ
                </ListSubheader>
            }
            className="faq-questions-list"
        >
            {content.data.map((questionRef) => {
                return (
                    <div key={questionRef.id} className="faq-questions-list__item">
                        <ListItem
                            button
                            onClick={() => handleClick(questionRef.id)}
                            className="faq-questions-list__item__question"
                        >
                            <ListItemText
                                className="faq-questions-list__item__question__text"
                                primary={questionRef.question}
                            />
                            {open === questionRef.id ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                            in={open === questionRef.id}
                            timeout="auto"
                            unmountOnExit
                            className="faq-questions-list__response-list"
                        >
                            <List component="ul" disablePadding>
                                {questionRef.answers.map((answer) => {
                                    return (
                                        <ListItem className="faq-questions-list__item__answer" key={answer.id}>
                                            {answer.content ? (
                                                <div className={"faq-questions-list__item__answer__content"}>
                                                    <ListItemIcon>
                                                        <CheckIcon />
                                                    </ListItemIcon>
                                                    <HtmlContent content={answer.content} />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </div>
                );
            })}
        </List>
    );
};
