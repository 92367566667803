import { Typography } from "@material-ui/core";
import React from "react";

import { CmsContentBlockText } from "../../../types/CmsPageData";

interface CmsRenderContentHeaderProps {
    content: CmsContentBlockText;
    variant: "h1" | "h2" | "h3" | "h4";
}

export const CmsRenderContentHeader = (props: CmsRenderContentHeaderProps) => {
    const { content, variant } = props;

    return <Typography variant={variant}>{content.data}</Typography>;
};
