import { useCallback } from "react";

export function useMoveSection({
    sectionIds,
    setSectionIds,
    setFormHasChangesAction,
}: {
    sectionIds: string[];
    setSectionIds: (newOrder: string[]) => void;
    setFormHasChangesAction: () => void;
}) {
    const movePageSectionUp = useCallback(
        (index: number) => {
            const newOrderedIds = [...sectionIds];

            const varReplace = newOrderedIds[index - 1];

            newOrderedIds[index - 1] = newOrderedIds[index];
            newOrderedIds[index] = varReplace;

            setSectionIds(newOrderedIds);
            setFormHasChangesAction();
        },
        [sectionIds, setSectionIds, setFormHasChangesAction]
    );

    const movePageSectionDown = useCallback(
        (index: number) => {
            const newOrderedIds = [...sectionIds];
            const varReplace = newOrderedIds[index + 1];

            newOrderedIds[index + 1] = newOrderedIds[index];
            newOrderedIds[index] = varReplace;

            setSectionIds(newOrderedIds);
            setFormHasChangesAction();
        },
        [sectionIds, setSectionIds, setFormHasChangesAction]
    );

    return {
        movePageSectionUp,
        movePageSectionDown,
    };
}
