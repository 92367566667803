import { Typography } from "@material-ui/core";
import React from "react";

import { API_URL_STATIC } from "../../../../../config/config";
import IDocument from "../../../../../types/IDocument";
import { isEmptyObject } from "../../../../../utils/check/isEmptyObject";
import DevLogger from "../../../../../utils/logger/DevLogger";

interface ICmsImageProps {
    image: IDocument;
    className?: string;
    onClick?: () => void;
}

const CmsImage = (props: ICmsImageProps) => {
    const { className, image, onClick } = props;

    if (isEmptyObject(image)) {
        DevLogger.logError("got an empty image", { props });
        return null;
    }

    const { url, description, copyright } = image;

    // when it's with copyright it becomes a div
    // need to be taken in consideration
    if (copyright != "" && copyright != null) {
        return (
            <div onClick={onClick}>
                <img
                    className={className}
                    src={image.data != null ? image.data : API_URL_STATIC + url}
                    alt={description}
                />
                {copyright != "" && copyright != null && (
                    <Typography
                        variant={"subtitle2"}
                        style={{
                            textAlign: "right",
                            color: "white",
                            textShadow: "2px 2px #000000",
                            marginTop: "-30px",
                            marginRight: "8px",
                        }}
                    >
                        Quelle: {copyright}
                    </Typography>
                )}
            </div>
        );
    } else {
        return (
            <img
                onClick={onClick}
                className={className}
                src={image.data != null ? image.data : API_URL_STATIC + url}
                alt={description}
            />
        );
    }
};

export default CmsImage;
