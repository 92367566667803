import React from "react";

import PersonTile from "../../../../personTile/PersonTile";
import { CmsContentBlockPerson } from "../../../types/CmsPageData";
import { useCmsDataContext } from "../../CmsDataContext";

interface CmsRenderContentPersonProps {
    content: CmsContentBlockPerson;
}

export const CmsRenderContentPerson = (props: CmsRenderContentPersonProps) => {
    const { content } = props;

    const { teamIndex } = useCmsDataContext();

    return (
        <PersonTile hasAvatar={teamIndex[content.id]?.image != undefined} minData={false} {...teamIndex[content.id]} />
    );
};
