import "./footerContact.css";

import { CircularProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";

import IApplicationRootState from "../../store/StoreTypes";
import IFeedback from "../../types/IFeedback";
import IMandant from "../../types/IMandant";
import InitialFeedbackData from "../../types/initialData/InitialFeedbackData";
import stringIsEmpty from "../../utils/form/stringIsEmpty";
import toInputString from "../../utils/form/toInputString";
import validateEmail from "../../utils/form/validateEmail";
import { createNewFeedback } from "../../xhr/FeedbackRequests";
import ISelectOption from "../../xhr/interface/ISelectOption";

interface IFormError {
    nameOfPerson?: string;
    emailOfPerson?: string;
    description?: string;
}

interface IMapStateProps {
    projectData: ISelectOption[];
    mandant: IMandant;
    footerFormTitle?: string;
}
interface IMapDispatchProps {}
interface IOwnProps {}

interface IFooterContactProps extends IMapStateProps, IMapDispatchProps, IOwnProps {}

interface IFooterContactState {
    contactData: IFeedback;
    isLoading: boolean;
    snackType: "success" | "error";
    snackText: string | null;
    errorState: IFormError;
}

class FooterContact extends React.Component<IFooterContactProps, IFooterContactState> {
    constructor(props: IFooterContactProps) {
        super(props);

        this.state = {
            contactData: { ...InitialFeedbackData },
            isLoading: false,
            snackType: "success",
            snackText: null,
            errorState: {},
        };
    }
    public render() {
        const formData = this.state.contactData;

        return (
            <div className="App-footer-contact">
                <Typography variant="h3" className="footer-title gap-bottom">
                    {this.props.footerFormTitle ? this.props.footerFormTitle : "Kontaktformular"}
                </Typography>
                <div className="footer-email-name-container">
                    <div>
                        <TextField
                            className="contact-form__inputs"
                            onChange={this.changeInput}
                            fullWidth={true}
                            error={!!this.state.errorState.nameOfPerson}
                            helperText={toInputString(this.state.errorState.nameOfPerson)}
                            value={toInputString(formData.nameOfPerson)}
                            name="nameOfPerson"
                            placeholder="Ihr Name"
                            variant="outlined"
                            size="small"
                        />
                    </div>

                    <div>
                        <TextField
                            className="contact-form__inputs"
                            fullWidth={true}
                            name="emailOfPerson"
                            onChange={this.changeInput}
                            error={!!this.state.errorState.emailOfPerson}
                            value={toInputString(formData.emailOfPerson)}
                            helperText={toInputString(this.state.errorState.emailOfPerson)}
                            placeholder="E-Mail"
                            variant="outlined"
                            size="small"
                        />
                    </div>
                </div>
                <div>
                    <TextField
                        fullWidth={true}
                        multiline
                        rows={5}
                        name="description"
                        onChange={this.changeInput}
                        error={!!this.state.errorState.description}
                        helperText={toInputString(this.state.errorState.description)}
                        value={toInputString(formData.description)}
                        placeholder="Was möchten Sie uns mitteilen?"
                        variant="outlined"
                        size="small"
                    />
                </div>

                <div>
                    {this.state.isLoading && <CircularProgress />}
                    {!this.state.isLoading && (
                        <Button
                            className="footer-send-button"
                            onClick={this.sendContactForm}
                            endIcon={<ArrowForwardIcon />}
                        >
                            Jetzt senden
                        </Button>
                    )}
                </div>

                <Snackbar open={!!this.state.snackText} autoHideDuration={6000} onClose={this.closeSnackbar}>
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackbar}
                        severity={this.state.snackType}
                    >
                        {this.state.snackText}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }

    private changeInput = (event: any) => {
        this.setState({
            contactData: {
                ...this.state.contactData,
                [event.target.name]: event.target.value,
            },
        });
    };

    private closeSnackbar = () => {
        this.setState({ snackText: null });
    };

    private sendContactForm = () => {
        const errorState: IFormError = {};

        let hasError = false;
        if (stringIsEmpty(this.state.contactData.nameOfPerson)) {
            hasError = true;
            errorState.nameOfPerson = "Der Name darf nicht leer sein.";
        }
        if (stringIsEmpty(this.state.contactData.emailOfPerson)) {
            hasError = true;
            errorState.emailOfPerson = "Bitte geben sie eine E-Mail an.";
        }
        if (!validateEmail(this.state.contactData.emailOfPerson)) {
            hasError = true;
            errorState.emailOfPerson = "Bitte geben sie eine korrekte E-Mail an.";
        }
        if (stringIsEmpty(this.state.contactData.description)) {
            hasError = true;
            errorState.description = "Bitte geben Sie einen Text ein.";
        }

        this.setState({
            ...this.state,
            errorState,
        });

        if (hasError) {
            return;
        }

        this.setState({
            ...this.state,
            isLoading: true,
        });

        createNewFeedback(this.props.mandant.key, this.state.contactData)
            .then(() => {
                this.setState({
                    snackText: "Vielen Dank Ihre Nachricht wurde verschickt.",
                    snackType: "success",
                    contactData: { ...InitialFeedbackData },
                    isLoading: false,
                });
            })
            .catch(() => {
                this.setState({
                    snackText: "Beim versenden der Nachricht ist ein Fehler aufgetreten.",
                    snackType: "error",
                    contactData: { ...InitialFeedbackData },
                    isLoading: false,
                });
            });
    };
}

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
        projectData: state.projectSelectbox.projectData,
    };
};

export default connect(mapStateToProps)(FooterContact);
