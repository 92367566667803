import React from "react";

const toLabel = (toProcess: string, fallback: any): any => {
    if (toProcess == null) {
        return fallback;
    }

    if (String(toProcess).trim() === "") {
        return fallback;
    }

    const parts = toProcess.split("/");

    if (parts.length === 1) {
        return parts[0];
    }

    return (
        <>
            {parts[0]}
            <br />
            {parts[1]}
        </>
    );
};

export default toLabel;
