import { FundingCalculationResult, FundingProgramm, KfwCalculationConfig } from "./FundingCalculatorForm.types";

export const InitialFundingData = {
    eligibleCosts: 0,
    fundingCalculatorType: undefined,
};

export const InitialCalculateKFWResult: FundingCalculationResult = {
    amountFunding: undefined,
    basicFundingCap: undefined,
    percentOfEligibleCosts: undefined,
    error: undefined,
};

export const fundingTypes: FundingProgramm[] = [
    { id: "kfw85", label: "KFW85" },
    { id: "kfw70", label: "KFW70" },
    { id: "kfw55", label: "KFW55" },
    { id: "kfw40", label: "KFW40" },
];

export const baseProgramm = "kfw85";

export const defaultCalculationParameters: KfwCalculationConfig = {
    kfw85: {
        fundingPercentage: 20,
        capFundingPercentage: 0.5,
        capTotal: 350000,
        isBase: true,
        // for the base
        capStatic: 150000,
        lowerGapLimit: 750000,
        upperGapLimit: 800000,
    },
    kfw70: {
        fundingPercentage: 25,
        capFundingPercentage: 5,
        capTotal: 400000,
        isBase: false,
    },
    kfw55: {
        fundingPercentage: 30,
        capFundingPercentage: 10,
        capTotal: 450000,
        isBase: false,
    },
    kfw40: {
        fundingPercentage: 35,
        capFundingPercentage: 15,
        capTotal: 500000,
        isBase: false,
    },
};
