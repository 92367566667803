import React from "react";

import { API_URL_STATIC } from "../../../../../config/config";
import DownloadCard from "../../../../downloadCard/DownloadCard";
import { CmsContentBlockAttachment } from "../../../types/CmsPageData";
import { useCmsDataContext } from "../../CmsDataContext";

interface CmsRenderContentAttachmentProps {
    content: CmsContentBlockAttachment;
}

export const CmsRenderContentAttachment = (props: CmsRenderContentAttachmentProps) => {
    const { content } = props;

    const { uploadsIndex } = useCmsDataContext();
    const attachment = uploadsIndex[content.id];

    return (
        <DownloadCard
            tooltip={attachment?.name}
            title={attachment?.title ? String(attachment.title) : String(attachment.name)}
            description={attachment?.description != null ? attachment.description : ""}
            actionButtonText="Herunterladen"
            actionButtonClassName="App-header-red-button"
            downloadUrl={API_URL_STATIC + attachment?.url}
        />
    );
};
