import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import { DocumentIndex } from "../../../../../../types/IDocument";
import IDocumentRef from "../../../../../../types/IDocumentRef";
import { SortableItemImage } from "./SortableItemImage";

export interface ISortableListDownloadProps {
    images: IDocumentRef[];
    imageIndex: DocumentIndex;
}

export const SortableListImage = SortableContainer<ISortableListDownloadProps>(
    ({ images, imageIndex }: ISortableListDownloadProps) => {
        return (
            <Grid container direction="column" spacing={2} style={{ margin: "8px" }}>
                {images ? (
                    images.map((image, index) => {
                        return <SortableItemImage key={index} index={index} img={imageIndex[image.key]} />;
                    })
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    }
);
