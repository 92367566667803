import { Feature, Map, Overlay, View } from "ol";
import { FullScreen, defaults as defaultControls } from "ol/control";
import Point from "ol/geom/Point";
import { defaults as defaultInteraction } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OverlayPositioning from "ol/OverlayPositioning";
import { transform, transformExtent } from "ol/proj";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Fill, Style } from "ol/style";
import Circle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import { useMemo } from "react";

/* put popup custom */
import IMapProposal from "../../../../types/IMapProposal";
import toInputString from "../../../../utils/form/toInputString";

/* clear popup */
export const clearPopup = (popType: string) => {
    const popupElement: any = document.getElementById(popType);
    popupElement.style.display = "none";
};

export const hex2rgb = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // return {r, g, b}
    return [r, g, b];
};

/* style function  */
const makeStyle = (color = [255, 0, 0]) => {
    return [
        new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({ color: "black" }),
                stroke: new Stroke({
                    color,
                    width: 4,
                }),
            }),
        }),
    ];
};

function transformTo(extent: any) {
    return transformExtent(extent, "EPSG:4326", "EPSG:3857");
}

export function useMapProposalMap() {
    /* the layer that contain the Marker */
    const markerLayer = useMemo(() => {
        return new VectorLayer({
            source: new VectorSource({ wrapX: false }),
        });
    }, []);

    const myInteration = useMemo(() => {
        return defaultInteraction({
            doubleClickZoom: false,
            //dragPan: false,
            //mouseWheelZoom: false,
            shiftDragZoom: false,
            altShiftDragRotate: false,
        });
    }, []);

    const putAllProposal = (Proposals: IMapProposal[]) => {
        const source = markerLayer.getSource();
        source.clear();
        Proposals.forEach((Proposal: IMapProposal) => {
            const transformed = transform([Proposal.longitude, Proposal.latitude], "EPSG:4326", "EPSG:3857");
            const myFeature = new Feature({
                geometry: new Point(transformed),
                value: Proposal,
            }) as Feature<any>;
            myFeature.setStyle(makeStyle(hex2rgb(Proposal.type ? toInputString(Proposal.type?.color) : "#ff000")));
            source.addFeature(myFeature);
        });
    };

    const olMap = useMemo(() => {
        return new Map({
            target: undefined,
            interactions: myInteration,
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                        maxZoom: 18,
                    }),
                }),
                markerLayer,
            ],
            controls: defaultControls().extend([new FullScreen()]),

            view: new View({
                center: transform([11.576124, 48.137154], "EPSG:4326", "EPSG:3857"),
                zoom: 1,
                extent: transformTo([3, 36, 17, 56]),
            }),
        });
    }, []);

    const displayHoverPopup = (proposal: IMapProposal) => {
        clearPopup("info-popup");
        clearPopup("popup");
        const convertedPosition = transform([proposal.longitude, proposal.latitude], "EPSG:4326", "EPSG:3857");
        const hoverPopup: any = document.getElementById("hover-popup");
        hoverPopup.style.display = "block";
        hoverPopup.innerHTML = proposal.comment;

        const popup = new Overlay({
            id: "hover-popup",
            element: hoverPopup,
            position: convertedPosition,
            positioning: OverlayPositioning.BOTTOM_CENTER,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });

        olMap.addOverlay(popup);
        olMap.getView().animate(
            { zoom: 16 },
            {
                center: convertedPosition,
            }
        );
    };

    return {
        olMap,
        putAllProposal,
        displayHoverPopup,
    };
}
