import "./PersonTile.css";

import { Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

import AvatarImg from "../../assets/images/user.svg";
import { API_URL_STATIC } from "../../config/config";
import ITeam from "../../types/ITeam";

interface IPersonTileParams extends ITeam {
    editLabel?: string;
    minData: boolean;
    hasAvatar: boolean;
}

const PersonTile = (props: IPersonTileParams) => {
    const { hasAvatar } = props;

    const renderContent = () => {
        return (
            <>
                <Typography gutterBottom variant="h3" className="text-color gap-bottom-none">
                    {props.salutation} {props.name}
                </Typography>
                <Typography gutterBottom variant="subtitle1" component="p">
                    {props.displayTitle} {props.company}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary" component="p" align="left" className="gap-top">
                    {!props.minData && (
                        <>
                            {props.street}
                            {!!props.street && (!!props.zip || !!props.city) && <br />}
                            {props.zip} {props.city}
                        </>
                    )}
                </Typography>
            </>
        );
    };

    const renderContactContent = () => {
        return (
            <>
                {props.phone && (
                    <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs={2} style={{ minWidth: "45px" }}>
                            <Typography variant="subtitle1" color="textPrimary" component="p" align="left">
                                Tel
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="p" align="left">
                                <a href={"tel:" + props.email?.trim()}>{props.phone?.trim()}</a>
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                {props.email && (
                    <Grid container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={2} style={{ minWidth: "45px" }}>
                            <Typography variant="subtitle1" color="textPrimary" component="p" align="left">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="p" align="left">
                                <a href={"mailto:" + props.email?.trim()}>{props.email?.trim()}</a>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    };

    return (
        <Card className="project-team-tab__team-member-tile" variant="outlined">
            <CardActionArea disableRipple={true}>
                <CardContent>
                    {hasAvatar && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Avatar
                                    alt={props?.name ? props.name : "Avatar"}
                                    style={{
                                        width: "120px",
                                        height: "120px",
                                    }}
                                    src={props?.image?.url ? API_URL_STATIC + props?.image?.url : AvatarImg}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={9}>
                                {renderContent()}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                {renderContactContent()}
                            </Grid>
                        </Grid>
                    )}
                    {!hasAvatar && renderContent()}
                    {!hasAvatar && renderContactContent()}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default PersonTile;
