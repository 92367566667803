import React from "react";

import { CmsContentBlockText } from "../../../types/CmsPageData";
import HtmlContent from "../components/HtmlContent";

interface CmsRenderContentHeaderProps {
    content: CmsContentBlockText;
}

export const CmsRenderContentHtml = (props: CmsRenderContentHeaderProps) => {
    const { content } = props;

    return <HtmlContent content={content.data != null ? (content.data as string) : ""} />;
};
