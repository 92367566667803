import React from "react";

import { useActiveProjectContext } from "../../../../../masks/public/projectData/ActiveProjectContext";
import IUser from "../../../../../store/userStore/interfaces/IUser";
import IProjectFull from "../../../../../types/IProjectFull";
import { ProjectComments } from "../../../../comment/ProjectComments";

export const CmsRenderContentComments = () => {
    const { project, mandant, actualUser } = useActiveProjectContext();

    return <ProjectComments project={project as IProjectFull} mandant={mandant} user={actualUser as IUser} />;
};
