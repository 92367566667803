import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { SortableElement } from "react-sortable-hoc";

import IDocument from "../../../../../../types/IDocument";
import CmsImage from "../../../../renderer/content/components/CmsImage";

export interface ISortableItemImageProps {
    img: IDocument;
    key: number;
}

export const SortableItemImage = SortableElement<ISortableItemImageProps>(({ img }: ISortableItemImageProps) => {
    return (
        <Grid item style={{ margin: "8px 0" }} className="sorting-item">
            <CmsImage image={img} className="portal-cms-image" />
        </Grid>
    );
});
