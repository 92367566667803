import { Alert } from "@material-ui/lab";
import React from "react";

import { CmsRenderLayoutOne } from "./CmsRenderLayoutOne";
import { CmsRenderLayoutOneOne } from "./CmsRenderLayoutOneOne";
import { CmsRenderLayoutOneOneOne } from "./CmsRenderLayoutOneOneOne";
import { CmsRenderLayoutOneOneOneOne } from "./CmsRenderLayoutOneOneOneOne";
import { CmsRenderLayoutOneTwo } from "./CmsRenderLayoutOneTwo";
import { CmsRenderLayoutOneTwoOne } from "./CmsRenderLayoutOneTwoOne";
import { CmsRenderLayoutProps } from "./CmsRenderLayoutProps";
import { CmsRenderLayoutTwoOne } from "./CmsRenderLayoutTwoOne";

export function CmsRenderLayoutMapper({ section }: CmsRenderLayoutProps) {
    switch (section.layout) {
        case "l_1": {
            return <CmsRenderLayoutOne section={section} />;
        }
        case "l_1_1": {
            return <CmsRenderLayoutOneOne section={section} />;
        }
        case "l_1_1_1": {
            return <CmsRenderLayoutOneOneOne section={section} />;
        }
        case "l_1_1_1_1": {
            return <CmsRenderLayoutOneOneOneOne section={section} />;
        }
        case "l_1_2": {
            return <CmsRenderLayoutOneTwo section={section} />;
        }
        case "l_2_1": {
            return <CmsRenderLayoutTwoOne section={section} />;
        }
        case "l_1_2_1": {
            return <CmsRenderLayoutOneTwoOne section={section} />;
        }

        default: {
            console.error("missing layout type: ", { section });
            return <Alert severity={"error"}>Missing Layout Type: {section.layout}</Alert>;
        }
    }
}
