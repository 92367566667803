import { Breadcrumbs, Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IOverview from "../../../types/IOverview";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { loadOverviewRequest, saveOverviewRequest } from "../../../xhr/OverviewRequests";
import { Container } from "./ProjectContainer";

interface IMapStateProps {
    mandant: IMandant;
}

interface IOwnProps {}

interface IChangePasswordParams extends IMapStateProps, IOwnProps {}

const AdminProjectOrderList = (props: IChangePasswordParams) => {
    const history = useHistory();
    const { mandant } = props;

    const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);
    const [errorResponse, setErrorResponse] = React.useState<string>("");
    const [overViewData, setOverViewData] = React.useState<IOverview>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarOpen(false);
    };

    React.useEffect(() => {
        setLoading(true);
        loadOverviewRequest()
            .then((result: any) => {
                setOverViewData(result.data);
                setLoading(false);
                return Promise.resolve();
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    if (!mandant.id) {
        return <></>;
    }
    const handleBread = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    };

    const handleChange = (event: any) => {
        const newData = {
            ...overViewData,
            [event.target.name]: event.target.value,
        };
        setOverViewData(newData);
    };

    const handleOverviewSave = () => {
        saveOverviewRequest(mandant.key, overViewData)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Der Benutzer wurde erfolgreich gespeichert",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim speichern der Benutzerdaten ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Startseite verwalten</Typography>
            </Breadcrumbs>

            <div className={"gap-top gap-bottom"}>
                <Typography variant="subtitle1">
                    Hier können Sie die Liste der Projekte per Drag and Drop anordnen.
                </Typography>
            </div>

            <Grid container alignItems="flex-start">
                {snackBarOpen && (
                    <Grid xs={12} md={12} lg={12}>
                        {errorResponse.length && (
                            <MuiAlert elevation={6} variant="filled" onClose={closeSnackbar} severity="error">
                                {errorResponse}
                            </MuiAlert>
                        )}

                        {!errorResponse.length && (
                            <MuiAlert elevation={6} variant="filled" onClose={closeSnackbar} severity="success">
                                Ihr Passwort wurde erfolgreich geändert.
                            </MuiAlert>
                        )}
                    </Grid>
                )}

                <Grid item xs={12} md={12} lg={8}>
                    <DndProvider backend={HTML5Backend}>
                        <Container mandant={mandant} />
                    </DndProvider>
                </Grid>
            </Grid>

            <div className={"gap-top gap-bottom"}>
                <Typography variant="subtitle1">Hier können Sie die Übersichtsinformationen hinzufügen</Typography>
            </div>
            {!loading && (
                <Grid container>
                    <Grid item xs={12} md={12} lg={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    name="firstTitle"
                                    label="Titel"
                                    fullWidth
                                    value={overViewData?.firstTitle}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    name="secondTitle"
                                    label="Überschrift"
                                    fullWidth
                                    value={overViewData?.secondTitle}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    name="firstDescription"
                                    label="Text"
                                    fullWidth
                                    multiline
                                    value={overViewData?.firstDescription}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="secondDescriptionLeft"
                                    label="Text Links"
                                    fullWidth
                                    multiline
                                    value={overViewData?.secondDescriptionLeft}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="secondDescriptionRight"
                                    label="Text Rechts"
                                    fullWidth
                                    multiline
                                    value={overViewData?.secondDescriptionRight}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Button onClick={handleOverviewSave} color="primary" startIcon={<SaveIcon />}>
                        Meine Änderungen speichern
                    </Button>
                </Grid>
            </Grid>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(AdminProjectOrderList);
