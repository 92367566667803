import {
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useConfirm } from "material-ui-confirm";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import RoutesEnum from "../../../routes/RoutesEnum";
import { ICmsPageData } from "../../../types/ICmsPage";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { deleteCmsPageRequest, loadCmsPageListRequest } from "../../../xhr/CmsPageRequests";

interface ICmsPageListingParams {
    mandant: IMandant;
}

const CmsPageListing = (props: ICmsPageListingParams) => {
    const history = useHistory();
    const confirm = useConfirm();

    const { mandant } = props;
    const [pageList, setPageList] = React.useState<ICmsPageData[]>([]);

    const sortedPageList = useMemo(() => {
        return pageList.sort((left, right) => {
            if (left.urlKey == right.urlKey) {
                return 0;
            }

            return left.urlKey > right.urlKey ? 1 : -1;
        });
    }, [pageList]);

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const loadPageList = () => {
        loadCmsPageListRequest(mandant.key)
            .then((result: any) => {
                setPageList(result.data);
                return Promise.resolve();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Seitendaten ist ein Fehler aufgetreten",
                    type: "error",
                });

                // Fehler beim Laden der Seiten
            });
    };

    React.useEffect(() => {
        loadPageList();
    }, [mandant.key]);

    if (!mandant.id) {
        return <></>;
    }

    const actionCreateNewPage = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_CMS_PAGE + "create"));
    };

    const actionEditPage = (event: any) => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_CMS_PAGE + event.currentTarget.dataset.id));
    };

    const actionDeletePage = (event: React.MouseEvent<HTMLButtonElement>) => {
        const deleteId = "" + event.currentTarget.dataset.id;

        confirm({
            description: "Möchten sie diese Seite wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                deleteCmsPageRequest(mandant.key, deleteId)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Die Seite wurde gelöscht.",
                            type: "success",
                        });
                        loadPageList();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Löschen der Seite ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                return;
            });
    };

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Liste der CMS Seiten</Typography>
            </Breadcrumbs>

            <Grid container alignItems="flex-start">
                <Grid item xs={12} md={12} lg={12}>
                    <Button color="primary" onClick={actionCreateNewPage} startIcon={<IconAdd />}>
                        Einen neuen CMS Seite anlegen
                    </Button>
                </Grid>
            </Grid>

            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Seiten Titel</TableCell>
                            <TableCell title="Über diese Bezeichnung kann die Seite im Browser aufgerufen werden.">
                                URL Schlüssel
                            </TableCell>
                            <TableCell title="Das Projekt dem die Seite angehängt ist">Projekt</TableCell>
                            <TableCell style={{ width: "100px" }}>Menu</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPageList.map((page: ICmsPageData, index: number) => {
                            return (
                                <TableRow key={"user" + index}>
                                    <TableCell>{page.title}</TableCell>
                                    <TableCell>{page.urlKey}</TableCell>
                                    <TableCell>{page.project?.title}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            component={"span"}
                                            data-id={page.id}
                                            onClick={actionEditPage}
                                            size={"small"}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            component={"span"}
                                            data-id={page.id}
                                            onClick={actionDeletePage}
                                            size={"small"}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

export default CmsPageListing;
