import "./imageUpload.css";

import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Fab from "@material-ui/core/Fab";
import { AddPhotoAlternateOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useState } from "react";
import uuid from "uuid-random";

import { API_URL_STATIC } from "../../config/config";
import DocumentTypeEnum from "../../enums/DocumentTypeEnum";
import IDocument from "../../types/IDocument";
import InitialDocumentData from "../../types/initialData/InitialDocumentData";
import { formatBytes, getImageSizeInBytes } from "../../utils/bytesConverter";
import ImageCropper, { ImageSizeError } from "../imageCropper/ImageCropper";

interface ImageUploadProps {
    name: string;
    idKey: string;
    label: string;
    docData?: IDocument | undefined;
    defaultFilename: string;
    onChange: (image: IDocument, key: string) => void;
    errorState?: boolean;
    hasFreeScale?: boolean;
    minSourceImageHeight?: number;
    minSourceImageWidth?: number;
    imageExportHeightInPixels?: number;
    imageExportWidthInPixels?: number;
    withSizeCheck?: boolean;
}

const ImageUpload = (props: ImageUploadProps) => {
    const {
        name,
        idKey,
        docData,
        onChange,
        errorState,
        hasFreeScale,
        minSourceImageHeight,
        minSourceImageWidth,
        imageExportHeightInPixels,
        imageExportWidthInPixels,
        withSizeCheck,
    } = props;
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [imageToCrop, setImageToCrop] = useState<any>(null);
    const [imageErrors, setImageErrors] = useState<ImageSizeError>();
    const [imageName, setImageName] = useState<string>("");

    const theData =
        docData != undefined
            ? (docData as never as IDocument)
            : { ...InitialDocumentData, key: uuid(), type: DocumentTypeEnum.IMAGE };

    const croppedImageSizeInBytes = croppedImage ? getImageSizeInBytes(croppedImage.url) : null;
    const onUploadFile = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                const image = reader.result;
                setImageToCrop(image);
            });
            reader.readAsDataURL(event.target.files[0]);
            setImageName(event.target.files[0].name);
            event.currentTarget.value = "";
        }
    };

    const handleResetClick = () => {
        onChange(
            {
                ...InitialDocumentData,
                id: theData?.id,
                type: theData?.type,
                key: theData?.key,
            },
            props.name
        );
        setImageToCrop(null);
        setCroppedImage(null);
        setImageErrors({ isHighEnough: true, isWideEnough: true });
    };

    const getImageSrc = (image: IDocument) => {
        if (image?.data) {
            return image.data;
        }
        if (image?.url) {
            return API_URL_STATIC + image.url;
        }

        return null;
    };

    const handleChange = (event: any) => {
        const newData = {
            ...theData,
            [event.target.name]: event.target.value,
        } as IDocument;
        onChange(newData, props.name);
    };

    const sizeIsNotNull = (size?: number | null) => {
        if (size == null) {
            return false;
        }

        return size > 0;
    };

    const imageSource = getImageSrc(theData);

    const uploadId = `contained-button-file-${idKey}`;

    return (
        <Card variant="outlined" className="upload-image-card">
            <CardActionArea disableRipple={true}>
                <CardMedia title={props.label}>
                    <></>
                </CardMedia>
                <CardContent>
                    {errorState ? (
                        <Typography variant="h3" style={{ color: "red" }}>
                            {props.label}
                        </Typography>
                    ) : (
                        <Typography variant="h3">{props.label}</Typography>
                    )}
                    <input
                        accept="image/*"
                        id={uploadId}
                        type="file"
                        style={{ display: "none" }}
                        onChange={onUploadFile}
                    />
                    <label htmlFor={uploadId}>
                        {imageSource && !imageToCrop && <img src={imageSource} alt={"Uploaded data"} />}
                        {imageToCrop && (
                            <>
                                <div>
                                    <ImageCropper
                                        hasFreeScale={hasFreeScale}
                                        imageToCrop={imageToCrop}
                                        imageName={imageName}
                                        minSourceImageHeight={minSourceImageHeight}
                                        minSourceImageWidth={minSourceImageWidth}
                                        imageExportHeightInPixels={imageExportHeightInPixels}
                                        imageExportWidthInPixels={imageExportWidthInPixels}
                                        onImageCropped={(cropImage: any) => {
                                            onChange(
                                                {
                                                    ...theData,
                                                    data: cropImage.url,
                                                    name: cropImage.name,
                                                    size: cropImage.size,
                                                },
                                                props.name
                                            );
                                            setCroppedImage(cropImage);
                                        }}
                                        imageSizeErrorhandler={(imageSizeErrors: ImageSizeError) => {
                                            setImageErrors(imageSizeErrors);
                                        }}
                                    />
                                </div>
                                {croppedImage && (
                                    <div className="coropped-image-container">
                                        {!withSizeCheck &&
                                            imageErrors &&
                                            (!imageErrors?.isHighEnough || !imageErrors?.isWideEnough) && (
                                                <Typography className="image-upload__error-message" variant="body1">
                                                    Das von Ihnen bereitgestellte Bild hat eine zu geringe Auflösung.
                                                    Die Mindestbreite sind:
                                                    {" " + minSourceImageWidth}
                                                    px und die Mindesthöhe sind
                                                    {" " + minSourceImageHeight}
                                                    px;
                                                </Typography>
                                            )}
                                        <Typography variant="h3">Zugeschnittenes Bild</Typography>
                                        <img alt="Cropped Img" src={croppedImage.url} />
                                        {!withSizeCheck && sizeIsNotNull(croppedImageSizeInBytes) && (
                                            <Typography className="image-upload__error-message" variant="body1">
                                                {`Die Größe des zugeschnittenen Bildes beträgt ${formatBytes(
                                                    croppedImageSizeInBytes,
                                                    1
                                                )}`}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        {!imageToCrop && !imageSource && (
                            <Fab component="span">
                                <AddPhotoAlternateOutlined />
                            </Fab>
                        )}
                    </label>
                    {(imageToCrop || imageSource) && (
                        <>
                            <TextField
                                name="name"
                                label="Bildname"
                                fullWidth
                                value={docData?.name ? docData?.name : props.defaultFilename}
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="title"
                                label="Titel"
                                fullWidth
                                value={docData?.title ? docData?.title : ""}
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="description"
                                label="Beschreibung"
                                className={"description-input"}
                                fullWidth
                                value={docData?.description ? docData?.description : ""}
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="copyright"
                                label="Copyright *"
                                fullWidth
                                value={docData?.copyright ? docData?.copyright : ""}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </>
                    )}
                </CardContent>
            </CardActionArea>
            {(imageToCrop || imageSource) && (
                <CardActions>
                    <Button onClick={handleResetClick} color="secondary" startIcon={<CancelIcon />}>
                        Bild entfernen
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default ImageUpload;
