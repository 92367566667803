import { CircularProgress, FormControl, Grid, InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";

import IApplicationRootState from "../../store/StoreTypes";
import IFeedback from "../../types/IFeedback";
import IMandant from "../../types/IMandant";
import InitialFeedbackData from "../../types/initialData/InitialFeedbackData";
import stringIsEmpty from "../../utils/form/stringIsEmpty";
import toInputString from "../../utils/form/toInputString";
import validateEmail from "../../utils/form/validateEmail";
import { createNewFeedback } from "../../xhr/FeedbackRequests";
import ISelectOption from "../../xhr/interface/ISelectOption";

interface IMapStateProps {
    projectData: ISelectOption[];
    mandant: IMandant;
}
interface IOwnProps {}

interface IContactFormProps extends IMapStateProps, IOwnProps {}

interface IContactFormState {
    contactData: IFeedback;
    snackType: "success" | "error";
    snackText: string | null;
    errorState: IFormError;
    loading: boolean;
}

interface IFormError {
    nameOfPerson?: string;
    emailOfPerson?: string;
    description?: string;
}

class ContactForm extends React.Component<IContactFormProps, IContactFormState> {
    constructor(props: IContactFormProps) {
        super(props);

        this.state = {
            contactData: { ...InitialFeedbackData },
            snackType: "success",
            snackText: null,
            errorState: {},
            loading: false,
        };
    }

    private changeInput = (event: any) => {
        this.setState({
            contactData: {
                ...this.state.contactData,
                [event.target.name]: event.target.value,
            },
        });
    };

    private changeProject = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            contactData: {
                ...this.state.contactData,
                project: { id: event.target.value as number },
            },
        });
    };

    private closeSnackbar = () => {
        this.setState({ snackText: null });
    };

    private sendContactForm = () => {
        const errorState: IFormError = {};

        let hasError = false;
        if (stringIsEmpty(this.state.contactData.nameOfPerson)) {
            hasError = true;
            errorState.nameOfPerson = "Der Name darf nicht leer sein.";
        }
        if (stringIsEmpty(this.state.contactData.emailOfPerson)) {
            hasError = true;
            errorState.emailOfPerson = "Bitte geben sie eine E-Mail an.";
        }
        if (!validateEmail(this.state.contactData.emailOfPerson)) {
            hasError = true;
            errorState.emailOfPerson = "Bitte geben sie eine korrekte E-Mail an.";
        }
        if (stringIsEmpty(this.state.contactData.description)) {
            hasError = true;
            errorState.description = "Bitte geben Sie einen Text ein.";
        }

        this.setState({
            ...this.state,
            errorState,
        });

        if (hasError) {
            return;
        }

        const contactData = this.state.contactData;

        if (this.props.projectData.length === 1) {
            contactData.project = this.props.projectData[0];
            this.setState({
                ...this.state,
                loading: true,
                contactData,
            });
        } else {
            this.setState({
                ...this.state,
                loading: true,
            });
        }

        createNewFeedback(this.props.mandant.key, contactData)
            .then(() => {
                this.setState({
                    snackText: "Vielen Dank Ihre Nachricht wurde verschickt.",
                    snackType: "success",
                    contactData: { ...InitialFeedbackData },
                    errorState: {},
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    snackText: "Beim versenden der Nachricht ist ein Fehler aufgetreten.",
                    snackType: "error",
                    contactData: { ...InitialFeedbackData },
                    errorState: {},
                    loading: false,
                });
            });
    };

    public render() {
        const formData = this.state.contactData;

        return (
            <Grid container>
                <Grid item sm={12} md={8} lg={6}>
                    {this.props.projectData.length > 1 && (
                        <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel id={"input-projekt-label"}>Projekt</InputLabel>
                            <Select
                                id="input-projekt"
                                value={toInputString(formData.project.id)}
                                fullWidth={true}
                                onChange={this.changeProject}
                                variant="outlined"
                            >
                                <MenuItem key="empty" value="">
                                    <em>Bitte Projekt wählen</em>
                                </MenuItem>
                                {this.props.projectData.map((project: ISelectOption) => {
                                    return (
                                        <MenuItem key={project.id} value={project.id}>
                                            {project.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        onChange={this.changeInput}
                        fullWidth={true}
                        error={!!this.state.errorState.nameOfPerson}
                        helperText={toInputString(this.state.errorState.nameOfPerson)}
                        value={toInputString(formData.nameOfPerson)}
                        name="nameOfPerson"
                        label="Ihr Name"
                        placeholder="Ihr Name"
                        variant="outlined"
                    />

                    <TextField
                        fullWidth={true}
                        name="emailOfPerson"
                        onChange={this.changeInput}
                        error={!!this.state.errorState.emailOfPerson}
                        helperText={toInputString(this.state.errorState.emailOfPerson)}
                        value={toInputString(formData.emailOfPerson)}
                        placeholder="E-Mail"
                        label="E-Mail"
                        variant="outlined"
                    />

                    <TextField
                        fullWidth={true}
                        multiline
                        rows={5}
                        label="Inhalt"
                        name="description"
                        onChange={this.changeInput}
                        error={!!this.state.errorState.description}
                        helperText={toInputString(this.state.errorState.description)}
                        value={toInputString(formData.description)}
                        placeholder="Was möchten Sie uns mitteilen?"
                        variant="outlined"
                    />

                    <div>
                        {this.state.loading && <CircularProgress />}
                        {!this.state.loading && (
                            <Button color="primary" onClick={this.sendContactForm}>
                                Jetzt senden
                            </Button>
                        )}
                    </div>

                    <Snackbar open={!!this.state.snackText} autoHideDuration={6000} onClose={this.closeSnackbar}>
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.closeSnackbar}
                            severity={this.state.snackType}
                        >
                            {this.state.snackText}
                        </MuiAlert>
                    </Snackbar>
                </Grid>
            </Grid>
        );
    }
}

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
        projectData: state.projectSelectbox.projectData,
    };
};

export default connect(mapStateToProps)(ContactForm);
