import { Grid, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import InitialDocumentData from "../../../../types/initialData/InitialDocumentData";
import IProjectFull from "../../../../types/IProjectFull";
import IProjectGalleryImage from "../../../../types/IProjectGalleryImage";
import { IProjectValidationState } from "../projectDataValidation";
import ProjectInfoDownloadsSection from "./ProjectInfoDownloadsSection";

type IProjectTextTabProps = {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    onCheckboxChange: (event: any) => void;
    projectData: IProjectFull;
    errorState: IProjectValidationState;
    onChangeWysiwyg: (editorState: any, name: string) => void;
    downloads: {
        images?: IProjectGalleryImage[];
        onUpload: (
            type: string,
            image: IProjectGalleryImage,
            index: number
        ) => void;
        onDelete: (type: string, index: number) => void;
        onResetImage: (type: string, index: number, imageId: number) => void;
        onSave: (type: string) => void;
        onImageCreate: (type: string) => void;
        loadingGallery: boolean;
        onSort: (oldIndex: number, newIndex: number, type: string) => void;
        handleImagesSortSave: (type: string) => void;
    };
};

const ProjectInfoTab = (props: IProjectTextTabProps) => {
    const {
        projectData,
        onChangeWysiwyg,
        onTextChange,
        onImageChange,
        downloads,
    } = props;

    const { images } = downloads;

    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Informationen Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden,
                        werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="menuTabInfo"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabInfo}
                        onChange={onTextChange}
                        variant="outlined"
                    />

                    <TextField
                        name="titleTabInfo"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabInfo}
                        onChange={onTextChange}
                        variant="outlined"
                    />

                    <TextField
                        name="descTabInfo"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabInfo}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabInfo}
                        idKey={"headerTabInfo"}
                        name={"headerTabInfo"}
                        label={"Info Header"}
                        defaultFilename="Header.jpg"
                    />
                    <Alert color="info">
                        Wenn hier ein Header gesetzt wird, ersetzt er die
                        Timeline. Wenn das Bild gelöscht wird erscheint die
                        Timeline wieder.
                    </Alert>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Informationen Inhalt
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "description");
                        }}
                        title="Text links"
                        defaultValue={
                            projectData.description
                                ? projectData.description
                                : ""
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "description2");
                        }}
                        title="Text Rechts"
                        defaultValue={
                            projectData.description2
                                ? projectData.description2
                                : ""
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={
                            projectData.imageMap
                                ? projectData.imageMap
                                : { ...InitialDocumentData }
                        }
                        name={"imageMap"}
                        idKey={"imageMap"}
                        label={"Projektkarte als Bild"}
                        defaultFilename="Map.jpg"
                    />
                </Grid>
            </Grid>

            {images && <ProjectInfoDownloadsSection {...downloads} />}

            <div className="gap-bottom_2 gap-top" />
        </Paper>
    );
};

export default ProjectInfoTab;
