import React, { useCallback, useEffect } from "react";

import { API_URL_STATIC } from "../../../../../../config/config";
import DocumentTypeEnum from "../../../../../../enums/DocumentTypeEnum";
import IDocument from "../../../../../../types/IDocument";
import InitialDocumentData from "../../../../../../types/initialData/InitialDocumentData";
import DownloadCard from "../../../../../downloadCard/DownloadCard";
import { FileUploadCms } from "../../../../../fileUpload/FileUploadCms";
import { CmsContentBlock, CmsContentBlockAttachment } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentAttachmentProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockAttachment;
}

export function CmsContentAttachment({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentAttachmentProps) {
    const { uploadsIndex, setUploadsIndex, setFormHasChangesAction, setContentIndex, contentIndex } =
        useCmsEditorContext();

    const attachment = uploadsIndex[contentNode.data?.key];

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    useEffect(() => {
        if (!editMode && (attachment == undefined || attachment.data == undefined || attachment.url == undefined)) {
            startEditMode();
        }

        if (typeof contentNode.data !== "object" || attachment === undefined) {
            updateContentNode({
                ...contentNode,
                data: { key: contentNode.id, type: DocumentTypeEnum.FILE },
            });

            setUploadsIndex({
                ...uploadsIndex,
                ["" + contentNode.id]: {
                    ...InitialDocumentData,
                    key: contentNode.id,
                    type: DocumentTypeEnum.FILE,
                },
            });
        }
    }, [contentNode.data, editMode, attachment?.key]);

    const stopEditModeIfAllowed = () => {
        if (attachment == undefined || attachment.data == undefined || attachment.url == undefined) {
            return;
        }
        stopEditMode();
    };

    const handleUploadChange = (attach: Partial<IDocument>) => {
        setUploadsIndex({
            ...uploadsIndex,
            ["" + attach.key]: attach as IDocument,
        });
        setFormHasChangesAction();
    };

    if (!editMode) {
        return (
            <DownloadCard
                onEditorClick={startEditMode}
                tooltip={attachment?.name}
                title={attachment?.title ? String(attachment.title) : String(attachment.name)}
                description={attachment?.description != null ? attachment.description : ""}
                actionButtonText="Herunterladen"
                actionButtonClassName="App-header-red-button"
                downloadUrl={API_URL_STATIC + attachment?.url}
            />
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditModeIfAllowed}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <FileUploadCms
                name={"attachment-" + contentNode.id}
                onChange={handleUploadChange}
                docData={attachment as Partial<IDocument>}
                label={"Datei"}
                defaultFilename="data.dat"
            />
        </CmsContentEditor>
    );
}
