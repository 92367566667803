import expandToDecimalPlaces from "../../../utils/expandToDecimalPlaces";
import { baseProgramm, defaultCalculationParameters } from "./FundingCalculatorForm.data";
import { FundingCalculationResult, FundingType, KfwProgramm } from "./FundingCalculatorForm.types";
import { formatNumberToVisible } from "./FundingCalculatorForm.utils";

function getBaseGap(costs: number, baseCalcParams: FundingType) {
    let gapBaseFunding = 0;
    const percentage = baseCalcParams.fundingPercentage / 100;
    const capPercentage = baseCalcParams.capFundingPercentage / 100;

    const baseFunding = costs * percentage;

    if (costs <= (baseCalcParams.lowerGapLimit as number)) {
        gapBaseFunding = baseFunding;
    } else if (costs <= (baseCalcParams.upperGapLimit as number)) {
        gapBaseFunding = baseCalcParams.capStatic as number;
    } else {
        gapBaseFunding = (baseCalcParams.capStatic as number) + costs * capPercentage;
    }

    return gapBaseFunding;
}

export function calculateFunding(
    costs: number,
    activeProgramm: KfwProgramm,
    calcData: FundingType,
    baseCalcParams: FundingType
): FundingCalculationResult {
    const percentage = calcData.fundingPercentage / 100;
    const capPercentage = calcData.capFundingPercentage / 100;

    const baseFunding = costs * percentage;
    let amountFounding = 0;

    if (activeProgramm == baseProgramm) {
        if (costs <= (baseCalcParams.lowerGapLimit as number)) {
            amountFounding = baseFunding;
        } else if (costs <= (baseCalcParams.upperGapLimit as number)) {
            amountFounding = baseCalcParams.capStatic as number;
        } else {
            amountFounding = (baseCalcParams.capStatic as number) + costs * capPercentage;
        }
    } else {
        if (costs <= (baseCalcParams.lowerGapLimit as number)) {
            amountFounding = baseFunding;
        } else {
            const baseFounding = getBaseGap(costs, baseCalcParams);
            amountFounding = baseFounding + costs * capPercentage;
        }
    }

    const fundingPercentage = expandToDecimalPlaces((amountFounding / costs) * 100, 2);

    let error: string | undefined;
    if (amountFounding > baseCalcParams.capTotal) {
        amountFounding = baseCalcParams.capTotal;
        error = `Für dieses Förderprogramm ist die Förderung auf ${formatNumberToVisible(
            baseCalcParams.capTotal
        )} € gedeckelt`;
    }

    console.log("funding", {
        calcData,
        baseFunding,
        amountFounding,
        fundingPercentage,
        error,
    });

    return {
        amountFunding: amountFounding,
        percentOfEligibleCosts: fundingPercentage,
        error,
    };
}
