import React from "react";

import ITeam from "../../../../../types/ITeam";
import ISelectOption from "../../../../../xhr/interface/ISelectOption";
import { loadAdminProjectSelectboxRequest, loadPublicProjectTeamListRequest } from "../../../../../xhr/ProjectRequests";
import { CmsPageData } from "../../../types/CmsPageData";

export interface UseGetRelationDataParams {
    pageData: CmsPageData;
    mandantKey: string;
}

export type TeamIndex = Record<string, ITeam>;

export function useGetRelationData({ mandantKey, pageData }: UseGetRelationDataParams) {
    const [projectList, setProjectList] = React.useState<ISelectOption[]>([]);

    const [teamIndex, setTeamIndex] = React.useState<TeamIndex>({});

    /**
     *
     */
    React.useEffect(() => {
        loadAdminProjectSelectboxRequest(mandantKey).then((response) => {
            setProjectList(response.data);
        });
    }, [mandantKey]);

    /**
     */
    React.useEffect(() => {
        if (pageData.data.project?.id) {
            loadPublicProjectTeamListRequest(mandantKey, "" + pageData.data.project?.id).then((response) => {
                setTeamIndex(response.data);
            });
        } else {
            setTeamIndex({});
        }
    }, [pageData.data.project?.id]);

    return {
        projectList,
        teamIndex,
    };
}
