import React from "react";

import { useActiveProjectContext } from "../../../../../masks/public/projectData/ActiveProjectContext";
import IUser from "../../../../../store/userStore/interfaces/IUser";
import IProjectFull from "../../../../../types/IProjectFull";
import ProposalMap from "../../../../olMap/proposal/ProposalMap";

export const CmsRenderContentMapComments = () => {
    const { project, actualUser } = useActiveProjectContext();

    return <ProposalMap project={project as IProjectFull} signedUser={actualUser as IUser} />;
};
