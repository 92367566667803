import {
    Breadcrumbs,
    Button,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import { API_URL_STATIC } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IMandant from "../../../types/IMandant";
import IMandantList from "../../../types/IMandantList";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    deleteMandantRequest,
    loadMandantListRequest,
} from "../../../xhr/MandantRequests";

//interface IStateProps {}
type IOwnProps = {
    mandant: IMandant;
};
type ITableContainerProps = IOwnProps; /*  & IStateProps; */
function MandantListing(props: ITableContainerProps) {
    const history = useHistory();
    const { mandant } = props;

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };
    const [mandantList, setMandantList] = React.useState<IMandantList[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsLoading(true);
        loadMandantListRequest(mandant.key)
            .then((result: any) => {
                setMandantList(result.data);
                setIsLoading(false);
                return Promise.resolve();
            })
            .catch(() => {
                setIsLoading(false);
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Benutzerdaten ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    }, [mandant]);

    const actionEditMandant = (
        event: React.SyntheticEvent<HTMLSpanElement>
    ) => {
        history.push(
            toPortalUrl(
                mandant,
                RoutesEnum.ADMIN_PORTAL + event.currentTarget.dataset.id
            )
        );
    };

    const actionCreateNewMandant = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_PORTAL + "create"));
    };

    const actionDeleteMandant = (
        event: React.SyntheticEvent<HTMLSpanElement>
    ) => {
        setIsLoading(true);
        deleteMandantRequest(
            mandant.key,
            parseInt("" + event.currentTarget.dataset.id)
        )
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Der Benutzer wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadMandantListRequest(mandant.key)
                    .then((result: any) => {
                        setMandantList(result.data);
                        setIsLoading(false);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Laden der Benutzerdaten ist ein Fehler aufgetreten",
                            type: "error",
                        });

                        setIsLoading(false);
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Löschen des Benutzers ist ein Fehler aufgetreten",
                    type: "error",
                });

                setIsLoading(false);
            });
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Portal Verwaltung</Typography>
            </Breadcrumbs>

            {isLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}

            <Button color="primary" onClick={actionCreateNewMandant}>
                Ein neues Portal erstellen
            </Button>

            <TableContainer className="main-table">
                <Table size={"small"} >
                    <TableHead>
                        <TableRow>
                            <TableCell>Logo</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Key</TableCell>
                            <TableCell>Domain</TableCell>
                            <TableCell style={{ width: "100px" }}>
                                Menu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mandantList.map(
                            (mandantEntry: IMandantList, index: number) => {
                                if (mandantEntry.key === "stegadmin") {
                                    return <></>;
                                }

                                return (
                                    <TableRow key={"mandant-" + index}>
                                        <TableCell>
                                            {mandantEntry?.logoImage?.url && (
                                                <img
                                                    alt=""
                                                    style={{
                                                        maxWidth: "150px",
                                                    }}
                                                    src={
                                                        API_URL_STATIC +
                                                        mandantEntry?.logoImage
                                                            ?.url
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {mandantEntry.name}
                                        </TableCell>
                                        <TableCell>
                                            {mandantEntry.key}
                                        </TableCell>
                                        <TableCell>
                                            <a href={mandantEntry.domain}>
                                                {mandantEntry.domain}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                component={"span"}
                                                data-id={mandantEntry.id}
                                                onClick={actionEditMandant}
                                                size={"small"}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                component={"span"}
                                                data-id={mandantEntry.id}
                                                onClick={actionDeleteMandant}
                                                size={"small"}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
}

export default MandantListing;
