import "./wysiwyg.css";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Paper, Typography } from "@material-ui/core";
import FormatClearIcon from "@material-ui/icons/FormatClear";
import { Alert } from "@material-ui/lab";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";

interface ITextEditProps {
    defaultValue: string;
    title: string;
    handleChange: (editorState: EditorState) => void;
    customTools?: boolean;
    gapBottom?: boolean;
    editLoadedValue?: string;
    showHelp?: boolean;
}
interface ICustomButton {
    button: React.ReactElement;
}
interface IResetButtonComponentProps {
    onClick: () => void;
}

const ResetButtonComponent = (props: IResetButtonComponentProps) => {
    const { onClick } = props;
    return (
        <div className="rdw-option-wrapper" title="Editor leeren" onClick={onClick}>
            <FormatClearIcon onClick={onClick} />
        </div>
    );
};

const TextEdit = (props: ITextEditProps) => {
    const { defaultValue, title, handleChange, editLoadedValue } = props;
    const [state, setState] = React.useState({
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("" + defaultValue).contentBlocks,
                convertFromHTML("" + defaultValue).entityMap
            )
        ),
    });
    const [textIsRested, setTextIsRested] = React.useState(false);

    React.useEffect(() => {
        if (editLoadedValue != null) {
            const loadedVlaue = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML("" + editLoadedValue).contentBlocks,
                    convertFromHTML("" + editLoadedValue).entityMap
                )
            );
            setState({ editorState: loadedVlaue });
        }
    }, [editLoadedValue]);

    // Handle reset state : update data with the current state (empty state)
    React.useEffect(() => {
        if (textIsRested) {
            handleChange(state.editorState);
        }
    }, [textIsRested]);

    // Reset the editor state to an empty state
    const handleReset = () => {
        setState({
            editorState: EditorState.createEmpty(),
        });
        setTextIsRested(true);
    };

    const onEditorStateChange = (editorState: EditorState) => {
        handleChange(editorState);
        setState({
            editorState,
        });
        setTextIsRested(false);
    };

    const paperClassName = props.gapBottom ? "main-paper gap-bottom" : "main-paper";

    if (title == null) {
        return <></>;
    }
    // Custom buttons
    const customButtons: ICustomButton[] = [{ button: <ResetButtonComponent onClick={handleReset} /> }];

    const toolbarCustomButtons = customButtons.map((customButton) => customButton.button);

    return (
        <Paper variant="outlined" className={paperClassName}>
            <Typography variant="h3" className="main-header">
                {title}
            </Typography>
            <Editor
                editorState={state.editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    inline: {
                        options: ["bold", "italic", "underline"],
                        blockType: {
                            inDropdown: true,
                            options: ["Normal", "H1", "H2", "H3"],
                        },
                    },
                    list: {
                        options: ["unordered", "ordered"],
                    },
                    link: {
                        inDropdown: false,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: "_self",
                        options: ["link", "unlink"],
                    },
                    options: ["inline", "list", "link", "blockType"],
                }}
                toolbarCustomButtons={toolbarCustomButtons}
            />
            {props.showHelp !== false && (
                <Alert color="info">
                    Um Text mit seiner Formatierung einzufügen, verwende Strg + V.
                    <br />
                    Um den Text im Standardformat einzufügen, benutze Strg + Umschalt + V.
                </Alert>
            )}
        </Paper>
    );
};
export default TextEdit;
