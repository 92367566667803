import { createContext, useContext } from "react";

import { CmsSectionIndexBlock } from "../../../types/CmsPageData";

interface CmsContentContextProps {
    sectionPosition: number;
    sectionNode: CmsSectionIndexBlock;
    numOfSections: number;
    deleteSectionAction: (index: number) => void;
}

export const CmsContentContext = createContext<CmsContentContextProps | null>(null);

/**
 * The context to pass Controls of Data grids
 */
export function useCmsContentContext(): CmsContentContextProps {
    const data = useContext<CmsContentContextProps | null>(CmsContentContext);

    if (data == null) {
        // should not happen
        throw new Error("Context must not be null!");
    }

    return data;
}
