import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

import ISnackbarState from "../../types/ISnackbarState";

/**
for integration just use:

const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
  ...InitialSnackbarState,
});

const closeSnackbar = () => {
  setSnackBarState({ isOpen: false, message: "", type: "success" });
};

usage:

const handleSaveClick = (event: any) => {
  saveUserRequest(mandant.key, formData, mandant.id)
    .then((response: any) => {
      setSnackBarState({
        isOpen: true,
        message: "Die Daten wurden erfolgreich gespeichert",
        type: "success",
      });
    })
    .catch((error: any) => {
      setSnackBarState({
        isOpen: true,
        message: "Fehler beim Speichern der Portaldaten",
        type: "error",
      });
    });
};

Put at the end of your render function: (but make sure it's always returned)

<Notify closeSnackbar={closeSnackbar} {...snackBarState} />

*/

interface INotifyPrpos extends ISnackbarState {
    closeSnackbar: () => void;
}

const Notify = (props: INotifyPrpos) => {
    return (
        <Snackbar open={props.isOpen} autoHideDuration={6000} onClose={props.closeSnackbar}>
            <MuiAlert
                // elevation={6}
                variant="filled"
                onClose={props.closeSnackbar}
                severity={props.type}
            >
                {props.message}
            </MuiAlert>
        </Snackbar>
    );
};

export default Notify;
