import React from "react";

import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../../types/ISnackbarState";

export function useSnackBar() {
    /**
     * Snackbar related states
     */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    return {
        setSnackBarState,
        closeSnackbar,
        snackBarState,
    };
}
