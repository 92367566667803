import { Grid, LinearProgress, Toolbar } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import IMandant from "../../../types/IMandant";
import { CmsDataContext } from "./CmsDataContext";
import { CmsRenderLayoutMapper } from "./layout/CmsRenderLayoutMapper";
import { useLoadCmsPageData } from "./useLoadCmsPageData";

interface ICmsPageRendererProps {
    mandant: IMandant;
    pageKey: string;
    projectKey?: string;
}

const CmsPageRenderer = ({ mandant, pageKey, projectKey }: ICmsPageRendererProps) => {
    const { loading, pageData, imagesIndex, uploadsIndex, teamIndex } = useLoadCmsPageData({
        mandant,
        pageKey,
        projectKey,
    });

    if (loading) {
        return (
            <div className="App-cms-container">
                <Toolbar disableGutters={true}>
                    <LinearProgress color="secondary" />
                </Toolbar>
            </div>
        );
    }

    return (
        <div className="App-cms-container">
            <Helmet>
                <title>{pageData.data.title}</title>
            </Helmet>

            <CmsDataContext.Provider
                value={{
                    pageData,
                    uploadsIndex,
                    imagesIndex,
                    teamIndex,
                }}
            >
                <Grid container spacing={2}>
                    {pageData.elements.map((section, index) => {
                        return <CmsRenderLayoutMapper key={index} section={section} />;
                    })}
                </Grid>
            </CmsDataContext.Provider>
        </div>
    );
};

export default CmsPageRenderer;
