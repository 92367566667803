import React from "react";

export function useChangesNotSaved() {
    const [changesNotSavedState, setChangesNotSavedState] = React.useState<boolean>(false);

    const closeChangesNotSavedWarning = () => {
        setChangesNotSavedState(false);
    };
    const setFormHasChangesAction = () => {
        setChangesNotSavedState(true);
    };

    return {
        changesNotSavedState,
        closeChangesNotSavedWarning,
        setFormHasChangesAction,
    };
}
