import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import { DocumentIndex } from "../../../../../../types/IDocument";
import IDocumentRef from "../../../../../../types/IDocumentRef";
import { SortableItemDownload } from "./SortableItemDownload";

export interface ISortableListDownloadProps {
    documents: IDocumentRef[];
    documentIndex: DocumentIndex;
}

export const SortableListDownload = SortableContainer<ISortableListDownloadProps>(
    ({ documents, documentIndex }: ISortableListDownloadProps) => {
        return (
            <Grid container direction="column" spacing={2} style={{ margin: "8px" }}>
                {documents ? (
                    documents.map((document, index) => {
                        return (
                            <SortableItemDownload key={index} index={index} document={documentIndex[document.key]} />
                        );
                    })
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    }
);
