import React from "react";

import FundingCalculator from "../../../../fundingCalculatorForm/FundingCalculatorForm";
import { CmsContentBlockCalculator } from "../../../types/CmsPageData";

interface CmsRenderContentCalculatorProps {
    content: CmsContentBlockCalculator;
}

export const CmsRenderContentCalculator = ({ content }: CmsRenderContentCalculatorProps) => {
    return <FundingCalculator calculationData={content.data} />;
};
