import { Grid, InputAdornment } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CurrencyInput from "react-currency-input-field";

export interface CalculatorResultValueProps {
    label: string;
    value: number | undefined;
    type: string;
    symbol: string;
}

export function CalculatorResultValue({ value, type, symbol, label }: CalculatorResultValueProps) {
    return (
        <Grid item xs={12}>
            <Typography className="main-header gap-bottom">{label}</Typography>
            <div className="currency-input MuiFormControl-root MuiTextField-root with-shadow with-text-field-border-radius">
                <CurrencyInput
                    className="MuiInputBase-input MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"
                    value={value}
                    disabled
                    decimalSeparator=","
                    groupSeparator="."
                    intlConfig={{
                        locale: "de-DE",
                    }}
                />
                <InputAdornment position="start" className={`calculator-content-container__${type}-symbol`}>
                    {symbol}
                </InputAdornment>
            </div>
        </Grid>
    );
}
