import { Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { arrayMove } from "react-sortable-hoc";

import ITeam from "../../../../../../types/ITeam";
import ITeamRef from "../../../../../../types/ITeamRef";
import TeamTile from "../../../../../team/TeamTile";
import { CmsContentBlock, CmsContentBlockPersonList } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { SortableListTeam } from "../../sorting/team/SortableListTeam";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";
import { CmsPersonSelector } from "./components/CmsPersonSelector";

export interface CmsContentPersonListProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockPersonList;
}

export function CmsContentPersonList({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentPersonListProps) {
    const [sortingEnabled, setSortingEnabled] = useState<boolean>(false);

    const { contentIndex, setContentIndex, setFormHasChangesAction, teamIndex } = useCmsEditorContext();

    const teamListSorted = useMemo(() => {
        return Object.values(teamIndex).sort((left, right) => {
            if (left.name == right.name) {
                return 0;
            }

            return left.name > right.name ? 1 : -1;
        });
    }, [teamIndex]);

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlockPersonList,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    const addAdditionalMember = () => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: [...contentNode.data, { id: "" }],
            } as CmsContentBlock,
        });
    };

    useEffect(() => {
        if (!Array.isArray(contentNode.data) || contentNode.data.length == 0) {
            updateContentNode({
                ...contentNode,
                data: [
                    {
                        id: "",
                    },
                ] as ITeamRef[],
            });
        }

        if (!editMode) {
            if (contentNode.data[0].id == "") {
                startEditMode();
            }
        }
    }, [contentNode.data, editMode]);

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: arrayMove(contentNode.data, oldIndex, newIndex),
            } as CmsContentBlock,
        });
    };

    // the use effect will fix this
    if (!Array.isArray(contentNode.data)) {
        return <></>;
    }

    if (!editMode) {
        const tiles = contentNode.data.map((team, index) => {
            return <TeamTile key={index} participant={teamIndex[team.id] as ITeam} fullWidth={true} />;
        });

        return (
            <Grid container spacing={2} onClick={startEditMode}>
                {tiles}
            </Grid>
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
            additionalButtons={[
                <IconButton key="add-file" onClick={addAdditionalMember} color="primary" disabled={sortingEnabled}>
                    <AddIcon />
                </IconButton>,
                <IconButton
                    key="toggle-sorting"
                    onClick={() => {
                        setSortingEnabled(!sortingEnabled);
                    }}
                    title={sortingEnabled ? "Sortierung deaktivieren" : "Sortierung aktivieren"}
                    color="primary"
                >
                    {sortingEnabled ? <SyncDisabledIcon /> : <SyncIcon />}
                </IconButton>,
            ]}
        >
            {sortingEnabled && (
                <SortableListTeam teamMembers={contentNode.data} teamIndex={teamIndex} onSortEnd={onSortEnd} />
            )}

            {!sortingEnabled && (
                <Grid container spacing={2}>
                    {contentNode.data.map((teamRef, index) => {
                        return (
                            <CmsPersonSelector
                                key={index}
                                value={"" + teamRef.id}
                                changeTeamHandler={(newId: string) => {
                                    const newData = [...contentNode.data];
                                    newData[index].id = newId;

                                    setContentIndex({
                                        ...contentIndex,
                                        [contentNode.id]: {
                                            ...contentNode,
                                            data: newData,
                                        } as CmsContentBlockPersonList,
                                    });
                                    setFormHasChangesAction();
                                }}
                                idKey={contentNode.id + "-" + index}
                                teamList={teamListSorted}
                            />
                        );
                    })}
                </Grid>
            )}
        </CmsContentEditor>
    );
}
