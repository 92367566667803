import React, { useCallback } from "react";

import IDocument, { DocumentIndex } from "../../../types/IDocument";
import IMandant from "../../../types/IMandant";
import ITeam from "../../../types/ITeam";
import { loadPublicCmsPage, loadPublicCmsPageByProjectKey } from "../../../xhr/CmsPageRequests";
import { initialCmsPageData } from "../editor/data/initialCmsPageData";
import { handleCmsData } from "../editor/masks/logic/handleCmsData";
import { TeamIndex } from "../editor/masks/logic/useGetRelationData";
import { CmsPageData, CmsSyncPageData } from "../types/CmsPageData";

export interface UseLoadCmsPageDataParams {
    mandant: IMandant;
    pageKey: string;
    projectKey?: string;
}

export function useLoadCmsPageData({ mandant, pageKey, projectKey }: UseLoadCmsPageDataParams) {
    const [pageData, setPageData] = React.useState<CmsPageData>({
        ...initialCmsPageData,
    });
    const [imagesIndex, setImagesIndex] = React.useState<DocumentIndex>({});
    const [uploadsIndex, setUploadsIndex] = React.useState<DocumentIndex>({});
    const [teamIndex, setTeamIndex] = React.useState<TeamIndex>({});

    const [lastPageKey, setLastPageKey] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);

    const mapServerRequestToStates = useCallback((response: CmsSyncPageData) => {
        const { data, pageProperties, elements } = handleCmsData(response.data, response.elements);

        setPageData({
            data,
            pageProperties,
            elements,
        });

        if (Array.isArray(response.images)) {
            const newImages: DocumentIndex = {};
            response.images.map((img: IDocument) => {
                newImages[img.key + ""] = img;
            });
            setImagesIndex(newImages);
        } else {
            setImagesIndex(response.images);
        }

        if (Array.isArray(response.uploads)) {
            const newUploads: DocumentIndex = {};
            response.uploads.map((upl: IDocument) => {
                newUploads[upl.key + ""] = upl;
            });
            setUploadsIndex(newUploads);
        } else {
            setUploadsIndex(response.uploads);
        }

        if (Array.isArray(response.teams)) {
            const newTeamMembers: TeamIndex = {};
            response.teams.map((member: ITeam) => {
                newTeamMembers[member.id + ""] = member;
            });
            setTeamIndex(newTeamMembers);
        } else {
            setTeamIndex(response.teams);
        }
    }, []);

    const loadPageToState = () => {
        setLoading(true);
        setLastPageKey(mandant.key + "-/-" + pageKey);

        if (projectKey) {
            loadPublicCmsPageByProjectKey(mandant.key, projectKey, pageKey)
                .then((result) => {
                    mapServerRequestToStates(result.data);

                    setLoading(false);
                    return Promise.resolve();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            loadPublicCmsPage(mandant.key, pageKey)
                .then((result) => {
                    mapServerRequestToStates(result.data);

                    setLoading(false);
                    return Promise.resolve();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    React.useEffect(() => {
        loadPageToState();
    }, [mandant.key, pageKey, projectKey]);

    if (lastPageKey !== mandant.key + "-/-" + pageKey) {
        setLastPageKey(mandant.key + "-/-" + pageKey);
        loadPageToState();
    }

    return {
        loading,
        pageData,
        imagesIndex,
        uploadsIndex,
        teamIndex,
    };
}
