import { Grid } from "@material-ui/core";
import React from "react";

import { CmsRenderContentMapper } from "../content/CmsRenderContentMapper";
import { CmsRenderLayoutProps } from "./CmsRenderLayoutProps";

export function CmsRenderLayoutTwoOne({ section }: CmsRenderLayoutProps) {
    return (
        <>
            <Grid item xs={8}>
                <CmsRenderContentMapper content={section.content[0]} />
            </Grid>
            <Grid item xs={4}>
                <CmsRenderContentMapper content={section.content[1]} />
            </Grid>
        </>
    );
}
