import IMapProposal from "../../../types/IMapProposal";
import IMapProposalType from "../../../types/IMapProposalType";

export interface IFormValidationState {
    hasError: boolean;
    comment?: boolean;
    type?: boolean;
}
export const isValidProposal = (proposal: IMapProposal, propsoalTypes: IMapProposalType[]): IFormValidationState => {
    const validationState: IFormValidationState = {
        hasError: false,
    };

    if (propsoalTypes.length > 0) {
        if (proposal.type == null) {
            validationState.type = true;
            validationState.hasError = true;
        }
    }

    if (proposal.comment.length < 1) {
        validationState.comment = true;
        validationState.hasError = true;
    }

    return validationState;
};
