import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import uuid from "uuid-random";

import { CmsRenderContentFaq } from "../../../../renderer/content/contentTypes/CmsRenderContentFaq";
import CmsFaqQuestion from "../../../../types/CmsFaqQuestion";
import { CmsContentBlock, CmsContentBlockFaq } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";
import { CmsFaqQuestionEditor } from "./components/CmsFaqQuestionEditor";

export interface CmsContentFaqProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockFaq;
}

export function CmsContentFaq({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentFaqProps) {
    const { contentIndex, setContentIndex, setFormHasChangesAction } = useCmsEditorContext();

    const updateContentNode = (newContent: CmsContentBlock) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...newContent,
            } as CmsContentBlock,
        });
        setFormHasChangesAction();
    };

    const updateQuestionNode = (newContent: CmsFaqQuestion, index: number) => {
        const newData = [...contentNode.data];
        newData[index] = newContent;

        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: newData,
            } as CmsContentBlockFaq,
        });
        setFormHasChangesAction();
    };

    const deleteQuestionNode = (questionIndex: number) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: contentNode.data.splice(questionIndex, 1),
            } as CmsContentBlockFaq,
        });
        setFormHasChangesAction();
    };

    const addAdditionalQuestion = () => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: [
                    ...contentNode.data,
                    {
                        id: uuid(),
                        question: "",
                        answers: [],
                    },
                ],
            } as CmsContentBlockFaq,
        });
        setFormHasChangesAction();
    };

    useEffect(() => {
        if (!editMode) {
            if (!Array.isArray(contentNode.data) || contentNode.data.length == 0) {
                startEditMode();
            }
        }

        if (!Array.isArray(contentNode.data)) {
            const newRefId = uuid();
            console.log("set new question");
            updateContentNode({
                ...contentNode,
                data: [
                    {
                        id: newRefId,
                        question: "",
                        answers: [
                            {
                                id: uuid(),
                                content: "",
                            },
                        ],
                    },
                ],
            });
        }
    }, [contentNode.data, editMode]);

    if (!Array.isArray(contentNode.data)) {
        return <></>;
    }

    if (!editMode) {
        return <CmsRenderContentFaq content={contentNode} onClick={startEditMode} />;
    }

    console.log("faq", { data: contentNode.data });

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
            additionalButtons={[
                <IconButton
                    key="add-file"
                    onClick={addAdditionalQuestion}
                    title="Neue Frage hinzufügen"
                    color="primary"
                >
                    <AddIcon />
                </IconButton>,
            ]}
        >
            {contentNode.data.map((question, questionIndex) => {
                return (
                    <CmsFaqQuestionEditor
                        key={question.id}
                        question={question}
                        questionIndex={questionIndex}
                        updateQuestionAction={updateQuestionNode}
                        deleteQuestionAction={deleteQuestionNode}
                        setFormHasChangesAction={setFormHasChangesAction}
                    />
                );
            })}
        </CmsContentEditor>
    );
}
