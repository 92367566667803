import { Grid } from "@material-ui/core";
import React from "react";

import { CmsRenderContentMapper } from "../content/CmsRenderContentMapper";
import { CmsRenderLayoutProps } from "./CmsRenderLayoutProps";

export function CmsRenderLayoutOne({ section }: CmsRenderLayoutProps) {
    return (
        <Grid item xs={12}>
            <CmsRenderContentMapper content={section.content[0]} />
        </Grid>
    );
}
