import "./CmsPage.css";

import React from "react";
import { useParams } from "react-router-dom";

import CmsPageRenderer from "../../../components/cms/renderer/CmsPageRenderer";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import IMandant from "../../../types/IMandant";
import { ActiveProjectContext } from "../projectData/ActiveProjectContext";

interface ICmsPageProps {
    mandant: IMandant;
}

const CmsPage = (props: ICmsPageProps) => {
    const { mandant } = props;
    const { pageKey }: { pageKey: string } = useParams();

    return (
        <div className="portal-section">
            <PortalHeader mandant={mandant} pageKey={pageKey} />
            <div className="portal-section-body-position">
                <div className="App-main">
                    <ActiveProjectContext.Provider
                        value={{
                            actualUser: undefined,
                            project: undefined,
                            mandant,
                        }}
                    >
                        <CmsPageRenderer mandant={mandant} pageKey={pageKey} />
                    </ActiveProjectContext.Provider>
                </div>
            </div>
            <PortalFooter mandant={mandant} />
        </div>
    );
};

export default CmsPage;
