import { Typography } from "@material-ui/core";
import React from "react";

import { CmsContentBlockText } from "../../../types/CmsPageData";

interface CmsRenderContentTextProps {
    content: CmsContentBlockText;
}

export const CmsRenderContentText = (props: CmsRenderContentTextProps) => {
    const { content } = props;

    return <Typography variant={"body2"}>{content.data}</Typography>;
};
