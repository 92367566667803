import { Grid } from "@material-ui/core";
import React from "react";

import { CmsContentMapper } from "../content/CmsContentMapper";
import { CmsLayoutComponentProps } from "./CmsLayoutComponentProps";

export function CmsEditLayoutOneOne({ section, sectionPosition }: CmsLayoutComponentProps) {
    return (
        <>
            <Grid item xs={6}>
                <CmsContentMapper
                    contentId={section.content[0]}
                    position={0}
                    sectionNode={section}
                    sectionPosition={sectionPosition}
                />
            </Grid>
            <Grid item xs={6}>
                <CmsContentMapper
                    contentId={section.content[1]}
                    position={1}
                    sectionNode={section}
                    sectionPosition={sectionPosition}
                />
            </Grid>
        </>
    );
}
