import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { SortableElement } from "react-sortable-hoc";

import IDocument from "../../../../../../types/IDocument";

export interface ISortableItemDownloadProps {
    document: IDocument;
    key: number;
}

export const SortableItemDownload = SortableElement<ISortableItemDownloadProps>(
    ({ document }: ISortableItemDownloadProps) => {
        return (
            <Grid item style={{ margin: "8px 0" }} className="sorting-item">
                <Typography color="textPrimary">
                    {document.name} {document.title}
                </Typography>
            </Grid>
        );
    }
);
