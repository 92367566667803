import "./CmsPageForm.css";

import { Breadcrumbs, Link, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useSnackBar } from "../../../components/cms/editor/logic/useSnackBar";
import { CmsPageEditor, CmsPageState, initialCmsPageState } from "../../../components/cms/editor/masks/CmsPageEditor";
import Notify from "../../../components/notify/Notify";
import RoutesEnum from "../../../routes/RoutesEnum";
import IMandant from "../../../types/IMandant";
import { toPortalUrl } from "../../../utils/toPortalUrl";

interface IOwnProps {
    mandant: IMandant;
}

const CmsPageForm = (props: IOwnProps) => {
    const { mandant } = props;
    const history = useHistory();

    const { pageId }: { pageId: string } = useParams<{
        pageId: string;
    }>();

    const { snackBarState, setSnackBarState, closeSnackbar } = useSnackBar();

    /*
    Handle generic change
   */

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    const [pageState, setPageState] = useState<CmsPageState>({
        ...initialCmsPageState,
    });

    return (
        <>
            <Paper variant="outlined" className={"main-paper"}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        onClick={handleBread}
                    >
                        Start
                    </Link>
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_CMS_PAGE_LIST)}
                        data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_CMS_PAGE_LIST)}
                        onClick={handleBread}
                    >
                        Liste der CMS Seiten
                    </Link>
                    <Typography color="textPrimary">
                        {pageState.isEditForm && <>Seite: {pageState.pageTitle}</>}
                        {!pageState.isEditForm && <>Eine neue Seite erstellen</>}
                    </Typography>
                </Breadcrumbs>

                <CmsPageEditor
                    mandant={mandant}
                    setSnackBarState={setSnackBarState}
                    pageId={pageId}
                    setPageState={setPageState}
                />

                <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
            </Paper>
        </>
    );
};
export default CmsPageForm;
