import { Button, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useCallback, useEffect } from "react";

import FundingCalculator from "../../../../../fundingCalculatorForm/FundingCalculatorForm";
import { defaultCalculationParameters } from "../../../../../fundingCalculatorForm/logic/FundingCalculatorForm.data";
import {
    FundingType,
    KfwCalculationConfig,
} from "../../../../../fundingCalculatorForm/logic/FundingCalculatorForm.types";
import { CmsContentBlock, CmsContentBlockCalculator } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentCalculatorProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockCalculator;
}

export function CmsContentCalculator({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentCalculatorProps) {
    const { contentIndex, setContentIndex, setFormHasChangesAction } = useCmsEditorContext();

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlockCalculator,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode, setFormHasChangesAction]
    );

    const resetToStandard = useCallback(() => {
        updateContentNode({
            ...contentNode,
            ["data"]: { ...defaultCalculationParameters },
        });
    }, [contentNode, updateContentNode]);

    // set the default values
    useEffect(() => {
        if (contentNode.data === undefined) {
            resetToStandard();
        }
    }, []);

    const changeValueKfw85 = useCallback(
        (event: any) => {
            const newData: FundingType = {
                ...contentNode.data?.kfw85,
                [event.target.name]: event.target.value,
            } as FundingType;

            updateContentNode({
                ...contentNode,
                ["data"]: {
                    ...contentNode.data,
                    ["kfw85"]: newData,
                } as KfwCalculationConfig,
            });
        },
        [contentIndex, setContentIndex, contentNode, setFormHasChangesAction]
    );

    const changeValueKfw70 = useCallback(
        (event: any) => {
            const newData: FundingType = {
                ...contentNode.data?.kfw70,
                [event.target.name]: event.target.value,
            } as FundingType;

            updateContentNode({
                ...contentNode,
                ["data"]: {
                    ...contentNode.data,
                    ["kfw70"]: newData,
                } as KfwCalculationConfig,
            });
        },
        [contentIndex, setContentIndex, contentNode, setFormHasChangesAction]
    );

    const changeValueKfw55 = useCallback(
        (event: any) => {
            const newData: FundingType = {
                ...contentNode.data?.kfw55,
                [event.target.name]: event.target.value,
            } as FundingType;

            updateContentNode({
                ...contentNode,
                ["data"]: {
                    ...contentNode.data,
                    ["kfw55"]: newData,
                } as KfwCalculationConfig,
            });
        },
        [contentIndex, setContentIndex, contentNode, setFormHasChangesAction]
    );

    const changeValueKfw40 = useCallback(
        (event: any) => {
            const newData: FundingType = {
                ...contentNode.data?.kfw40,
                [event.target.name]: event.target.value,
            } as FundingType;

            updateContentNode({
                ...contentNode,
                ["data"]: {
                    ...contentNode.data,
                    ["kfw40"]: newData,
                } as KfwCalculationConfig,
            });
        },
        [contentIndex, setContentIndex, contentNode, setFormHasChangesAction]
    );

    if (!editMode) {
        return <FundingCalculator onClick={startEditMode} />;
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <Typography variant="h2" style={{ marginBottom: "24px" }}>
                Fördergeld Rechner
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button onClick={resetToStandard}>Auf Standard zurücksetzen</Button>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                        KFW 85
                    </Typography>

                    <TextField
                        name="fundingPercentage"
                        label="Förderung %"
                        fullWidth
                        value={
                            contentNode.data?.kfw85?.fundingPercentage !== undefined
                                ? contentNode.data?.kfw85?.fundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw85}
                        variant="outlined"
                    />

                    <TextField
                        name="capFundingPercentage"
                        label="Bonusförderung %"
                        fullWidth
                        value={
                            contentNode.data?.kfw85?.capFundingPercentage !== undefined
                                ? contentNode.data?.kfw85?.capFundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw85}
                        variant="outlined"
                    />

                    <TextField
                        name="capTotal"
                        label="Deckel Förderungbetrag"
                        fullWidth
                        value={contentNode.data?.kfw85?.capTotal !== undefined ? contentNode.data?.kfw85?.capTotal : ""}
                        onChange={changeValueKfw85}
                        variant="outlined"
                    />

                    <Typography variant={"body1"} style={{ marginBottom: "16px" }}>
                        Nur bei KFW 85
                    </Typography>

                    <TextField
                        label="Dynamischer Deckel Grenzwert"
                        name="capStatic"
                        fullWidth
                        value={
                            contentNode.data?.kfw85?.capStatic !== undefined ? contentNode.data?.kfw85?.capStatic : ""
                        }
                        onChange={changeValueKfw85}
                        variant="outlined"
                    />

                    <TextField
                        label="Dynamischer Deckel (min)"
                        name="lowerGapLimit"
                        fullWidth
                        value={
                            contentNode.data?.kfw85?.lowerGapLimit !== undefined
                                ? contentNode.data?.kfw85?.lowerGapLimit
                                : ""
                        }
                        onChange={changeValueKfw85}
                        variant="outlined"
                    />

                    <TextField
                        label="Dynamischer Deckel (max)"
                        name="upperGapLimit"
                        fullWidth
                        value={
                            contentNode.data?.kfw85?.upperGapLimit !== undefined
                                ? contentNode.data?.kfw85?.upperGapLimit
                                : ""
                        }
                        onChange={changeValueKfw85}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={3}>
                    <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                        KFW 70
                    </Typography>

                    <TextField
                        label="Förderung %"
                        name="fundingPercentage"
                        fullWidth
                        value={
                            contentNode.data?.kfw70?.fundingPercentage !== undefined
                                ? contentNode.data?.kfw70?.fundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw70}
                        variant="outlined"
                    />

                    <TextField
                        label="Bonusförderung %"
                        name="capFundingPercentage"
                        fullWidth
                        value={
                            contentNode.data?.kfw70?.capFundingPercentage !== undefined
                                ? contentNode.data?.kfw70?.capFundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw70}
                        variant="outlined"
                    />

                    <TextField
                        label="Deckel Förderungbetrag"
                        name="capTotal"
                        fullWidth
                        value={contentNode.data?.kfw70?.capTotal !== undefined ? contentNode.data?.kfw70?.capTotal : ""}
                        onChange={changeValueKfw70}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={3}>
                    <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                        KFW 55
                    </Typography>

                    <TextField
                        label="Förderung %"
                        name="fundingPercentage"
                        fullWidth
                        value={
                            contentNode.data?.kfw55?.fundingPercentage !== undefined
                                ? contentNode.data?.kfw55?.fundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw55}
                        variant="outlined"
                    />

                    <TextField
                        label="Bonusförderung %"
                        name="capFundingPercentage"
                        fullWidth
                        value={
                            contentNode.data?.kfw55?.capFundingPercentage !== undefined
                                ? contentNode.data?.kfw55?.capFundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw55}
                        variant="outlined"
                    />

                    <TextField
                        label="Deckel Förderungbetrag"
                        name="capTotal"
                        fullWidth
                        value={contentNode.data?.kfw55?.capTotal !== undefined ? contentNode.data?.kfw55?.capTotal : ""}
                        onChange={changeValueKfw55}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={3}>
                    <Typography variant={"h3"} style={{ marginBottom: "16px" }}>
                        KFW 40
                    </Typography>

                    <TextField
                        label="Förderung %"
                        name="fundingPercentage"
                        fullWidth
                        value={
                            contentNode.data?.kfw40?.fundingPercentage !== undefined
                                ? contentNode.data?.kfw40?.fundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw40}
                        variant="outlined"
                    />

                    <TextField
                        label="Bonusförderung %"
                        name="capFundingPercentage"
                        fullWidth
                        value={
                            contentNode.data?.kfw40?.capFundingPercentage !== undefined
                                ? contentNode.data?.kfw40?.capFundingPercentage
                                : ""
                        }
                        onChange={changeValueKfw40}
                        variant="outlined"
                    />

                    <TextField
                        label="Deckel Förderungbetrag"
                        name="capTotal"
                        fullWidth
                        value={contentNode.data?.kfw40?.capTotal !== undefined ? contentNode.data?.kfw40?.capTotal : ""}
                        onChange={changeValueKfw40}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </CmsContentEditor>
    );
}
