import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import ITeam from "../../../../../../types/ITeam";
import ITeamRef from "../../../../../../types/ITeamRef";
import { SortableItemTeam } from "./SortableItemTeam";

export interface ISortableListTeamProps {
    teamMembers: ITeamRef[];
    teamIndex: Record<string, ITeam>;
}

export const SortableListTeam = SortableContainer<ISortableListTeamProps>(
    ({ teamMembers, teamIndex }: ISortableListTeamProps) => {
        return (
            <Grid container direction="column" spacing={2} style={{ margin: "8px" }}>
                {teamMembers ? (
                    teamMembers.map((member, index) => {
                        return <SortableItemTeam key={index} index={index} member={teamIndex[member.id]} />;
                    })
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    }
);
