import { createContext, useContext } from "react";

import { DocumentIndex, DocumentIndexSetter } from "../../../../types/IDocument";
import { CmsPageData, CmsPageDataSetter } from "../../types/CmsPageData";
import { CmsContentMap, CmsContentMapSetter, CmsSectionMap, CmsSectionMapSetter } from "./logic/useBackend";
import { TeamIndex } from "./logic/useGetRelationData";

interface CmsEditorContextProps {
    pageData: CmsPageData;
    setPageData: CmsPageDataSetter;

    sectionIndex: CmsSectionMap;
    setSectionIndex: CmsSectionMapSetter;
    contentIndex: CmsContentMap;
    setContentIndex: CmsContentMapSetter;
    sectionIds: string[];
    setSectionIds: (sectionIs: string[]) => void;

    imagesIndex: DocumentIndex;
    setImagesIndex: DocumentIndexSetter;
    uploadsIndex: DocumentIndex;
    setUploadsIndex: DocumentIndexSetter;
    teamIndex: TeamIndex;

    setFormHasChangesAction: () => void;
    deleteSectionAction: (idx: number) => void;
}

export const CmsEditorContext = createContext<CmsEditorContextProps | null>(null);

/**
 * The context to pass Controls of Data grids
 */
export function useCmsEditorContext(): CmsEditorContextProps {
    const data = useContext<CmsEditorContextProps | null>(CmsEditorContext);

    if (data == null) {
        // should not happen
        throw new Error("Context must not be null!");
    }

    return data;
}
