import draft, { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useCallback, useEffect } from "react";

import { getEditorStateContent } from "../../../../../../utils/editorContentHelper/editorContentHelper";
import TextEdit from "../../../../../wysiwyg/TextEdit";
import HtmlContent from "../../../../renderer/content/components/HtmlContent";
import { CmsContentBlock, CmsContentBlockText } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentHtmlProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockText;
}

export function CmsContentHtml({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentHtmlProps) {
    const { contentIndex, setContentIndex, setFormHasChangesAction } = useCmsEditorContext();

    const updateContentData = useCallback(
        (newText: string) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...contentNode,
                    data: newText,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    useEffect(() => {
        if (!editMode && contentNode.data.length == 0) {
            startEditMode();
        }

        if (Array.isArray(contentNode.data) || typeof contentNode.data == "object") {
            // set an empty string if the content is an object or an array
            updateContentData("");
        }
    }, [contentNode.data, editMode]);

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    const handleContentWysiwygChange = (editorState: EditorState) => {
        const rawContentState = draft.convertToRaw(editorState.getCurrentContent());

        updateContentData(draftToHtml(getEditorStateContent(editorState, rawContentState)));

        setFormHasChangesAction();
    };

    if (!editMode) {
        return (
            <HtmlContent
                onClick={startEditMode}
                className={"cmsHoverContent"}
                content={contentNode.data != null ? (contentNode.data as string) : ""}
            />
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <TextEdit
                handleChange={handleContentWysiwygChange}
                title="Freitext"
                showHelp={false}
                defaultValue={contentNode.data ? contentNode.data : ""}
            />
        </CmsContentEditor>
    );
}
