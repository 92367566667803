import "./FundingCalculatorForm.css";

import { Button, Grid, InputAdornment, MenuItem, Select } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CurrencyInput from "react-currency-input-field";

import { CalculatorResultValue } from "./components/CalculatorResultValue";
import { fundingTypes } from "./logic/FundingCalculatorForm.data";
import { FundingProgramm, KfwCalculationConfig } from "./logic/FundingCalculatorForm.types";
import { useFundingCalculatorLogic } from "./logic/useFundingCalculatorFormLogic";

interface FundingCalculatorProps {
    onClick?: () => void;
    calculationData?: KfwCalculationConfig;
}

export const FundingCalculator = ({ onClick, calculationData }: FundingCalculatorProps) => {
    const {
        buttonIsDisabled,
        handleCalculation,
        changeInputEligibleCosts,
        changeInput,
        fundingCalculationInputs,
        fundingCalculationResult,
    } = useFundingCalculatorLogic({
        calculationData,
    });

    return (
        <div onClick={onClick}>
            <Typography variant="h2" className="gap-bottom calculator-header">
                Fördermittelrechner
            </Typography>
            <div className="calculator-content-container">
                <Grid
                    container
                    md={4}
                    lg={4}
                    xs={12}
                    className="calculator-content-container__calculation-section with-shadow"
                >
                    <div>
                        <Grid item xs={12} className="calculator-content-container__calculation-section-mobile">
                            <Typography className="main-header gap-bottom">Anrechenbare Kosten</Typography>
                            <div className="currency-input MuiFormControl-root MuiTextField-root with-text-field-border-radius">
                                <CurrencyInput
                                    className="MuiInputBase-input MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"
                                    id="Förderfähige Kosten"
                                    name="eligibleCosts"
                                    required={true}
                                    decimalSeparator=","
                                    groupSeparator="."
                                    onValueChange={changeInputEligibleCosts}
                                    allowNegativeValue={false}
                                    intlConfig={{
                                        locale: "de-DE",
                                    }}
                                />
                                <InputAdornment
                                    position="start"
                                    className="calculator-content-container__calculation-section__unit-symbol"
                                >
                                    €
                                </InputAdornment>
                            </div>
                        </Grid>
                        <Grid item xs={12} className="calculator-content-container__calculation-section-mobile">
                            <Typography className="main-header gap-bottom">KFW Standard</Typography>
                            <Select
                                id="fundingCalculatorType"
                                value={fundingCalculationInputs.fundingCalculatorType}
                                fullWidth
                                onChange={(e) => {
                                    changeInput(e);
                                }}
                                variant="outlined"
                                required={true}
                                name="fundingCalculatorType"
                                className="calculator-content-container__kfw-types"
                            >
                                {fundingTypes.map((type: FundingProgramm) => {
                                    return (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleCalculation}
                        className={`${
                            !buttonIsDisabled ? "button--colored" : ""
                        } calculator-content-container__calculation-section__edit-button`}
                        disabled={buttonIsDisabled}
                    >
                        Berechnen
                    </Button>
                </Grid>
                <Grid container className="calculator-content-container__result-section">
                    <CalculatorResultValue
                        label="Förderung"
                        value={fundingCalculationResult.amountFunding}
                        type={"calculation-section__unit"}
                        symbol="€"
                    />

                    {/*

                        <CalculatorResultValue
                            label="Deckel der Förderung"
                            value={fundingCalculationResult.basicFundingCap}
                            type={"calculation-section__unit"}
                            symbol="€"
                        />
                    */}

                    <CalculatorResultValue
                        label="Prozent der anrechenbaren Kosten"
                        value={fundingCalculationResult.percentOfEligibleCosts}
                        type={"result-section__percentage"}
                        symbol="%"
                    />

                    {fundingCalculationResult.error !== undefined && (
                        <Grid item xs={12} className={`calculator-content-container__result-section__error-message`}>
                            <Typography className="calculator-content-container__result-section__error-message__content">
                                {fundingCalculationResult.error}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} className={"gap-top"}>
                        <Typography variant="caption">Alle Angaben ohne Gewähr.</Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default FundingCalculator;
