import { Grid, Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/renderer/content/components/HtmlContent";
import FolderList from "../../../../components/folderList/FolderList";
import ImageGallery from "../../../../components/imageGallery/ImageGallery";
import { API_URL_STATIC } from "../../../../config/config";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectDataTabProps {
    project: IProjectFull;
    mandant: IMandant;
}

function ProjectAboutTab(props: IProjectDataTabProps) {
    const { project } = props;

    return (
        <>
            <Grid container spacing={4}>
                {!!project.description && !project.description2 && (
                    <Grid item xs={12} md={12} lg={12}>
                        <HtmlContent className="content-block" content={project.description} />
                    </Grid>
                )}

                {!!project.description && !!project.description2 && (
                    <>
                        <Grid item xs={12} md={6}>
                            <HtmlContent className="content-block" content={project.description} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HtmlContent className="content-block" content={project.description2} />
                        </Grid>
                    </>
                )}

                <Grid item xs={12} md={12} lg={12}>
                    {project.imageMap?.url && (
                        <>
                            <img
                                className="map-image"
                                src={API_URL_STATIC + project.imageMap.url}
                                alt={project.imageMap.description}
                                style={{ maxWidth: "100%" }}
                            />
                            <p>{project.imageMap?.description.length ? project.imageMap.description : ""}</p>
                        </>
                    )}
                </Grid>
            </Grid>

            {Boolean(project?.attachments?.length) && <FolderList files={project.attachments} fromEventTab={false} />}

            {Boolean(project?.images.length) && (
                <>
                    <Typography gutterBottom variant="h3" className="gap-top">
                        Bilder zum Projekt
                    </Typography>
                    <ImageGallery images={project.images} />
                </>
            )}
        </>
    );
}

export default ProjectAboutTab;
