import "./PartnerLogoCard.css";

import {
    Box,
    Card,
    FormControl,
    Grid,
    InputLabel,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { AddPhotoAlternateOutlined } from "@material-ui/icons";
import IconDelete from "@material-ui/icons/Delete";
import IconReplay from "@material-ui/icons/Replay";
import React from "react";

import { API_URL_STATIC } from "../../../../../config/config";
import IDocument from "../../../../../types/IDocument";
import ILogoPostion from "../../../../../types/ILogoPostion";
import IPartnerLogo from "../../../../../types/IPartnerLogo";
import { formatBytes } from "../../../../../utils/bytesConverter";
import { logosPositions } from "../../constants";

type IProps = {
    partnerLogo: IPartnerLogo;
    index: number;
    isNotImage?: boolean;
    isSorting?: boolean;
    onChange: (image: IPartnerLogo, index: number) => void;
    onDelete: (index: number) => void;
    onReset: (index: number, imageId: number) => void;
};

const PartnerLogoCard = (props: IProps) => {
    const { partnerLogo, index, isSorting, onChange, onDelete, onReset } =
        props;

    if (partnerLogo === null) {
        return <div>Invalid image</div>;
    }

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            onChange(
                {
                    ...partnerLogo,
                    document: {
                        ...partnerLogo.document,
                        data: reader.result,
                        name: file.name,
                        size: file.size,
                        type: file.type,
                    },
                    isChanged: true,
                },
                props.index
            );
        };
    };

    const handleTextChangePartner = (event: any) => {
        onChange(
            {
                ...partnerLogo,
                [event.target.name]: event.target.value,
                isChanged: true,
            },
            props.index
        );
    };

    const handleTextChange = (event: any) => {
        onChange(
            {
                ...partnerLogo,
                document: {
                    ...partnerLogo.document,
                    [event.target.name]: event.target.value,
                },
                isChanged: true,
            },
            props.index
        );
    };
    const handleSelectChange = (event: any) => {
        onChange(
            {
                ...partnerLogo,
                position: event.target.value,
                isChanged: true,
            },
            props.index
        );
    };
    const getImageSrc = (imageNode: IDocument) => {
        if (imageNode.data) {
            return imageNode.data;
        }
        if (imageNode.url) {
            return API_URL_STATIC + imageNode.url;
        }

        return null;
    };
    const currentPosition = logosPositions.find(
        (position: ILogoPostion) => position.id === partnerLogo.position
    );
    const imageSource = getImageSrc(partnerLogo.document);
    return (
        <Grid container item xs={12} md={6} lg={4} direction="column">
            <Card variant="outlined" style={{ height: "100%" }}>
                <FormControl fullWidth>
                    <Paper elevation={0}>
                        <input
                            accept="image/*"
                            id={"partner-logo-image-upload-" + props.index}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleUploadClick}
                        />

                        <label
                            htmlFor={"partner-logo-image-upload-" + props.index}
                        >
                            {!imageSource && (
                                <Fab
                                    component="span"
                                    style={{
                                        marginTop: "16px",
                                        marginLeft: "16px",
                                        marginBottom: "16px",
                                    }}
                                >
                                    <AddPhotoAlternateOutlined />
                                </Fab>
                            )}
                        </label>
                        {imageSource != null && (
                            <img
                                style={{ maxWidth: "100%" }}
                                src={imageSource}
                                alt={"fresh uploaded"}
                            />
                        )}
                        {!!partnerLogo.document.size && (
                            <Typography align="right">
                                Dateigröße :{" "}
                                {formatBytes(partnerLogo.document.size)}
                            </Typography>
                        )}
                    </Paper>
                    <Box m={0} padding={2}>
                        <Paper elevation={0}>
                            <TextField
                                variant="outlined"
                                label="Name Bild"
                                name="name"
                                value={partnerLogo.document.name || " "}
                                onChange={handleTextChange}
                                fullWidth
                                disabled={isSorting}
                            />
                            <TextField
                                variant="outlined"
                                label="Titel"
                                name="title"
                                value={partnerLogo.document.title || " "}
                                onChange={handleTextChange}
                                fullWidth
                                disabled={isSorting}
                            />
                            <TextField
                                variant="outlined"
                                label="Copyright *"
                                name="copyright"
                                value={partnerLogo.document.copyright || " "}
                                onChange={handleTextChange}
                                fullWidth
                                disabled={isSorting}
                            />

                            <TextField
                                variant="outlined"
                                label="Beschreibung"
                                name="description"
                                value={partnerLogo.document.description || " "}
                                onChange={handleTextChange}
                                fullWidth
                                multiline
                                disabled={isSorting}
                            />

                            <TextField
                                variant="outlined"
                                label="Link zum Partner"
                                name="url"
                                value={partnerLogo?.url || " "}
                                onChange={handleTextChangePartner}
                                fullWidth
                                disabled={isSorting}
                            />

                            <InputLabel
                                id={"input-position-label"}
                                className="patrner-logo-postion-label"
                            >
                                Position
                            </InputLabel>
                            <Select
                                id="input-position"
                                value={currentPosition?.id || ""}
                                fullWidth={true}
                                onChange={handleSelectChange}
                                variant="outlined"
                                name="position"
                            >
                                {logosPositions.map(
                                    (position: ILogoPostion) => {
                                        return (
                                            <MenuItem
                                                key={position.id}
                                                value={position.id}
                                            >
                                                {position.label}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </Paper>
                        {!isSorting && (
                            <IconButton
                                className="event_form-button"
                                onClick={() => {
                                    onDelete(index);
                                }}
                                title="Löschen"
                            >
                                <IconDelete />
                            </IconButton>
                        )}

                        {partnerLogo.isChanged && partnerLogo.id != null && (
                            <IconButton
                                className="event_form-button"
                                color="secondary"
                                title="Zurücksetzen"
                                onClick={() => {
                                    onReset(
                                        index,
                                        parseInt("" + partnerLogo.id)
                                    );
                                }}
                            >
                                <IconReplay />
                            </IconButton>
                        )}
                    </Box>
                </FormControl>
            </Card>
        </Grid>
    );
};
export default PartnerLogoCard;
