import "./SwipableDrawer.css";

import { Divider, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { History } from "history";
import React from "react";

import logo from "../../assets/images/logo.png";
import { IMenuEntry } from "../../masks/admin/projectForm/tabs/ProjectMenuTab";
import { IRichHeaderEntry } from "../../types/IHeaderEntry";
import IMandant from "../../types/IMandant";
import IProjectFull from "../../types/IProjectFull";
import getLinkActionUrl from "../../utils/headerMenu/getLinkActionUrl";
import CmsImage from "../cms/renderer/content/components/CmsImage";

interface ISwipableDrawerProps {
    entries: IRichHeaderEntry[];
    title: string;
    history: History;
    logoUrl: string;
    handleLogoRouting: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    mandant?: IMandant;
    activeProject?: IProjectFull | null;
    buttons?: any;
}

export default function SwipableDrawer(props: ISwipableDrawerProps) {
    const { entries, title, mandant, activeProject, buttons, history, logoUrl, handleLogoRouting } = props;
    const [state, setState] = React.useState({
        left: false,
    });
    const [openedSubMenu, setOpenedSubMenu] = React.useState<string[]>([]);

    const closeSubmenu = (openedSubmenuList: string[], submenuLabel: string) => {
        // Filter out the submenuLabel from the openedSubmenuList
        return openedSubmenuList.filter((label: string) => label !== submenuLabel);
    };

    const handleSubMenuOpening = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, label: string) => {
        event.stopPropagation();
        if (openedSubMenu.indexOf(label) === -1) {
            // If label is not there, add it
            setOpenedSubMenu((prevOpenedSubMenu) => [...prevOpenedSubMenu, label]);
        } else {
            // If label exisits, remove it
            setOpenedSubMenu((prevOpenedSubMenu) => closeSubmenu(prevOpenedSubMenu, label));
        }
    };
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setState({ ...state, left: open });
    };

    const OnClickItem = (entry: any) => {
        entry.onClick();
        setState({ ...state, left: false });
        return;
    };

    const EntryLabel = ({ entry }: any) => (
        <>
            <span className="list-entry-label">{entry.label} </span>
            {entry.children && entry.children.length > 0 && (
                <IconButton onClick={(event) => handleSubMenuOpening(event, entry.label)}>
                    {openedSubMenu.indexOf(entry.label) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            )}
        </>
    );
    const list = () => (
        <div role="presentation" onKeyDown={toggleDrawer(false)}>
            <List className="drawer-items">
                <ListItem key="drawer-title" className="drawer-title">
                    <ListItemText primary={title} />
                </ListItem>
                {entries.map((entry: IRichHeaderEntry, index: number) => {
                    return (
                        <div className="list_items-container" key={entry.label}>
                            {index === 0 && <Divider />}
                            <ListItem
                                button
                                className={`list-item ${entry?.active} ${
                                    openedSubMenu.indexOf(entry.label) !== -1 ? "rich-list-item" : ""
                                }`}
                                onClick={() => {
                                    OnClickItem(entry);
                                }}
                            >
                                <ListItemText
                                    className={`${
                                        entry.children && entry.children.length > 0 ? "rich-list-item__label" : ""
                                    }`}
                                    primary={<EntryLabel entry={entry} />}
                                />
                                {openedSubMenu.indexOf(entry.label) !== -1 &&
                                    entry.children &&
                                    entry.children.length > 0 && (
                                        <List className="rich-list-item__children">
                                            {entry.children.map((subMenuEntry: IMenuEntry, subMenuIndex: number) => {
                                                const linkSubPath = getLinkActionUrl(
                                                    subMenuEntry,
                                                    mandant,
                                                    activeProject
                                                );

                                                return (
                                                    <div
                                                        className="submenu-items-container"
                                                        key={index + "-" + subMenuIndex}
                                                    >
                                                        <Divider />
                                                        <ListItem
                                                            button
                                                            className={`rich-list-item__children__item ${entry?.active}`}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                history.push(linkSubPath);
                                                            }}
                                                        >
                                                            <ListItemText primary={subMenuEntry.label} />
                                                        </ListItem>
                                                    </div>
                                                );
                                            })}
                                        </List>
                                    )}
                            </ListItem>
                            <Divider />
                        </div>
                    );
                })}
            </List>
        </div>
    );

    const hideStegLogo = mandant && mandant.flagHideStegLogo === true;
    return (
        <div>
            <React.Fragment>
                <div onClick={toggleDrawer(true)}>
                    <IconButton>
                        <MenuIcon />
                    </IconButton>
                </div>
                <SwipeableDrawer
                    anchor={"right"}
                    open={state.left}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <IconButton className="close-button" onClick={toggleDrawer(false)}>
                        <CloseIcon />
                    </IconButton>

                    {list()}
                    <div>
                        <div className="drawer-logo">
                            {mandant && (
                                <a href={logoUrl} target="_blank" onClick={handleLogoRouting} rel="noopener noreferrer">
                                    <CmsImage className="App-header-logo-customer" image={mandant.logoImage} />
                                </a>
                            )}
                            {!hideStegLogo && (
                                <a href={"https://steg.de"} target="__blank">
                                    <img className="App-header-logo-steg" src={logo} alt="Logo" />
                                </a>
                            )}
                        </div>
                        <div className="drawer-btns">{buttons()}</div>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
