import React from "react";

import TaskComments from "../../../../components/comment/TaskComments";
import IUser from "../../../../store/userStore/interfaces/IUser";
import IEventFull from "../../../../types/IEventFull";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

interface ICommentTabProps {
    event: IEventFull;
    mandant: IMandant;
    user: IUser;
}

function CommentTab(props: ICommentTabProps) {
    const { event, mandant, user } = props;

    const project = event.project as IProjectFull;

    return (
        <div className="gap-top">
            <TaskComments project={project} eventId={event.id} mandant={mandant} user={user} />
        </div>
    );
}

export default CommentTab;
