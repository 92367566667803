import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { Helmet } from "react-helmet";

import HtmlContent from "../../../components/cms/renderer/content/components/HtmlContent";
import ContactForm from "../../../components/contact/ContactForm";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import IMandant from "../../../types/IMandant";

interface IOwnProps {
    mandant: IMandant;
}

interface IContactParams extends IOwnProps {}

const Contact = (props: IContactParams) => {
    const { mandant } = props;

    return (
        <div className="portal-section">
            <Helmet>
                <title>Kontaktanfrage</title>
            </Helmet>
            <PortalHeader mandant={mandant} />

            <div className="portal-section-body-position">
                <div className="App-main">
                    <Paper className="main-paper" variant="outlined" square>
                        <Typography gutterBottom variant="h2">
                            Kontaktanfrage zum Projektteam
                        </Typography>
                        <HtmlContent
                            className="App-cms-container-textblock"
                            content={"" + mandant.contactFormTextBefore}
                        />

                        <ContactForm />

                        <HtmlContent
                            className="App-cms-container-textblock"
                            content={"" + mandant.contactFormTextAfter}
                        />
                    </Paper>
                </div>
            </div>
            <PortalFooter mandant={mandant} />
        </div>
    );
};

export default Contact;
