import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { SortableElement } from "react-sortable-hoc";

import ITeam from "../../../../../../types/ITeam";

export interface ISortableItemTeamProps {
    member: ITeam;
    key: number;
}

export const SortableItemTeam = SortableElement<ISortableItemTeamProps>(({ member }: ISortableItemTeamProps) => {
    return (
        <Grid item style={{ margin: "8px 0" }} className="sorting-item">
            {member.displayTitle && (
                <Typography color="textPrimary">
                    {member.displayTitle} {member.name}
                </Typography>
            )}
        </Grid>
    );
});
