import { FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";

import ITeam from "../../../../../../../types/ITeam";

export interface CmsPersonSelectorProps {
    teamList: ITeam[];
    changeTeamHandler: (memberId: string) => void;
    value: string;
    idKey: string;
}

export function CmsPersonSelector({ teamList, changeTeamHandler, idKey, value }: CmsPersonSelectorProps) {
    return (
        <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id={"team-select-label" + idKey}>Teammitglied</InputLabel>
            <Select
                labelId={"team-select-label" + idKey}
                value={"" + value}
                onChange={(event) => {
                    changeTeamHandler("" + event.target.value);
                }}
                label="Teammitglied"
            >
                <MenuItem key="empty" value="">
                    <em>Teammitglied wählen</em>
                </MenuItem>
                {teamList.map((team: ITeam, teamDropdownIndex: number) => {
                    return (
                        <MenuItem key={"team-" + teamDropdownIndex} value={"" + team.id}>
                            {team.displayTitle} {team.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
