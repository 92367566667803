import React, { useEffect, useState } from "react";

import { APP_VERSION } from "../../config/config";
import DevLogger from "../../utils/logger/DevLogger";

interface ICacheBusterProps {
    children: (loading: boolean, isLatestVersion: boolean, refreshCacheAndReload: () => void) => any;
}

const CacheBuster = (props: ICacheBusterProps) => {
    const [actState, setActState] = useState({
        loading: true,
        isLatestVersion: false,
    });

    const semverGreaterThan = (versionA: string, versionB: string) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());
            const b = Number(versionsB.shift());
            // eslint-disable-next-line no-continue
            if (a === b) {
                continue;
            }
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    };

    useEffect(() => {
        fetch("/meta.json")
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion: string = "" + meta.version;
                const currentVersion = APP_VERSION;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

                if (shouldForceRefresh) {
                    // eslint-disable-next-line no-console
                    DevLogger.logInfo(`We have a new version - ${latestVersion}. Should force refresh`);
                    setActState({ loading: false, isLatestVersion: false });
                } else {
                    // eslint-disable-next-line no-console
                    DevLogger.logInfo(
                        `You already have the latest version - ${latestVersion}. No cache refresh needed.`
                    );
                    setActState({ loading: false, isLatestVersion: true });
                }
            });
    }, []);

    const refreshCacheAndReload = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
                for (const name of names) {
                    caches.delete(name);
                }
            });
            // delete browser cache and hard reload
            window.location.reload(true);
        }
    };

    const { loading, isLatestVersion } = actState;
    return props.children(loading, isLatestVersion, refreshCacheAndReload);
};

export default CacheBuster;
