import React, { useState } from "react";
// @ts-ignore
import Carousel, { Image, Modal, ModalGateway } from "react-images";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";

interface ImageGalleryParams {
    images: any[];
}

const ImageGallery = (props: ImageGalleryParams) => {
    const [currentImage, setCurrentImage] = useState<number>(0);
    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <>
            <Carousel
                currentIndex={currentImage}
                views={props.images.map(
                    (image: IDocument): Image => ({
                        src: API_URL_STATIC + image.url,
                        caption: image.description,
                        alt: image.description,
                    })
                )}
            />

            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={props.images.map(
                                (image: IDocument): Image => ({
                                    src: API_URL_STATIC + image.url,
                                    caption: image.description,
                                    alt: image.description,
                                })
                            )}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
};

export default ImageGallery;
