import React, { useCallback } from "react";

import { CmsPageData, CmsPageDataSetter, CmsPageLayoutTypes, CmsSectionBlock } from "../../../types/CmsPageData";
import { listOfLayouts } from "../../data/listOfLayouts";
import { CmsContentMap, CmsContentMapSetter, CmsSectionMap, CmsSectionMapSetter } from "../../masks/logic/useBackend";

interface UseEditorElementsParams {
    pageData: CmsPageData;
    setPageData: CmsPageDataSetter;

    sectionIndex: CmsSectionMap;
    setSectionIndex: CmsSectionMapSetter;
    contentIndex: CmsContentMap;
    setContentIndex: CmsContentMapSetter;
    sectionIds: string[];
    setSectionIds: (sectionIs: string[]) => void;

    setFormHasChangesAction: () => void;
}

export function useCmsEditorContentLayouts({
    pageData,
    setPageData,
    setFormHasChangesAction,

    sectionIndex,
    setSectionIndex,
    contentIndex,
    setContentIndex,
    sectionIds,
    setSectionIds,
}: UseEditorElementsParams) {
    const deleteSectionAction = useCallback(
        (index: number) => {
            const sectionId = sectionIds[index];

            sectionIndex[sectionId].content.forEach((contentId) => {
                delete contentIndex[contentId];
            });
            delete sectionIndex[sectionId];
            sectionIds.splice(index, 1);

            setSectionIds([...sectionIds]);
            setSectionIndex({ ...sectionIndex });
            setContentIndex({ ...contentIndex });
        },
        [sectionIds, pageData, setPageData]
    );

    const addNewPageSectionAction = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        const type = ("" + event.currentTarget.dataset.type) as CmsPageLayoutTypes;

        let newCol: CmsSectionBlock;

        switch (type) {
            case "l_1": {
                newCol = listOfLayouts.l_1();
                break;
            }
            case "l_1_1": {
                newCol = listOfLayouts.l_1_1();
                break;
            }
            case "l_1_1_1": {
                newCol = listOfLayouts.l_1_1_1();
                break;
            }
            case "l_1_1_1_1": {
                newCol = listOfLayouts.l_1_1_1_1();
                break;
            }
            case "l_1_2": {
                newCol = listOfLayouts.l_1_2();
                break;
            }
            case "l_2_1": {
                newCol = listOfLayouts.l_2_1();
                break;
            }
            case "l_1_2_1": {
                newCol = listOfLayouts.l_1_2_1();
                break;
            }
            default: {
                // fallback to only
                newCol = listOfLayouts.l_1();
            }
        }

        const { id, layout, content } = newCol;

        setSectionIds([...sectionIds, id]);

        const newContentIds: string[] = [];
        const newContentIndex: CmsContentMap = {};
        content.forEach((node) => {
            newContentIds.push(node.id);
            newContentIndex[node.id] = node;
        });

        setSectionIndex({
            ...sectionIndex,
            [id]: { id, layout, content: newContentIds },
        });

        setContentIndex({
            ...contentIndex,
            ...newContentIndex,
        });

        setFormHasChangesAction();
    };

    return {
        deleteSectionAction,
        addNewPageSectionAction,
    };
}
