import "./ProjectListing.css";

import { Breadcrumbs, Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconAdd from "@material-ui/icons/Add";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import ProjectTile from "../../../components/project/projectTile/ProjectTile";
import RoleEnum from "../../../enums/RoleEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IProjectList from "../../../types/IProjectList";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import userHasRoles from "../../../utils/userHasRole";
import {
    deleteProjectRequest,
    loadProjectsRequest,
    switchProjectPublishFlagRequest,
} from "../../../xhr/ProjectRequests";

interface IStateProps {
    activeUser: IUser | null;
}
interface IOwnProps {
    onProjectEditClick?: (id: number) => {};
    //mandantKey prop from privateRoute
    mandant: IMandant;
    handleSelectedHome: (index: number) => void;
}

type IProjectListingProps = IStateProps & IOwnProps;

const ProjectListing = (props: IProjectListingProps) => {
    const history = useHistory();
    const [data, setData] = React.useState<IProjectList[]>([]);
    const [projectsLoaded, setProjectsLoaded] = React.useState<boolean>(false);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const [showArchivedProjects, setShowArchivedProjects] = React.useState<boolean>(false);
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };
    const { mandant, activeUser, handleSelectedHome } = props;
    const confirm = useConfirm();

    const loadProjectsToState = () => {
        loadProjectsRequest(mandant.key)
            .then((response: any) => {
                setData(response.data);
                setProjectsLoaded(true);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Projektdaten ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setProjectsLoaded(true);
            });
    };
    React.useEffect(() => {
        loadProjectsToState();
    }, [mandant]);
    React.useEffect(() => {
        handleSelectedHome(1);
    }, [handleSelectedHome]);

    const onProjectEditClick = (id: number) => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_PROJECT + id));
    };

    const onCreateProjectClick = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_PROJECT + "create"));
    };

    const onProjectPublishClick = (id: number, mandKey: string) => {
        switchProjectPublishFlagRequest(id, mandKey)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Projekt wurde publiziert.",
                    type: "success",
                });

                loadProjectsRequest(mandant.key)
                    .then((newResp: any) => {
                        setData(newResp.data);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Die aktualisierte Projektliste konnte nicht geladen werden",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Projekt wurde depubliziert.",
                    type: "error",
                });
            });
    };

    const handleDeleteProject = (id: number) => {
        confirm({
            description: "Möchten Sie dieses Projekt wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                deleteProjectRequest(id, mandant.key)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Das Projekt wurde gelöscht.",
                            type: "success",
                        });
                        loadProjectsRequest(mandant.key).then((responses: any) => {
                            setData(responses.data);
                        });
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Löschen des Projektes ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                    });
                loadProjectsRequest(mandant.key)
                    .then((response: any) => {
                        setData(response.data);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Die aktualisierte Projektliste konnte nicht geladen werden",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                return;
            });
    };
    const filteredProjectsData = showArchivedProjects
        ? data
        : data.filter((project: IProjectList) => !project.flagArchive);
    return (
        <>
            {!projectsLoaded ? (
                <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />
            ) : (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary">Projekte verwalten</Typography>
                    </Breadcrumbs>

                    <Grid container>
                        <Grid item xs={6} md={6} lg={6}>
                            {userHasRoles(activeUser, [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_PROJECT_MANAGER]) && (
                                <Button onClick={onCreateProjectClick} color="primary" startIcon={<IconAdd />}>
                                    Ein neues Projekt anlegen
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} className="archived-project-checkbox-container">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className="archived-project-checkbox-container__checkbox"
                                        checked={showArchivedProjects}
                                        onChange={() => setShowArchivedProjects(!showArchivedProjects)}
                                        name="archivedProjects"
                                        color="primary"
                                    />
                                }
                                label="Archivierte Projekte"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                        {filteredProjectsData
                            .sort((a, b) => {
                                const firstdate = new Date(a.theStart);
                                const seconddate = new Date(b.theStart);
                                return firstdate.getTime() - seconddate.getTime();
                            })
                            .map((project: IProjectList, index) => {
                                return (
                                    <ProjectTile
                                        key={`project-${index}`}
                                        {...project}
                                        onDeleteClick={() => handleDeleteProject(project.id)}
                                        onEditClick={() => {
                                            onProjectEditClick(project.id);
                                        }}
                                        onPublishClick={() => {
                                            onProjectPublishClick(project.id, mandant.key);
                                        }}
                                    />
                                );
                            })}
                    </Grid>
                    <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
                </>
            )}
        </>
    );
};

const mapStateToProps: MapStateToPropsParam<IStateProps, {}, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        activeUser: state.user.userData,
    };
};

export default connect(mapStateToProps)(ProjectListing);
