import { Button, ButtonGroup, Typography } from "@material-ui/core";
import React, { memo } from "react";

export interface ElementLayoutListProps {
    addPageSectionAction: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const ElementLayoutList = memo(function ElementLayoutList({ addPageSectionAction }: ElementLayoutListProps) {
    return (
        <div className="sticky-menu">
            <Typography variant="h2" className="gap-bottom">
                Seiten Elemente
            </Typography>

            <ButtonGroup
                fullWidth={true}
                variant="outlined"
                orientation="vertical"
                color="primary"
                aria-label="vertical outlined primary button group"
            >
                <Button onClick={addPageSectionAction} data-type={"l_1"}>
                    1 Spalte
                </Button>
                <Button onClick={addPageSectionAction} data-type={"l_1_1"}>
                    2 Spalten
                </Button>
                <Button onClick={addPageSectionAction} data-type={"l_1_1_1"}>
                    3 Spalten
                </Button>
                <Button onClick={addPageSectionAction} data-type={"l_2_1"}>
                    2 Spalten 2/3 | 1/3
                </Button>
                <Button onClick={addPageSectionAction} data-type={"l_1_2"}>
                    2 Spalten 1/3 | 2/3
                </Button>
                <Button onClick={addPageSectionAction} data-type={"l_1_2_1"}>
                    3 Spalten 1/4 | 1/2 | 1/4
                </Button>
                <Button onClick={addPageSectionAction} data-type={"l_1_1_1_1"}>
                    4 Spalten
                </Button>
            </ButtonGroup>
        </div>
    );
});
