import "../FundingCalculatorForm.css";

import { useState } from "react";

import inputNumberIsEmpty from "../../../utils/form/inputNumberIsEmpty";
import stringIsEmpty from "../../../utils/form/stringIsEmpty";
import { calculateFunding } from "./calculateFunding";
import {
    InitialCalculateKFWResult,
    InitialFundingData,
    baseProgramm,
    defaultCalculationParameters,
} from "./FundingCalculatorForm.data";
import {
    FundingCalculationResult,
    FundingData,
    KfwCalculationConfig,
    KfwProgramm,
} from "./FundingCalculatorForm.types";

interface FundingCalculatorParams {
    calculationData?: KfwCalculationConfig;
}

export function useFundingCalculatorLogic({ calculationData }: FundingCalculatorParams) {
    const [fundingCalculationInputs, setFundingCalculationInputs] = useState<FundingData>({ ...InitialFundingData });

    const [fundingCalculationResult, setFundingCalculationResult] = useState<FundingCalculationResult>({
        ...InitialCalculateKFWResult,
    });

    const changeInputEligibleCosts = (value: any) => {
        setFundingCalculationResult(InitialCalculateKFWResult);

        setFundingCalculationInputs({
            ...fundingCalculationInputs,
            eligibleCosts: parseFloat(value),
        });
    };

    const changeInput = (event: any) => {
        setFundingCalculationInputs({
            ...fundingCalculationInputs,
            [event.target.name]: event.target.value,
        });
    };

    const calcParameters =
        calculationData !== undefined ? (calculationData as KfwCalculationConfig) : defaultCalculationParameters;

    const handleCalculation = () => {
        const activeProgramm = fundingCalculationInputs.fundingCalculatorType as KfwProgramm;

        setFundingCalculationResult(
            calculateFunding(
                Number(fundingCalculationInputs.eligibleCosts),
                activeProgramm,
                calcParameters[activeProgramm],
                calcParameters[baseProgramm]
            )
        );
    };

    const buttonIsDisabled =
        inputNumberIsEmpty(fundingCalculationInputs.eligibleCosts) ||
        stringIsEmpty(fundingCalculationInputs.fundingCalculatorType);

    return {
        buttonIsDisabled,
        handleCalculation,
        changeInputEligibleCosts,
        setFundingCalculationResult,
        fundingCalculationInputs,
        changeInput,
        fundingCalculationResult,
    };
}
